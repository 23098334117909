<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import "lodash";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ImageParticle, } from "components/cards/particles/YinzCam";
export let container;
export let overlayPadding;
export let headerTab;
export let playerStarterStats = undefined;
export let playerSubStats = undefined;
export let imgTeamLogo;
export let txtStartersHeading;
export let txtSubHeading;
export let playerImage;
export let txtTeamName;
export let txtLineUp;
export let headerTabs;
export let teamTricode;
export let numberColor;
let atomWidthRem;
function handleImageError(event) {
    event.target.src =
        "https://resources-au.yinzcam.com/apl/shared/headshots/silhouette.png";
}
$: isMobile = atomWidthRem <= 58;
$: isTablet = atomWidthRem <= 70 && atomWidthRem > 58;
$: playerImageMultiplier = isMobile ? "1.00" : isTablet ? "0.7" : "1.00";
$: eventIconMultiplier = isMobile ? "0.75" : isTablet ? "0.6" : "0.9";
</script>

<BaseAtom
    _isotope="MCLineupTable2Atom"
    _cssVariablePrefix="mc-lineup-table"
    {...container}
    {overlayPadding}
    {numberColor}
    bind:_clientWidthRem={atomWidthRem}
>
    <div
        class="main-container"
        class:mobile-layout={isMobile}
        class:tablet-layout={isTablet}
    >
        <div class="team-name-logo">
            <ImageParticle {...imgTeamLogo} />
            <div class="team-name-heading">
                <TextParticle
                    {...txtTeamName}
                    text={`${txtTeamName?.text} Line-up`}
                    useAccentColor={CONFIG.tricode.toLowerCase() ===
                        teamTricode?.toLowerCase()}
                />
                <!-- <TextParticle {...txtLineUp} text="Line-up" /> -->
            </div>
        </div>
        <div class="starters-lineup">
            {#each playerStarterStats || [] as stat}
                <div class="player-card">
                    <div class="player-card-image">
                        <ImageParticle
                            {...playerImage}
                            source={stat?.playerImageUrl}
                            {handleImageError}
                        />
                    </div>
                    <div class="player-name-position">
                        <TextParticle {...stat?.playerName} />
                        <TextParticle {...stat?.playingPosition} />
                    </div>
                    <div class="player-number">
                        <TextParticle
                            {...stat?.position}
                            useAccentColor={CONFIG.tricode.toLowerCase() ===
                                teamTricode?.toLowerCase()}
                        />
                    </div>
                </div>
            {/each}
        </div>
        <div class="subs-section">
            <div class="sub-heading">
                <TextParticle
                    {...txtSubHeading}
                    useAccentColor={CONFIG.tricode.toLowerCase() ===
                        teamTricode?.toLowerCase()}
                />
            </div>
            <div class="subs-lineup">
                {#each playerSubStats || [] as stat}
                    <div class="player-card">
                        <div class="player-card-image">
                            <ImageParticle
                                {...playerImage}
                                source={stat?.playerImageUrl}
                                {handleImageError}
                            />
                        </div>
                        <div class="player-name-position">
                            <TextParticle {...stat?.playerName} />
                            <TextParticle {...stat?.playingPosition} />
                        </div>
                        <div class="player-number">
                            <TextParticle
                                {...stat?.position}
                                useAccentColor={CONFIG.tricode.toLowerCase() ===
                                    teamTricode?.toLowerCase()}
                            />
                        </div>
                    </div>
                {/each}
            </div>
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        width: 100%;
    }
    .team-name-logo {
        width: 100%;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        padding: 1rem;
    }
    .team-name-logo .team-name-heading {
            display: flex;
            flex-direction: column;
        }
    .starters-lineup,
    .subs-lineup {
        width: 100%;
        border-top: 1px solid #fff;
        border-radius: 0.5rem;
        background-color: #fff;
        padding: 0.5rem 0.5rem 0 0.5rem;
    }
    .player-card {
        display: grid;
        grid-template-columns: 0.75fr 3fr 1fr;
        padding: 0 0.5rem;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        align-items: center;
        border-bottom: 1px solid #eee;
    }
    .player-card .player-name-position {
            display: flex;
            flex-direction: column;
        }
    .player-card .player-number {
            text-align: center;
        }
    .sub-heading {
        padding: 1rem;
    }</style>
