import { generateBooleanSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema, generateDateTimeSchema, generateArraySchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { TextParticleSpec, ImageParticleSpec, ButtonParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Player Headshot", generateRootObjectSchema({
    container: generateAtomContainerSchema({ defaultValues: { themeMode: 'primary' } }),
    overlayPadding: generateStringSchema("Overlay Padding", "The padding (inset) of the overlay text/buttons relative to the background image.", {
        defaultValue: '3rem'
    }),
    imgBackgroundImage: ImageParticleSpec.generateSchema("Background Image", "The player's background image."),
    txtFirstName: TextParticleSpec.generateSchema("First Name", "Enter player first name", {
        defaultValues: {
            size: "2.25rem", weight: "900", color: "var(--theme-mode-highlight-color)", lineHeight: "normal",
        }
    }),
    txtLastName: TextParticleSpec.generateSchema("Last Name", "Enter player last name", {
        defaultValues: {
            size: "18.75rem", weight: "900", lineHeight: "normal", color: "var(--theme-mode-background-color)"
        }
    }),
    imgPlayerImage: ImageParticleSpec.generateSchema("Headline Image", "The player's headline image.", {
        defaultValues: { container: { height: "100%" } }
    }),
    imgTshirt: ImageParticleSpec.generateSchema("Tshirt image", "Upload T Shirt image", {
        defaultValues: {
            container: {
                width: "7rem", height: "7rem",
                border: { visible: true, radius: "50%" },
                overflow: "hidden"
            }
        }
    }),
    btnBuy: ButtonParticleSpec.generateSchema("Buy TShirt", "Button to buy T shirt", {
        defaultValues: {
            text: { size: "1rem", weight: "600", lineHeight: "1rem" },
            container: {
                background: { visible: true, color: "var(--theme-mode-highlight-color)" },
                border: { visible: true, radius: "0.125rem" },
                padding: "0.75rem 1.25rem"
            }
        }
    }),
    btnSocialMedia: generateArraySchema("Social Media Icons", "Specify social media content",
        ButtonParticleSpec.generateSchema("Social Media", "Select social media icon and link", {
            defaultValues: { text: { size: "1rem" }, image: { container: { width: "3rem", height: "3rem" } } }
        })
    ),
    showPlayerName: generateBooleanSchema("Show Player Name", "Render player names behind the player's headline image.", {
        defaultValue: true,
    }),
}));

