import { generateBooleanSchema, generateColorSchema, generateDateTimeSchema, generateRootObjectSchema, generateStringSchema, generateArraySchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { TextParticleSpec, ImageParticleSpec, ButtonParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Schedule/Fixtures Card", generateRootObjectSchema({
    container: generateAtomContainerSchema({
        defaultValues: { background: { visible: true } },
    }),
    overlayPadding: generateStringSchema("Overlay Padding", "Specify a padding for overlay content"),
    backgroundColor: generateColorSchema("Background Color", "BG for live matches"),
    imgLeaguelogo: ImageParticleSpec.generateSchema("Competition Logo", "Upload/specify URL for competition logo", {
        defaultValues: { container: { width: "7rem", height: "7rem" }, sizing: "cover" }
    }),
    matchDateTime: generateDateTimeSchema("Match Date Time", "The date and time of the match."),
    txtMatchDate: TextParticleSpec.generateSchema("Match Date", "The styling for the match date.", {
        defaultValues: { size: "1.5rem", weight: "600", wrap: "nowrap" },
        uneditableFields: { text: true }
    }),
    txtMatchTime: TextParticleSpec.generateSchema("Match Time", "The styling for the match time.", {
        defaultValues: { size: '2.25rem', weight: '300' },
        uneditableFields: { text: true }
    }),
    txtStadiumName: TextParticleSpec.generateSchema("Venue Name", "Enter venue name", {
        defaultValues: { size: '1rem', weight: '400' }
    }),
    stadiumBroadcastersGap: generateStringSchema("Stadium-Broadcasters Gap", "The size of the gap between stadium information and broadcasters information in standard CSS units.",
        { defaultValue: '1rem' }
    ),
    txtBroadcasters: TextParticleSpec.generateSchema("Broadcasters Text", "The text to display ahead of the Broadcasters list.", {
        defaultValues: {
            size: "1rem",
            weight: "600",
            text: "Watch On",
        }
    }),
    btnBroadcasters: generateArraySchema("Broadcaster Buttons", "A list of broadcaster buttons.",
        ButtonParticleSpec.generateSchema("Broadcaster Button", "A broadcaster button", {
            defaultValues: {
                showText: false,
                text: { size: '1.5rem', weight: '700', wrap: "wrap" },
                image: { container: { height: '2.25rem' } },
                gap: '0.5rem',
            }
        })
    ),
    txtHomeTeam: TextParticleSpec.generateSchema("Home Team", "Enter home team details", {
        defaultValues: { size: "1.5rem", weight: "700", align: "right", wrap: "wrap", container: { centerContentHorizontally: false, centerContentVertically: false } }
    }),
    txtAwayTeam: TextParticleSpec.generateSchema("Away Team", "Enter away team details", {
        defaultValues: { size: "1.5rem", weight: "700", align: "left", container: { centerContentHorizontally: false, centerContentVertically: false } }
    }),
    txtHomeTeamAbbr: TextParticleSpec.generateSchema("Home Team Abbr", "Enter home team details", {
        defaultValues: { size: "1.5rem", weight: "700", align: "right", container: { centerContentHorizontally: false, centerContentVertically: false, fillWidth: false } }
    }),
    txtAwayTeamAbbr: TextParticleSpec.generateSchema("Away Team Abbr", "Enter away team details", {
        defaultValues: { size: "1.5rem", weight: "700", align: "left", container: { centerContentHorizontally: false, centerContentVertically: false, fillWidth: false } }
    }),
    imgHomeTeam: ImageParticleSpec.generateSchema("Home Team logo", "Upload/specify home team logo", {
        defaultValues: { container: { height: "5.5rem", width: "5.5rem" }, sizing: 'contain', source: 'https://resources-uk.yinzcam.com/soccer/shared/logos/_default.svg' }
    }),
    imgAwayTeam: ImageParticleSpec.generateSchema("Away Team logo", "Upload/specify away team logo", {
        defaultValues: { container: { height: "5.5rem", width: "5.5rem" }, sizing: 'contain', source: 'https://resources-uk.yinzcam.com/soccer/shared/logos/_default.svg' }
    }),
    btnCTA: generateArraySchema("CTA Buttons", "Populate action buttons",
        ButtonParticleSpec.generateSchema("CTA Button", "Details of CTA", {
            defaultValues: {
                container: {
                    padding: "0.5rem 0.5rem",
                    border: {
                        visible: true, radius: "0.375rem", color: '#DDD', style: "solid", width: "1px"
                    },
                    background: {
                        visible: true
                    }, fillHeight: false, fillWidth: false,
                },
                text: {
                    size: "1rem", weight: "600", align: "left", transform: 'uppercase'
                },
                image: {
                    container: { height: "2.25rem", width: "2.25rem" },
                },
            }
        })
    ),
    matchStatus: generateStringSchema("Match Status", "Select match status", {
        choices: ['pre', 'live', 'post'],
        choiceTitles: ["Pre", "Live", "Post"]
    }),
    homeTeamScore: TextParticleSpec.generateSchema("Home Score", "Enter home team score", {
        defaultValues: { size: "3rem", weight: "700" }
    }),
    awayTeamScore: TextParticleSpec.generateSchema("Away Score", "Enter home team score", {
        defaultValues: { size: "3rem", weight: "700" }
    }),
    matchMinutes: TextParticleSpec.generateSchema("Match minutes - Live", "Enter the minutes of live match", {
        defaultValues: { size: "2.25rem", weight: "300", color: "#fff" }
    }),
    isChannelImageRequired: generateBooleanSchema("Channel Images", "Specify if channel partner images required", {
        defaultValue: false
    }),
    isPenalty: generateBooleanSchema("Penalty", "Select if there was a penalty", {
        defaultValue: false
    }),
    txtLive: TextParticleSpec.generateSchema("Live text", "Defaults to `Live` when match status is Live", {
        defaultValues: { size: "1.25rem", weight: "700", transform: "uppercase", color: "#000", text: "Live" }
    }),
    txtPenalties: TextParticleSpec.generateSchema("Penalties Text", "The text to display when a match is won on penalties.", {
        defaultValues: { size: "1.5rem", weight: "300" }
    }),
    colorLiveIconBackground: generateColorSchema("Live Icon BG", "Pick Live icon background color"),
    colorLiveIconBorder: generateColorSchema("Live Icon Border", "Pick Live icon border color"),
    colorFirstButton: generateColorSchema("First Button Color", "Pick a color for first button"),
    colorLiveIconCircle: generateColorSchema("Circle Fill - Live Icon", "Pick a color to fill the live circle icon")
}));
