<script lang="ts">import { BaseParticle } from "components/cards/particles";
import { ButtonParticle } from "../../Core/Button";
import { merge } from "lodash";
// props
export let container = undefined;
export let shareLink = undefined;
export let shareText = undefined;
export let buttonDefaults = undefined;
export let showShareLink = true;
export let showShareMail = true;
export let showShareLinkedIn = true;
export let showShareTwitter = true;
export let showShareFacebook = true;
function popupShare(type) {
    var _a;
    let rawUrl = new URL(shareLink, window.origin).toString();
    let url = encodeURIComponent(rawUrl);
    let title = encodeURIComponent(shareText);
    switch (type) {
        case 'link':
            (_a = navigator === null || navigator === void 0 ? void 0 : navigator.clipboard) === null || _a === void 0 ? void 0 : _a.writeText(rawUrl);
            alert('URL copied to clipboard.');
            return;
        case 'facebook':
            url = `https://facebook.com/sharer/sharer.php?title=${title}&u=${url}`;
            break;
        case 'mail':
            url = `mailto:someone@domain.com?subject=${title}&body=${url}`;
            window.open(url, '_self');
            return;
        case 'twitter':
            url = `https://twitter.com/intent/tweet/?text=${title}&url=${url}`;
            break;
        case 'whatsapp':
            url = `whatsapp://send?text=${title + '\n' + url}`;
            break;
        default:
            break;
    }
    window.open(url, 'YCShareWindow', `height=450, width=550, top=${window.innerHeight / 2 - 225}, left=${window.innerWidth / 2 - 275}, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`);
}
function navigatorShare(type) {
    navigator.share({
        url: shareLink,
        text: shareText,
    });
}
function onShareLink() {
    popupShare('link');
}
function onShareMail() {
    popupShare('mail');
}
function onShareLinkedIn() {
    popupShare('linkedin');
}
function onShareTwitter() {
    popupShare('twitter');
}
function onShareFacebook() {
    popupShare('facebook');
}
</script>

<style>
  .share-strip {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }</style>

<BaseParticle _name="ShareStripParticle" _cssVariablePrefix="share-strip-particle" {...container}>
  <div class="share-strip">
    {#if shareLink}
    <div class="share-button">
      {#if showShareLink}
      <ButtonParticle on:click={onShareLink} {...merge({}, buttonDefaults, { image: { icon: 'yc_icon_link'} })} />
      {/if}
    </div>
    <!--
    <div class="share-button">
      {#if showShareLinkedIn}
      <ButtonParticle on:click={onShareLinkedIn} {...merge({}, buttonDefaults, { image: { icon: 'yc_icon_linkedin'} })} />
      {/if}
    </div>
    -->
    <div class="share-button">
      {#if showShareMail}
      <ButtonParticle on:click={onShareMail} {...merge({}, buttonDefaults, { image: { icon: 'yc_icon_email'} })} />
      {/if}
    </div>
    <div class="share-button">
      {#if showShareTwitter}
      <ButtonParticle on:click={onShareTwitter} {...merge({}, buttonDefaults, { image: { icon: 'yc_icon_twitter_x'} })} />
      {/if}
    </div>
    <div class="share-button">
      {#if showShareFacebook}
      <ButtonParticle on:click={onShareFacebook} {...merge({}, buttonDefaults, { image: { icon: 'yc_icon_facebook'} })} />
      {/if}
    </div>
    {/if}
  </div>
</BaseParticle>
