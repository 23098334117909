
<script lang="ts">import { AudioParticle, ImageParticle, TextParticle, VideoParticle, WebEmbedParticle } from "components/cards/particles/YinzCam";
import "./ArticleBodyNode";
import { merge } from "lodash";
import { resolveUrl, shouldOpenInNewWindow } from "js/url";
export let node = undefined;
export let textDefaults = undefined;
export let imageDefaults = undefined;
export let videoDefaults = undefined;
export let audioDefaults = undefined;
export let webEmbedDefaults = undefined;
</script>

<style>
  a.article-body-link {
    font-weight: 700;
  }</style>

{#if node}
{#if node.type === 'text-block'}
{#each node.nodes as subNode}
{#if subNode}
{#if subNode.type === 'link' && subNode.uri && subNode.nodes && subNode.nodes.length > 0}
<a class="article-body-link link" href={resolveUrl(subNode.uri).href} class:external={resolveUrl(subNode.uri).external} target={(shouldOpenInNewWindow(resolveUrl(subNode.uri).href))? '_blank' : '_self'}>
{#each subNode.nodes as subSubNode}
{#if subSubNode.type === 'text' && subSubNode.particleProps}
  <TextParticle {...merge({}, textDefaults, subSubNode.particleProps, { useAccentColor: true })} />
{/if}
{/each}
</a>
{:else if subNode.type === 'text' && subNode.particleProps}
<TextParticle {...merge({}, textDefaults, subNode.particleProps)} />
{/if}
{/if}
{/each}
{:else if node.type === 'image' && node.particleProps}
<ImageParticle _block={true} {...merge({}, imageDefaults, node.particleProps)} />
{:else if node.type === 'video' && node.particleProps}
<VideoParticle {...merge({}, videoDefaults, node.particleProps)} />
{:else if node.type === 'audio' && node.particleProps}
<AudioParticle {...merge({}, audioDefaults, node.particleProps)} />
{:else if node.type === 'web-embed' && node.particleProps}
<WebEmbedParticle {...merge({}, webEmbedDefaults, node.particleProps)} />
{/if}
{/if}
