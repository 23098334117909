<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import { get, merge } from "lodash";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, RichTextParticle, ButtonParticle, } from "components/cards/particles/YinzCam";
import { writable } from "svelte/store";
import "js/url";
import "svelte/internal";
const hovering = writable(false);
export let container;
export let overlayPadding = undefined;
export let txtFieldNames;
export let txtPlayerName;
export let txtPlayerFullName;
export let txtDOB;
export let txtPOB;
export let txtHeight;
export let txtWeight;
export let txtSummary;
export let playerBioHidden;
export let playerStatsHidden;
export let playerNameHidden;
export let btnViewMoreSummary;
export let gradientColor;
export let shouldDisplayViewMore;
export let shouldDisplayFullName;
export let summaryBoxHeight;
let atomWidthRem;
let viewMore = !shouldDisplayViewMore;
const toggleViewMore = () => (viewMore = !viewMore);
$: headerTextMultiplier = atomWidthRem <= 30 ? "0.60" : "1.00";
$: bodyTextMultiplier = atomWidthRem <= 30 ? "0.80" : "1.00";
//size = {`calc(${get(txtFieldNames, "size")}*${bodyTextMultiplier})`}
</script>

<BaseAtom
    _isotope="PlayerBioAtom"
    {...container}
    _cssVariablePrefix="player-bio"
    {overlayPadding}
    {gradientColor}
    {summaryBoxHeight}
    bind:_clientWidthRem={atomWidthRem}
>
    <div class="main-container">
        <div class="player-info-box">
            {#if !playerNameHidden}
                <div class="player-name">
                    <TextParticle
                        {...txtPlayerName}
                        size={`calc(${get(txtPlayerName, "size")}*${headerTextMultiplier})`}
                        text={shouldDisplayFullName
                            ? txtPlayerFullName?.text
                            : txtPlayerName?.text}
                    />
                </div>
            {/if}
            {#if !playerStatsHidden}
                <div class="player-stats">
                    <div class="date-of-birth">
                        <TextParticle
                            {...txtFieldNames}
                            text={"Date of Birth"}
                            size={`calc(${get(txtFieldNames, "size")}*${bodyTextMultiplier})`}
                        />
                        <TextParticle
                            {...txtDOB}
                            size={`calc(${get(txtDOB, "size")}*${bodyTextMultiplier})`}
                        />
                    </div>
                    <div class="place-of-birth">
                        <TextParticle
                            {...txtFieldNames}
                            text={"Place of Birth"}
                            size={`calc(${get(txtFieldNames, "size")}*${bodyTextMultiplier})`}
                        />
                        <TextParticle
                            {...txtPOB}
                            size={`calc(${get(txtFieldNames, "size")}*${bodyTextMultiplier})`}
                        />
                    </div>
                    <div class="height">
                        <TextParticle
                            {...txtFieldNames}
                            text={"Height"}
                            size={`calc(${get(txtFieldNames, "size")}*${bodyTextMultiplier})`}
                        />
                        <TextParticle
                            {...txtHeight}
                            size={`calc(${get(txtFieldNames, "size")}*${bodyTextMultiplier})`}
                        />
                    </div>
                    <div class="weight">
                        <TextParticle
                            {...txtFieldNames}
                            text={"Weight"}
                            size={`calc(${get(txtFieldNames, "size")}*${bodyTextMultiplier})`}
                        />
                        <TextParticle
                            {...txtWeight}
                            size={`calc(${get(txtFieldNames, "size")}*${bodyTextMultiplier})`}
                        />
                    </div>
                </div>
            {/if}
            {#if !playerBioHidden && txtSummary?.html}
                <div class="summary-box">
                    <div
                        class={`${viewMore ? "summary-long" : "summary-short"}`}
                    >
                        <RichTextParticle {...txtSummary} />
                    </div>
                    {#if shouldDisplayViewMore}
                        <div class="view-more-button">
                            <ButtonParticle
                                on:click={toggleViewMore}
                                {...merge({}, btnViewMoreSummary, {
                                    text: {
                                        text:
                                            btnViewMoreSummary?.text?.text ||
                                            `${viewMore ? "View Less" : "View More"}`,
                                    },
                                })}
                            />
                        </div>
                    {/if}
                </div>
            {/if}
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        padding: var(--player-bio-overlay-padding, unset);
        background-color: var(--theme-mode-background-color, unset);
    }
    .player-info-box {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }
    .player-stats {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .date-of-birth,
    .place-of-birth,
    .height,
    .weight {
        display: flex;
        gap: 1rem;
    }
    .summary-short {
        height: var(--player-bio-summary-box-height, 6rem);
        overflow: hidden;
    }
    .view-more-button {
        padding: 0.5rem;
    }
    .summary-box {
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 25.97%,
            var(--player-bio-gradient-color, --theme-mode-accent-color) 100%
        );
    }</style>
