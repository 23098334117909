<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import { BaseAtom, } from "components/cards/atoms";
import { DropDownParticle } from "components/cards/particles/YinzCam";
import { resultsYears } from "components/cards/utilities/constants";
export let container;
export let overlayPadding = undefined;
export let dropdownParticle;
export let isResultsYears;
$: if (isResultsYears)
    dropdownParticle.txtOptions = resultsYears;
</script>

<BaseAtom
    _isotope="DropdownAtom"
    {...container}
    _cssVariablePrefix="drop-down"
    {overlayPadding}
>
    <div class="dropdown-box">
        <DropDownParticle {...dropdownParticle} txtOptions={isResultsYears ? resultsYears : []}/>
    </div>
</BaseAtom>

<style>
    .dropdown-box {
        width: 100%;
        padding: 0;
        position: relative;
    }</style>
