<script context="module" lang="ts">var _a, _b, _c, _d;
export { specification } from "./spec";
</script>

<script lang="ts">var _a, _b, _c, _d;
import { get, merge } from "lodash";
import "components/cards/utilities";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, } from "components/cards/particles/YinzCam";
import { writable } from "svelte/store";
import "js/url";
const hovering = writable(false);
const themeModeValue = "primary";
export let container;
export let overlayPadding = undefined;
export let headerText;
export let statForward;
export let statBackward;
export let statLeft;
export let statRight;
export let playerStats = [];
let atomWidthRem;
const calcPercentage = (val, total) => {
    var _a;
    let homeValue = parseInt((_a = val === null || val === void 0 ? void 0 : val.homeValue) === null || _a === void 0 ? void 0 : _a.text);
    let totalValue = parseInt(total);
    if (!homeValue || !totalValue)
        return 0;
    return ((homeValue / totalValue) * 100).toFixed(1);
};
$: txtPercentMultiplier = atomWidthRem < 30 ? "0.6" : "1.00";
$: pctForward = playerStats === null || playerStats === void 0 ? void 0 : playerStats.find((playerStat) => { var _a; return statForward === ((_a = playerStat === null || playerStat === void 0 ? void 0 : playerStat.name) === null || _a === void 0 ? void 0 : _a.text); });
$: pctBackward = playerStats === null || playerStats === void 0 ? void 0 : playerStats.find((playerStat) => { var _a; return statBackward === ((_a = playerStat === null || playerStat === void 0 ? void 0 : playerStat.name) === null || _a === void 0 ? void 0 : _a.text); });
$: pctRight = playerStats === null || playerStats === void 0 ? void 0 : playerStats.find((playerStat) => { var _a; return statLeft === ((_a = playerStat === null || playerStat === void 0 ? void 0 : playerStat.name) === null || _a === void 0 ? void 0 : _a.text); });
$: pctLeft = playerStats === null || playerStats === void 0 ? void 0 : playerStats.find((playerStat) => { var _a; return statRight === ((_a = playerStat === null || playerStat === void 0 ? void 0 : playerStat.name) === null || _a === void 0 ? void 0 : _a.text); });
$: totalValue =
    +((_a = pctForward === null || pctForward === void 0 ? void 0 : pctForward.homeValue) === null || _a === void 0 ? void 0 : _a.text) +
        +((_b = pctBackward === null || pctBackward === void 0 ? void 0 : pctBackward.homeValue) === null || _b === void 0 ? void 0 : _b.text) +
        +((_c = pctRight === null || pctRight === void 0 ? void 0 : pctRight.homeValue) === null || _c === void 0 ? void 0 : _c.text) +
        +((_d = pctLeft === null || pctLeft === void 0 ? void 0 : pctLeft.homeValue) === null || _d === void 0 ? void 0 : _d.text);
$: valForward = calcPercentage(pctForward, totalValue);
$: valBackward = calcPercentage(pctBackward, totalValue);
$: valRight = calcPercentage(pctRight, totalValue);
$: valLeft = calcPercentage(pctLeft, totalValue);
</script>

<BaseAtom
    _isotope="PassDirectionAtom"
    {...merge({}, container, { themeMode: themeModeValue })}
    _cssVariablePrefix="pass-dir"
    {overlayPadding}
    bind:_clientWidthRem={atomWidthRem}
>
    <div
        class="pass-direction-container"
        class:layout-mobile-width={atomWidthRem < 30}
    >
        <div class="pass-direction-card">
            <div class="header-text">
                <TextParticle {...headerText} />
            </div>
            <div class="stat-content">
                <div class="field-shape">
                    <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 696 696"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M2 2L694 694"
                            stroke="#0054A6"
                            stroke-width="4"
                        />
                        <path
                            d="M694 2L2.00001 694"
                            stroke="#0054A6"
                            stroke-width="4"
                        />
                        <circle
                            cx="347.498"
                            cy="347.5"
                            r="30.412"
                            fill="white"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M377.91 347.5C377.91 364.296 364.294 377.912 347.498 377.912C330.702 377.912 317.086 364.296 317.086 347.5C317.086 330.704 330.702 317.088 347.498 317.088C364.294 317.088 377.91 330.704 377.91 347.5ZM319.339 347.5C319.339 351.415 320.137 355.143 321.581 358.53L323.281 359.89L324.407 358.764V348.626L320.348 340.001C319.69 342.388 319.339 344.903 319.339 347.5ZM338.985 374.349C333.613 372.648 328.938 369.378 325.498 365.078L324.971 362.706L326.097 361.58L334.545 366.648L339.613 373.406L338.985 374.349ZM355.383 373.406L356.011 374.349C361.467 372.621 366.204 369.276 369.659 364.876L370.589 362.706L368.899 361.58L361.014 366.648L355.383 373.406ZM375.657 347.5C375.657 351.381 374.872 355.078 373.452 358.442L371.715 359.89L370.025 358.764V348.626L374.628 339.932C375.299 342.34 375.657 344.878 375.657 347.5ZM328.35 328.352L327.434 327.742C331.871 323.237 337.813 320.219 344.446 319.504L346.372 320.467V322.72L337.361 327.225L328.35 328.352ZM349.187 320.467L350.754 319.527C357.415 320.294 363.369 323.385 367.783 327.969L367.209 328.352L357.635 327.225L349.187 322.72V320.467ZM366.083 331.168L358.198 330.604L355.383 337.363L361.014 346.374L368.336 346.937L371.715 339.615L366.083 331.168ZM328.913 331.168L336.797 330.604L339.613 337.363L333.982 346.374L326.66 346.937L323.281 339.615L328.913 331.168ZM336.234 347.5L341.866 338.489H353.13L358.762 347.5L353.13 357.074H341.866L336.234 347.5ZM341.866 359.89H353.13L357.635 366.085L353.13 371.717H341.866L337.361 366.085L341.866 359.89Z"
                            fill="black"
                        />
                    </svg>
                </div>
                <div class="stats-text">
                    <div class="forward">
                        <TextParticle
                            {...pctForward?.homeValue}
                            text={`${valForward}%`}
                            size={`calc(${get(pctForward, "homeValue.size")}*${txtPercentMultiplier})`}
                        />
                        <span>Forward</span>
                    </div>
                    <div class="left-right">
                        <div class="left">
                            <TextParticle
                                {...pctLeft?.homeValue}
                                text={`${valLeft}%`}
                                size={`calc(${get(pctLeft, "homeValue.size")}*${txtPercentMultiplier})`}
                            />
                            <span>Left</span>
                        </div>
                        <div class="right">
                            <TextParticle
                                {...pctRight?.homeValue}
                                text={`${valRight}%`}
                                size={`calc(${get(pctRight, "homeValue.size")}*${txtPercentMultiplier})`}
                            />
                            <span>Right</span>
                        </div>
                    </div>
                    <div class="backward">
                        <TextParticle
                            {...pctBackward?.homeValue}
                            text={`${valBackward}%`}
                            size={`calc(${get(pctBackward, "homeValue.size")}*${txtPercentMultiplier})`}
                        />
                        <span>Backward</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</BaseAtom>

<style>
    .pass-direction-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
    }

    .pass-direction-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        align-items: center;
        padding: 1.5rem;
        gap: 1.5rem;
        width: 100%;
        height: 100%;
        border-radius: 0.75rem;
    }

    .stat-content {
        display: grid;
        aspect-ratio: 1/1;
        flex-grow: 1;
    }

    .stat-content > * {
        grid-row: 1;
        grid-column: 1;
    }

    .stats-text {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        grid-gap: 0.75rem;
        gap: 0.75rem;
    }

    .left-right {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;
    }

    .left {
        text-align: left;
    }

    .right {
        text-align: right;
    }

    .forward,
    .left,
    .right,
    .backward {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .forward span, .left span, .right span, .backward span {
            font-size: 1.25rem;
            font-weight: 600;
        }

    .backward {
        align-self: center;
    }

    .forward {
        align-self: center;
    }

    .pass-direction-container.layout-mobile-width > .pass-direction-card .forward span, .pass-direction-container.layout-mobile-width > .pass-direction-card .left span, .pass-direction-container.layout-mobile-width > .pass-direction-card .right span, .pass-direction-container.layout-mobile-width > .pass-direction-card .backward span {
                font-size: 1rem;
                font-weight: 600;
            }</style>
