import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { JSONSchema4 } from "json-schema";
import _ from "lodash";
import { YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamAppServerToken } from "yinzcam-appserver";

@injectable()
export class YinzCamAppServerStatsStandings extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }

  public async getDataSourceConfigSpec(path?: string, specData?: { [key: string]: any }): Promise<JSONSchema4> {
    return {
      type: "object",
      properties: {
        "conference": {
          type: "string",
          title: "Conference",
          description: "Filter the standings response to a specific conference.",
        },
      },
      required: [
      ]
    };
  }

  protected processResponse(path: string, response: YinzCamAPIResponse, specData?: { [key: string]: any }): object | any[] {
    const data = response?.data as any;
    if (data?.Standings?.Conferences?.Conference?.length > 0 && specData?.conference) {
      const conferenceData = data.Standings.Conferences.Conference.find((conf) => conf?._attributes?.Name === specData.conference);
      if (conferenceData) {
        data.Standings.Division = _.cloneDeep(conferenceData);
      }
    }
    return data;
  }
}
