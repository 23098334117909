import {dndzone, Item, Options} from "svelte-dnd-action";
import _, { update } from 'lodash';
import { resize as resizeObserverAction } from 'svelte-resize-observer-action';
import { resolveUrl } from "js/url";
import { sendMessageToComponent } from "../utilities";
import { ContainerActionProps } from "./schema";
import { CardsTabContext } from "./context";


export interface EditableContainerOptions {
  items: Item[];
  updateItems: (items: any[]) => void;
  disabled: boolean;
}

function buildDndOptions(options: EditableContainerOptions) {
  let dndOptions: Options = {
    flipDurationMs: 300,
    items: options.items
  };
  return dndOptions;
}

export function editableContainer(node: HTMLElement, options: EditableContainerOptions) {
  if (options?.disabled) {
    return;
  }
  if (!node) {
    console.error("editableContainer: node argument is required but not provided");
    return;
  }
  if (!options?.items) {
    console.error("editableContainer: options argument with items array is required but not provided");
    return;
  }
  if (!options?.updateItems) {
    console.error("editableContainer: options argument with updateItems function is required but not provided");
    return;
  }

  node.addEventListener('consider', function(e: CustomEvent) {
    //console.log('onconsider', e.detail);
    options.updateItems(e.detail.items);
  });
  node.addEventListener('finalize', function(e: CustomEvent) {
    //console.log('onfinalize', e.detail);
    options.updateItems(e.detail.items);
  });

  // for now, options is just a passthrough to dndzone
  const dndAction = dndzone(node, buildDndOptions(options));
  return {
    update: (newOptions: EditableContainerOptions) => {
      dndAction.update(buildDndOptions(newOptions));
    },
    destroy: () => {
      dndAction.destroy();
    }
  };
}

export { resizeObserverAction };

/*
export interface ContainerAction {
  trigger?: string;
  target?: string;
  forwardToChildren?: boolean;
  effect?: string;
  [k: string]: any;
}
*/

export type ContainerAction = ContainerActionProps;

export interface ContainerActionsHandlerOptions {
  actions: ContainerAction[];
  tabContext: CardsTabContext;
};

interface ActionState {
  eventListeners: {
    type: string;
    func: () => any;
  }[];
}

export function containerActionsHandler(node: HTMLElement, initialOptions: ContainerActionsHandlerOptions) {
  let currentOptions: ContainerActionsHandlerOptions = null;
  let actionStates: ActionState[] = [];
  let styleOverrides: { [k: string]: string } = {};

  function unregisterCurrentActions() {
    //console.log('UNREGISTER CURRENT ACTIONS', currentOptions);
    if (!currentOptions) {
      return;
    }
    // remove event listeners
    for (const state of (actionStates || [])) {
      for (const listener of (state?.eventListeners || [])) {
        //console.log('ACTION REMOVE EVENT LISTENER', node, listener.type);
        node.removeEventListener(listener.type, listener.func);
      }
    }
    // revert style overrides
    for (const [ property, value ] of Object.entries(styleOverrides || {})) {
      node.style[property] = value;
    }
  }

  function update(newOptions: ContainerActionsHandlerOptions) {
    unregisterCurrentActions();
    currentOptions = newOptions;
    actionStates = [];
    styleOverrides = {};
    for (const action of (newOptions?.actions || [])) {
      //console.log('process action', action);
      let type = null;
      switch (action?.trigger) {
        case 'click':
          type = 'click';
          break;
      }
      if (!type) {
        continue;
      }
      let func = null;
      switch (action?.effect) {
        case 'navigate': {
          const resolved = resolveUrl(action?.navigate?.link);
          func = resolved.func;
          break;
        }
        case 'navigate-back': {
          func = () => {
            //console.log('ACTION NAVIGATE BACK');
            history.back();
          };
          break;
        }
        case 'set-variable': {
          func = () => {
            if (currentOptions.tabContext) {
              currentOptions.tabContext.setParam(action.variable?.name, action.variable?.value);
            }  
          };
          break;
        }
        case 'clear-variable': {
          func = () => {
            if (currentOptions.tabContext) {
              currentOptions.tabContext.setParam(action.variable?.name);
            }  
          };
          break;
        }
        default: {
          if (action?.target) {
            //console.log('SEND MESAGE TO COMPONENT');
            func = () => sendMessageToComponent(action?.target, action);
          } else {
            console.error('unable to dispatch event, no special effect type or target found');
          }
        }
      }
      if (!func) {
        continue;
      }
      //console.log('ACTION ADD EVENT LISTENER', node, type);
      const listenerFunc = () => setTimeout(func, 0);
      node.addEventListener(type, listenerFunc);
      if (type === 'click') {
        //console.log('process action', 'add cursor');
        styleOverrides['cursor'] = node.style.cursor;
        node.style.cursor = 'pointer';
      }
      actionStates.push({ eventListeners: [{ type, func: listenerFunc }] });
    }
  };

  function destroy() {
    //console.log('DESTROY ACTIONS', actionStates);
    unregisterCurrentActions();
    currentOptions = null;
    actionStates = [];
    styleOverrides = {};
  }

  update(initialOptions);

  return {
    update,
    destroy
  };
}