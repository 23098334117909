<script lang="ts">var _a, _b, _c, _d;
export let data = {
    HomeTeam: {},
    AwayTeam: {}
};
$: {
    data.HomeTeam.Logo = `https://resources-uk.yinzcam.com/soccer/shared/logos/${(_b = (_a = data === null || data === void 0 ? void 0 : data.HomeTeam) === null || _a === void 0 ? void 0 : _a.LogoId) === null || _b === void 0 ? void 0 : _b.toLowerCase()}_light.png`;
    data.AwayTeam.Logo = `https://resources-uk.yinzcam.com/soccer/shared/logos/${(_d = (_c = data === null || data === void 0 ? void 0 : data.AwayTeam) === null || _c === void 0 ? void 0 : _c.LogoId) === null || _d === void 0 ? void 0 : _d.toLowerCase()}_light.png`;
}
$: maxValue = Math.max(data.HomeValue, data.AwayValue) || 1;
</script>

<div class="comparison-card">
  <h3>{data.Name}</h3>
  <div class="card-body-el">
    <div class="team home">
      <div class="team-info">
        <div class="logo-wrapper">
          <img src={data.HomeTeam.Logo} alt="Logo" class="logo" />
        </div>
        <span class="percent">{data.Home}</span>
      </div>
      <div class="bar-wrapper">
        <div
          class="bar"
          style={`
            width: calc(${Math.round((data.HomeValue * 100) / maxValue)}%);
            background: ${data.ClientIsHome ? 'var(--theme-primary-accent-color, black);' : '#c5c5c5'};
          `}
        />
      </div>
    </div>
    <div class="team away">
      <div class="team-info">
        <div class="logo-wrapper">
          <img src={data.AwayTeam.Logo} alt="Logo" class="logo" />
        </div>
        <span class="percent">{data.Away}</span>
      </div>
      <div class="bar-wrapper">
        <div
          class="bar"
          style={`
            width: calc(${Math.round((data.AwayValue * 100) / maxValue)}%);
            background: ${!data.ClientIsHome ? 'var(--theme-primary-accent-color, black);' : '#c5c5c5'};
          `}
        />
      </div>
    </div>
  </div>
</div>

<style lang="scss">*,
*:before,
*:after {
  box-sizing: border-box; }

.comparison-card {
  width: 100%;
  height: 100%;
  background: var(--theme-primary-background-color, white);
  display: flex;
  flex-direction: column; }

h3 {
  margin: 0 30px;
  padding: 23px 0 18px 0;
  border-bottom: 2px solid #d8d8d8;
  font-size: 18px;
  font-weight: 500;
  color: var(--theme-primary-foreground-color, black);
  text-transform: uppercase; }

.card-body-el {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 0 30px; }
  .card-body-el .team {
    width: 100%;
    display: flex;
    align-items: center; }
    .card-body-el .team.home {
      order: 1; }
      .card-body-el .team.home .bar {
        background: var(--theme-primary-accent-color, black); }
    .card-body-el .team.away {
      order: 2; }
      .card-body-el .team.away .bar {
        background: #c5c5c5; }
    .card-body-el .team .team-info {
      display: flex;
      align-items: center;
      width: 110px; }
      .card-body-el .team .team-info .logo-wrapper {
        width: 52px;
        height: 52px;
        text-align: center;
        margin: 0 6px 0 0; }
        .card-body-el .team .team-info .logo-wrapper img {
          height: 100%;
          display: inline-block; }
      .card-body-el .team .team-info .percent {
        line-height: 1;
        font-size: 18px;
        font-weight: 600;
        color: var(--theme-primary-foreground-color, black);
        display: inline;
        width: 51px;
        text-align: center; }
    .card-body-el .team .bar-wrapper {
      width: calc(100% - 110px); }
    .card-body-el .team:first-child {
      margin-bottom: 17px; }
  .card-body-el .bar {
    height: 30px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px; }

@media (max-width: 767px) {
  h3 {
    margin: 0 19px;
    padding: 14px 0 10px 0;
    border-bottom: 2px solid #d8d8d8;
    font-size: 11.1px;
    line-height: 13px; }
  .card-body-el {
    padding: 0 19px; }
    .card-body-el .team .team-info {
      width: 60px; }
      .card-body-el .team .team-info .logo-wrapper {
        width: 31px;
        height: 31px;
        margin: 0 3px 0 0; }
      .card-body-el .team .team-info .percent {
        font-size: 11.1px;
        width: 19px; }
    .card-body-el .team .bar-wrapper {
      width: calc(100% - 60px); }
    .card-body-el .team:first-child {
      margin-bottom: 10px; }
    .card-body-el .bar {
      height: 10px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px; } }</style>
