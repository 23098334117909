<script context="module" lang="ts">var _a;
export { specification } from "./spec";
</script>

<script lang="ts">var _a;
import _ from "lodash";
import { BaseAtom, getContentManager } from "components/cards/atoms";
import { ShareStripParticle, TextParticle } from "components/cards/particles/YinzCam";
import AuthorParticle from "components/cards/particles/YinzCam/Media/Author/AuthorParticle.svelte";
import { formatDateTimeLocal, formatDuration } from "components/cards/utilities";
import { t } from "js/i18n";
import { DateTime } from "luxon";
export let container = undefined;
export let slug = undefined;
export let link = undefined;
export let publishDateTime;
export let viewTimeDuration;
export let publishDateAndDurationText = undefined;
export let titleText = undefined;
export let descriptionText = undefined;
export let author = undefined;
export let socialLinks = undefined;
export let showAuthor = undefined;
export let showPublishDateAndDuration = undefined;
$: mtc = getContentManager().getMediaTypeConfigurationStore('article', slug, link);
$: shareLink = (_a = $mtc === null || $mtc === void 0 ? void 0 : $mtc.shareLinkUrl) === null || _a === void 0 ? void 0 : _a.href;
$: publishDateTimeDisplay = formatDateTimeLocal(publishDateTime, DateTime.DATE_HUGE);
$: viewTimeDurationDisplay = formatDuration(viewTimeDuration);
</script>

<style>
  .article-header-atom {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 100%;
    height: 100%;
    gap: 1rem;
  }

  .article-header-atom > .article-header-atom-title {
    flex: 1 0 auto;
    width: 100%;
  }

  .article-header-atom > .article-header-atom-description {
    flex: 1 0 auto;
    width: 100%;
  }

  .article-header-atom > .article-header-atom-bottom-row {
    flex: 1 0 auto;
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .article-header-atom > .article-header-atom-bottom-row > .article-header-atom-author {
    flex: 0 0 auto;
  }

  .article-header-atom > .article-header-atom-bottom-row > .article-header-atom-bottom-row-spacer {
    flex: 1 1 auto;
  }

  .article-header-atom > .article-header-atom-bottom-row > .article-header-atom-social {
    flex: 0 0 auto;
  }</style>

<BaseAtom _isotope="ArticleHeaderAtom" {...container}>
  <div class="article-header-atom">
    <div class="article-header-atom-title">
      <TextParticle {...titleText} />
    </div>
    <div class="article-header-atom-description">
      <TextParticle {...descriptionText} />
    </div>
    <div class="article-header-atom-bottom-row">
      <div class="article-header-atom-bottom-row-left-column">
        {#if showAuthor}
        <div class="article-header-atom-author">
          <AuthorParticle {...author} />
        </div>
        {/if}
        {#if showPublishDateAndDuration}
        <div class="article-header-atom-metadata">
          <TextParticle
            {...publishDateAndDurationText}
            text={publishDateTimeDisplay || ""}
          />
          {#if !_.isNil(viewTimeDuration)}
            &nbsp;•&nbsp;
            <TextParticle
              {...publishDateAndDurationText}
              text="{viewTimeDurationDisplay || ''} {$t('read')}"
            />
          {/if}
        </div>
        {/if}
      </div>
      <div class="article-header-atom-bottom-row-spacer">
      </div>
      <div class="article-header-atom-social">
        <ShareStripParticle {...socialLinks} shareText={(titleText && titleText.text) || ""} shareLink={shareLink || '/'} />
      </div>
    </div>
  </div>
</BaseAtom>
