import { injectable } from "inversify";
import { Token, injectToken } from "inversify-token";
import { Readable, derived } from "svelte/store";
import type { Logger } from "loglevel";
import { LoggerToken } from "yinzcam-log";
import _, { over } from "lodash";
import { ResolvedUrl, resolveUrl } from "js/url";
import { CardsDynamicConfigurationProviderToken } from "./CardsDynamicConfigurationProvider";
import type { CardsDynamicConfigurationProvider } from "./CardsDynamicConfigurationProvider";

export const CardsPageContentManagerToken = new Token<CardsPageContentManager>(Symbol.for("CardsPageContentManager"));

export interface MediaTypeConfiguration {
  linkUrl?: ResolvedUrl;
  shareLinkUrl?: ResolvedUrl;
  icon?: string;
  [k: string]: unknown;
}

export interface ThemeConfiguration {
  fontFamily?: string;
  primaryForegroundColor?: string;
  primaryAccentColor?: string;
  primaryHighlightColor?: string;
  primaryBackgroundColor?: string;
  invertedForegroundColor?: string;
  invertedAccentColor?: string;
  invertedHighlightColor?: string;
  invertedBackgroundColor?: string;
  overlayForegroundColor?: string;
  overlayAccentColor?: string;
  overlayHighlightColor?: string;
  [k: string]: unknown;
}

const MANUAL_OVERRIDE_LINKS = {
  'fa': {
    'bha': {
      'article': 'media-article-popup',
      'video': 'media-video-popup',
      'gallery': 'media-gallery-popup',
      'audio': 'media-audio-popup',
    }
  },
};

const USE_MODAL = {
  'fa': {
    'bha': true,
    'bir': false,
    'lei': false,
  }
};

@injectable()
export class CardsPageContentManager {
  public constructor(
    @injectToken(LoggerToken) private readonly log: Logger,
    @injectToken(CardsDynamicConfigurationProviderToken) private readonly dcp: CardsDynamicConfigurationProvider
  ) {}

  public getMediaTypeConfigurationStore(typeCode: string, slug: string, overrideLink?: string): Readable<MediaTypeConfiguration> {
    return derived([], () => {
      return this.getMediaTypeConfiguration(typeCode, slug, overrideLink);
    });
    /*
    // this.dcp.get().getObjectStore('media-type', typeCode)
    // [obj]
    typeCode = (typeCode || '').toLowerCase();
    const viewerPageSlug = MANUAL_OVERRIDE_LINKS?.[CONFIG.league]?.[CONFIG.tricode]?.[typeCode];
    overrideLink = overrideLink || ((viewerPageSlug)? `${viewerPageSlug}/${slug}` : null);
    const useModal = USE_MODAL?.[CONFIG.league]?.[CONFIG.tricode] || false;
    return derived([], () => {
      switch (typeCode) {
        case 'article':
          return {
            linkUrl: resolveUrl(overrideLink || `media-article-popup/${slug}`, undefined, { modal: useModal }),
            shareLinkUrl: resolveUrl(overrideLink || `media-article-popup/${slug}`),
            icon: 'yc_icon_news'
          };
        case 'video':
          return {
            linkUrl: resolveUrl(overrideLink || `media-video-popup/${slug}`, undefined, { modal: useModal }),
            shareLinkUrl: resolveUrl(overrideLink || `media-video-popup/${slug}`),
            icon: 'yc_icon_video'
          };
        case 'gallery':
          return {
            linkUrl: resolveUrl(overrideLink || `media-gallery-popup/${slug}`, undefined, { modal: useModal }),
            shareLinkUrl: resolveUrl(overrideLink || `media-gallery-popup/${slug}`),
            icon: 'yc_icon_gallery'
          };
        case 'audio':
          return {
            linkUrl: resolveUrl(overrideLink || `media-audio-popup/${slug}`, undefined, { modal: useModal }),
            shareLinkUrl: resolveUrl(overrideLink || `media-audio-popup/${slug}`),
            icon: 'yc_icon_headphones'
          };
        }
      return {
        linkUrl: undefined,
        icon: undefined
      };
    });
    */
  }

  public getMediaTypeConfiguration(typeCode: string, slug: string, overrideLink?: string): MediaTypeConfiguration {
    // this.dcp.get().getObjectStore('media-type', typeCode)
    // [obj]
    //console.log('getMediaTypeConfiguration', { typeCode, slug, overrideLink});
    typeCode = (typeCode || '').toLowerCase();
    const viewerPageSlug = MANUAL_OVERRIDE_LINKS?.[CONFIG.league]?.[CONFIG.tricode]?.[typeCode];
    overrideLink = overrideLink || ((viewerPageSlug)? `${viewerPageSlug}/${slug}` : null);
    const useModal = USE_MODAL?.[CONFIG.league]?.[CONFIG.tricode] || false;
    switch (typeCode) {
      case 'article':
        return {
          linkUrl: resolveUrl(overrideLink || `media-article/${slug}`, undefined, { modal: useModal }),
          shareLinkUrl: resolveUrl(overrideLink || `media-article/${slug}`),
          icon: 'yc_icon_news'
        };
      case 'video':
        return {
          linkUrl: resolveUrl(overrideLink || `media-video/${slug}`, undefined, { modal: useModal }),
          shareLinkUrl: resolveUrl(overrideLink || `media-video/${slug}`),
          icon: 'yc_icon_video'
        };
      case 'gallery':
        return {
          linkUrl: resolveUrl(overrideLink || `media-gallery/${slug}`, undefined, { modal: useModal }),
          shareLinkUrl: resolveUrl(overrideLink || `media-gallery/${slug}`),
          icon: 'yc_icon_gallery'
        };
      case 'audio':
        return {
          linkUrl: resolveUrl(overrideLink || `media-audio/${slug}`, undefined, { modal: useModal }),
          shareLinkUrl: resolveUrl(overrideLink || `media-audio/${slug}`),
          icon: 'yc_icon_headphones'
        };
    }
    return {
      linkUrl: undefined,
      icon: undefined
    };
  }

  public getThemeConfigurationStore(slug: string): Readable<ThemeConfiguration> {
    return derived([this.dcp.get().getObjectStore('theme', slug)], ([obj]) => {
      return obj as ThemeConfiguration;
    });
  }

  public getPageConfigurationStore(slug: string): Readable<any> {
    return derived([this.dcp.get().getObjectStore('page', slug)], ([obj]) => {
      return obj as any;
    });
  }
}
