<script context="module" lang="ts">var _a;
export { specification } from "./spec";
</script>

<script lang="ts">var _a;
import { get, isNil } from "lodash";
import { t } from "js/i18n";
import { formatDateTimeLocal, formatDateTimeRelative, formatDuration, formatNumber, } from "components/cards/utilities";
import { BaseAtom, getContentManager, } from "components/cards/atoms";
import { TextParticle, ImageParticle, ButtonParticle, } from "components/cards/particles/YinzCam";
import { writable } from "svelte/store";
import "js/url";
import { DateTime } from "luxon";
const hovering = writable(false);
export let container;
export let overlayPadding;
export let mediaType;
export let slug;
export let link;
export let publishDateTime;
export let viewTimeDuration;
export let views;
export let thumbnail;
export let titleText;
export let descriptionText;
export let txtDateTime;
export let imgClockIcon;
export let imgShareIcon;
export let shouldDisplayBodyText;
export let reverseOrientation;
export let displayDateDuration;
export let shouldDisplayTime;
export let shouldDisplayDuration;
export let shouldDisplayViews;
export let textBoxWidth;
export let categories;
export let shouldDisplayCategories;
let atomWidthRem;
$: mtc = getContentManager().getMediaTypeConfigurationStore(mediaType, slug, link);
$: resolvedLink = (_a = $mtc === null || $mtc === void 0 ? void 0 : $mtc.linkUrl) === null || _a === void 0 ? void 0 : _a.href;
$: mediaTypeIcon = $mtc === null || $mtc === void 0 ? void 0 : $mtc.icon;
$: isTablet = atomWidthRem <= 53 && atomWidthRem > 20;
$: isMobile = atomWidthRem <= 20;
$: txtDurationValue = formatDateTimeRelative(publishDateTime);
$: publishDateTimeDisplay = formatDateTimeLocal(publishDateTime, DateTime.DATE_HUGE);
$: viewDurationDisplay = formatDuration(viewTimeDuration);
$: viewsDisplay = formatNumber(views);
$: titleTextMultiplier = isMobile ? "0.6" : isTablet ? "0.7" : "1.00";
$: textWidth = `${parseInt(textBoxWidth) < 100 ? textBoxWidth : 50}%`;
$: imageWidth = `${100 - parseInt(textWidth)}%`;
</script>

<BaseAtom
    bind:_clientWidthRem={atomWidthRem}
    _isotope="RegularHorizontalAtom"
    _cssVariablePrefix="regular-horizontal"
    _link={resolvedLink}
    {...container}
    {overlayPadding}
    {textWidth}
    {imageWidth}
>
    <div
        class="main-container"
        class:layout-mobile-width={isMobile}
        class:layout-tablet-width={isTablet}
        class:reverse={reverseOrientation === "text-first"}
    >
        <div class="image-container">
            <ImageParticle {...thumbnail} _block={true} />
        </div>
        <div class="text-container">
            <div class="top-row">
                <div class="categories-box">
                    {#if shouldDisplayCategories}
                        <div class="category-list">
                            {#each categories || [] as category}
                                <ButtonParticle {...category} />
                            {/each}
                        </div>
                    {/if}
                </div>

                <div class="title-description">
                    <TextParticle
                        {...titleText}
                        size={`calc(${get(titleText, "size")}*${titleTextMultiplier})`}
                    />
                    {#if shouldDisplayBodyText}
                        <TextParticle {...descriptionText} />
                    {/if}
                </div>
            </div>
            <div class="description-footer">
                <div class="footer-content">
                    <div class="time-content">
                        {#if shouldDisplayTime && !isNil(publishDateTime)}
                            <div class="clock-duration">
                                <ImageParticle {...imgClockIcon} _block={true}/>
                                <TextParticle
                                    {...txtDateTime}
                                    text={displayDateDuration === "duration"
                                        ? txtDurationValue
                                        : publishDateTimeDisplay}
                                    _block={true}
                                />
                            </div>
                        {/if}
                        {#if shouldDisplayDuration && !isNil(viewTimeDuration)}
                            <div class="minute-read">
                                <TextParticle
                                    {...txtDateTime}
                                    text="| {viewDurationDisplay || ''} {$t(
                                        'read',
                                    )}"
                                />
                            </div>
                        {/if}
                        {#if shouldDisplayViews && !isNil(views)}
                            <div class="views-count">
                                <TextParticle
                                    {...txtDateTime}
                                    text="| {viewsDisplay || ''} {$t('views')}"
                                />
                            </div>
                        {/if}
                    </div>
                    <div class="share-icon">
                        <ImageParticle {...imgShareIcon} _block={true} />
                    </div>
                </div>
            </div>
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 1rem;
    }
    .main-container.reverse {
        flex-direction: row-reverse;
    }

    .image-container {
        flex: 0 0 var(--regular-horizontal-image-width);
    }

    .text-container {
        display: flex;
        flex: 0 1 var(--regular-horizontal-text-width);
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;
        padding: var(--regular-horizontal-overlay-padding, unset);
    }
    .title-description {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .footer-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-basis: max-content;
    }

    .clock-duration {
        display: flex;
        justify-content: end;
        align-items: center;
    }
    .time-content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        -webkit-column-gap: 0.5rem;
           -moz-column-gap: 0.5rem;
                column-gap: 0.5rem;
    }
    .share-icon {
        width: 10%;
    }
    .category-list {
        display: flex;
        flex-direction: row;
        align-items: start;
        -webkit-column-gap: 1rem;
           -moz-column-gap: 1rem;
                column-gap: 1rem;
        flex-wrap: wrap;
    }
    .top-row{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
    }</style>
