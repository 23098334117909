<script lang="ts">var _a, _b, _c;
import 'yinzcam-cards';
import CardBase from '../../../common/CardBase.svelte';
import { getFirstSourceStore } from '../../../utilities';
import '../../../common/CardsDataSourceRegistration';
import { CardsTabContextKey } from '../../../common/context';
import '../../../../../js/i18n';
import 'svelte/store';
import { getContext } from 'svelte';
import '../../../../../js/stores';
import PenaltyCard from './PenaltyCard/PenaltyCard.svelte';
import GoalCard from './GoalCard/GoalCard.svelte';
import InOutCard from './InOutCard/InOutCard.svelte';
import FoulOffsidesCard from './FoulOffsidesCard/FoulOffsidesCard.svelte';
export let element;
export let sources;
export let sourceStore;
const tabContext = getContext(CardsTabContextKey);
$: sourceStore = getFirstSourceStore($sources);
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
let data = {};
$: {
    data = $sourceStore || {};
    data.HomeLogo = `https://resources-uk.yinzcam.com/soccer/shared/logos/${(_b = data === null || data === void 0 ? void 0 : data.HomeLogoId) === null || _b === void 0 ? void 0 : _b.toLowerCase()}_light.png`;
    data.AwayLogo = `https://resources-uk.yinzcam.com/soccer/shared/logos/${(_c = data === null || data === void 0 ? void 0 : data.AwayLogoId) === null || _c === void 0 ? void 0 : _c.toLowerCase()}_light.png`;
    data.TeamLogo = (data === null || data === void 0 ? void 0 : data.IsHome) ? data === null || data === void 0 ? void 0 : data.HomeLogo : data === null || data === void 0 ? void 0 : data.AwayLogo;
}
</script>

<CardBase {element}>
  <div class="matchevent-card">
    {#if data.Type === 'FOUL'}
      <FoulOffsidesCard {data} text="FOUL" />
    {:else if data.Type === 'YELLOW'}
      <PenaltyCard {data} text="YELLOW CARD" />
    {:else if data.Type === 'CORNER'}
      <FoulOffsidesCard {data} text="CORNER" />
    {:else if data.Type === 'SUB'}
      <InOutCard {data} />
    {:else if data.Type === 'SECOND_YELLOW'}
      <PenaltyCard {data} text="SECOND YELLOW" />
    {:else if data.Type === 'OFF_SIDE'}
      <FoulOffsidesCard {data} text="OFFSIDES" />
    {:else if data.Type === 'RED'}
      <PenaltyCard {data} text="RED" />
    {:else if data.Type === 'GOAL'}
      <GoalCard {data} />
    {:else if data.Type === 'START_PERIOD'}
      <FoulOffsidesCard {data} text="START_PERIOD" />
    {:else if data.Type === 'END_PERIOD'}
      <FoulOffsidesCard {data} text="END_PERIOD" />
    {:else}
      <PenaltyCard {data} text={data.Type} />
    {/if}
  </div>
</CardBase>

<style lang="scss">*,
*:before,
*:after {
  box-sizing: border-box; }

.matchevent-card {
  width: 185px;
  height: 250px !important;
  border-radius: 12px;
  overflow: hidden; }</style>
