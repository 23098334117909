<Boundary>
  <CardsComponentWrapper {messageHandler} sticky={section.sticky} style="
    {buildCssPadding(padding)}
    {buildCssMargin(margin)}
    {buildCssBackground(background)}
    {buildCssBorder(section.border)}
    {buildCssFont(section.font)}
    {buildCssScrollContainer(height)}
    {(hidden)? 'display: none;' : ''}
    {(zoom)? `zoom: ${zoom};` : ''}
    {(createStackingContext)? 'isolation: isolate;' : ''}
  " {editorOptions} {readyToRender}>
    {#if !hidden}
    <CardsColumns parentSequenceId={sequenceId} columns={section.columns} updater={(columns) => section.columns = columns} />
    {/if}
  </CardsComponentWrapper>
</Boundary>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }</style>

<script lang="ts">var _a, _b, _c;
import "yinzcam-cards";
import { applyImageTransformation, buildCssBackground, buildCssBorder, buildCssFont, buildCssMargin, buildCssPadding, buildCssScrollContainer, getImageTransformationSlug, getRenderDelayForSequenceId, rewriteExternalCDNURL } from "../utilities/index";
import CardsColumns from "./CardsColumns.svelte";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import _ from "lodash";
import CardsComponentWrapper from './CardsComponentWrapper.svelte';
import "./CardsEditorInterfaces";
import { CARDS_SECTION_SCHEMA } from "./CardsEditorComponentSchemata";
import { windowMetrics as wm } from '../../../js/stores';
import "./actions";
import { onMount } from "svelte";
export let parentSequenceId = null;
export let sequenceId = null;
export let section;
export let updater;
let readyToRender = false;
let actionOverrides = {};
function messageHandler(action) {
    switch (action.effect) {
        case 'hide':
            actionOverrides.hidden = true;
            break;
        case 'show':
            actionOverrides.hidden = false;
            break;
        case 'set-background':
            actionOverrides.background = action.layerBackground;
            break;
        case 'set-border':
            actionOverrides.border = action.layerBorder;
            break;
        default:
            console.log("CardsColumn - Ignoring message, no suitable message handler found.");
            break;
    }
}
let hidden, height, zoom, padding, margin, background, createStackingContext;
$: if (section && ($wm === null || $wm === void 0 ? void 0 : $wm.width)) {
    let clone = _.cloneDeep(section);
    // Apply responsive properties
    //console.log('CHECKING RESPONSIVE CONFIGURATION', currentLayout?.responsiveness);
    const responsiveConfiguration = (_c = (_b = (_a = clone.responsiveness) === null || _a === void 0 ? void 0 : _a.filter((i) => i.maxWidth >= 0)) === null || _b === void 0 ? void 0 : _b.sort((a, b) => a.maxWidth - b.maxWidth)) === null || _c === void 0 ? void 0 : _c.find((i) => i.maxWidth >= $wm.width);
    if (responsiveConfiguration) {
        //console.log('COLUMN FOUND RESPONSIVE CONFIGURATION', responsiveConfiguration);
        for (const k in responsiveConfiguration) {
            if (k === 'maxWidth') {
                continue;
            }
            const val = responsiveConfiguration[k];
            if (!_.isNil(val)) {
                clone[k] = val;
            }
        }
    }
    // Apply action overrides
    clone = _.merge(clone, actionOverrides);
    // Apply background image optimization
    let modifiedBackground = clone.background;
    if (modifiedBackground) {
        try {
            modifiedBackground.url = rewriteExternalCDNURL(modifiedBackground.url);
            /*
            if (_clientWidthPx && _clientHeightPx) {
            }
            */
            const xfSlug = getImageTransformationSlug('background', 1, 1);
            if (xfSlug) {
                modifiedBackground.url = applyImageTransformation(modifiedBackground.url, xfSlug);
            }
        }
        catch (e) {
            console.warn('error modifying background image URL', e);
        }
    }
    hidden = !_.isNil(clone.hidden) && clone.hidden;
    height = clone.height;
    zoom = clone.zoom;
    padding = clone.padding;
    margin = clone.margin;
    background = clone.background;
    createStackingContext = clone.createStackingContext;
}
else {
    hidden = true, height = undefined, zoom = undefined, padding = undefined, margin = undefined, createStackingContext = false;
}
let editorOptions;
$: editorOptions = {
    parentSequenceId,
    sequenceId,
    componentId: section.id,
    component: section,
    componentTypeName: 'Section',
    childTypeNames: ['Column'],
    primaryColor: '#19DEC6',
    reverseTextColor: 'black',
    unselectedBackgroundColor: '#e6e6e6',
    unselectedTextColor: '#000000',
    selectedBackgroundColor: 'rgb(0, 122, 255)',
    selectedTextColor: '#FFFFFF',
    childrenKey: 'columns',
    configSpec: CARDS_SECTION_SCHEMA,
    allowChildren: true,
    allowDeletion: true,
    allowDuplication: true,
    allowMovement: true,
    allowCopyPaste: true,
    updater
};
onMount(() => {
    const renderDelay = getRenderDelayForSequenceId(sequenceId);
    if (!isNaN(renderDelay) && renderDelay > 0) {
        setTimeout(() => readyToRender = true, renderDelay);
    }
    else {
        readyToRender = true;
    }
});
</script>
