import { generateArraySchema, generateBooleanSchema, generateDateTimeSchema, generateDurationSchema, generateNumberSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema, generateColorSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ButtonParticleSpec, ImageParticleSpec, RichTextParticleSpec, TextParticleSpec, DropDownParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Blue Octopus Careers", generateRootObjectSchema({
  container: generateAtomContainerSchema({
    defaultValues: { themeMode: 'primary' },
    uneditableFields: { background: false }
  }),
  feedUrl: generateUrlSchema("Feed URL", "The Blue Octopus feed URL."),
  itemBoxPadding: generateStringSchema("Item Box Padding", "The padding of the overall item box.", { defaultValue: '1rem 0rem 0rem 0rem' }),
  overlayPadding: generateStringSchema("Overlay Padding", "The padding (inset) of the overlay text/buttons relative to the background image.", { defaultValue: '0rem 1rem' }),
  topLeftButton: ButtonParticleSpec.generateSchema("Category", "A category for the media item.", {
    defaultValues: { showImage: false, container: { padding: '0.5rem 0rem' }, text: { size: '1.5rem', weight: '600',  color: 'rgb(163, 161, 167)', transform: 'uppercase' } }
  }),
  topRightButton: ButtonParticleSpec.generateSchema("Top Right Button", "The top right button.", {
    defaultValues: { container: { padding: '0.5rem 0rem' }, text: { size: '1.25rem', weight: '600', color: 'rgb(163, 161, 167)' }, image: { icon: 'yc_icon_location', iconColor: 'rgb(163, 161, 167)', container: { width: '2.5rem', height: '2.5rem' } } }
  }),
  titleText: TextParticleSpec.generateSchema("Title Text", "The title text.", {
    defaultValues: { size: '2rem', weight: '900', useAccentColor: true, container: { centerContentHorizontally: false, padding: '1rem 0rem 1rem 0rem', border: { visible: true, style: 'solid', color: 'rgb(233, 233, 233)', width: '0px 0px 2px 0px' } } },
  }),
  descriptionText: RichTextParticleSpec.generateSchema("Description Text", "The description text.", {
    defaultValues: { size: '1rem', weight: '600', container: { centerContentVertically: false, padding: '0rem 0rem 0rem 0rem' } },
  }),
  ctaButton: ButtonParticleSpec.generateSchema("CTA", "The CTA for the media item.", {
    defaultValues: { showImage: false, container: { margin: '0rem 0rem 0rem 0rem', fillWidth: false, width: 'max-content', themeMode: 'inverted', background: { visible: true }, border: { visible: true, radius: '2rem' }, padding: '0.75rem 1.25rem' }, text: { size: '1.25rem', weight: '600', text: 'Read More', transform: 'uppercase' } }
  }),
  footerBackgroundColor: generateColorSchema("Footer Background Color", "The background color of the footer.", { defaultValue: 'rgb(233, 233, 233)' }),
  footerText: TextParticleSpec.generateSchema("Footer Text", "The footer text.", {
    defaultValues: { size: '1.5rem', weight: '600' },
  }),
  footerButton: ButtonParticleSpec.generateSchema("Footer Button", "The footer button.", {
    defaultValues: { showImage: false, container: { fillWidth: false, width: 'max-content', background: { visible: true, color: 'rgb(163, 161, 167)' }, border: { visible: true, radius: '0.5rem' }, padding: '0.75rem 1.25rem' }, text: { size: '1.25rem', weight: '600', color: 'white' } }
  }),
  dropdownProps: DropDownParticleSpec.generateSchema("DropDown Props", "DropDown Props")
}));
