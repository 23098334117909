//import { JSONSchema4 } from 'json-schema';
import { ExtendedJSONSchema, FromExtendedSchema } from 'json-schema-to-ts';


export type RecursivePartial<T> = {
  [P in keyof T]?:
    T[P] extends (infer U)[] ? RecursivePartial<U>[] :
    T[P] extends object | undefined ? RecursivePartial<T[P]> :
    T[P];
};

export type YinzCamCardsComponentSchemaExtension = {
  propertyOrder: string[],
  options?: {
    enum_titles?: string[],
    jodit?: {
      disabled?: boolean,
    },
    upload?: object,
  },
  links?: { href?: string }[],
  uneditable: boolean,
};

export type YinzCamCardsComponentSchema = Exclude<ExtendedJSONSchema<YinzCamCardsComponentSchemaExtension>, Boolean>;

// In case this is needed in the future:
// type Writeable<T> = { -readonly [P in keyof T]: T[P] };
// type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> };

export type YinzCamCardsComponentProps<T extends YinzCamCardsComponentSchema> = RecursivePartial<FromExtendedSchema<YinzCamCardsComponentSchemaExtension, T>>;

// Below are useful for testing.
/*
const testSchema = {
  type: 'object',
  properties: {
    foo: generatePrimitiveSchema('string', "Foo", "This is a foo.")
  },
  additionalProperties: false
} as const satisfies YinzCamCardsComponentSchema

export type TestProps = YinzCamCardsComponentProps<typeof testSchema>;

const testSchema2 = generateRootObjectSchema({
  foo: generatePrimitiveSchema('string', "Foo", "This is a foo."),
  bar: generatePrimitiveSchema('boolean', "Bar", "This is a bar."),
  baz: generateObjectSchema("Baz", "This is a baz.", {
    foo: generatePrimitiveSchema('string', "Foo", "This is a foo."),
    bar: generatePrimitiveSchema('boolean', "Bar", "This is a bar."),
  })
}, {
  propertyOrder: [ 'bar', 'foo' ]
});

export type TestProps2 = YinzCamCardsComponentProps<typeof testSchema2>;
*/

export const layerRepeatSchema = {
  "title": "Repeat",
  "description": "The number of times this component is repeated in order.",
  "type": "integer",
  "minimum": 1
};

export const layerPaddingSchema = {
  "title": "Padding",
  "type": "object",
  "format": "normal",
  "properties": {
    "top": {
      "type": "string",
      "title": "Top",
      "description": "Top padding in standard CSS units."
    },
    "right": {
      "type": "string",
      "title": "Right",
      "description": "Right padding in standard CSS units."
    },
    "bottom": {
      "type": "string",
      "title": "Bottom",
      "description": "Bottom padding in standard CSS units."
    },
    "left": {
      "type": "string",
      "title": "Left",
      "description": "Left padding in standard CSS units."
    }
  }
};

export const layerMarginSchema = {
  "title": "Margin",
  "type": "object",
  "format": "normal",
  "properties": {
    "top": {
      "type": "string",
      "title": "Top",
      "description": "Top margin in standard CSS units. Negative values are allowed."
    },
    "right": {
      "type": "string",
      "title": "Right",
      "description": "Right margin in standard CSS units. Negative values are allowed."
    },
    "bottom": {
      "type": "string",
      "title": "Bottom",
      "description": "Bottom margin in standard CSS units. Negative values are allowed."
    },
    "left": {
      "type": "string",
      "title": "Left",
      "description": "Left margin in standard CSS units. Negative values are allowed."
    }
  }
};

export const layerBorderSchema = {
  "title": "Border",
  "type": "object",
  "format": "normal",
  "properties": {
    /*
    "style": {
      "type": "string",
      "title": "Style",
      "description": "The border style.",
      "enum": [
        'none', 'solid', 'dotted', 'dashed', 'double', 'groove', 'ridge', 'inset', 'outset', 'hidden'
      ],
      "options": {
        "enum_titles": [
          'None', 'Solid', 'Dotted', 'Dashed', 'Double', '3D Groove', '3D Ridge', '3D Inset', '3D Outset', 'Hidden'
        ]
      }
    },
    "color": {
      "title": "Color",
      "description": "The border color.",
      "type": "string",
      "format": "color"
    },
    */
    "top": {
      "type": "string",
      "title": "Top",
      "description": "Top border in standard CSS syntax. Example: 1px solid gray."
    },
    "right": {
      "type": "string",
      "title": "Right",
      "description": "Right border in standard CSS syntax. Example: 1px solid gray."
    },
    "bottom": {
      "type": "string",
      "title": "Bottom",
      "description": "Bottom border in standard CSS syntax. Example: 1px solid gray."
    },
    "left": {
      "type": "string",
      "title": "Left",
      "description": "Left border in standard CSS syntax. Example: 1px solid gray."
    },
    "radius": {
      "type": "string",
      "title": "Radius",
      "description": "Border radius in standard CSS units."
    }
  }
} as const satisfies YinzCamCardsComponentSchema;
export type LayerBorder = YinzCamCardsComponentProps<typeof layerBorderSchema>;

export const layerBackgroundSchema = {
  "title": "Background",
  "type": "object",
  "format": "normal",
  "properties": {
    "color": {
      "title": "Color",
      "description": "A solid background color. Will be overridden if an image is specified.",
      "type": "string",
      "format": "color"
    },
    "url": {
      "type": "string",
      "format": "url",
      "title": "Image",
      "description": "The image for the background. This takes precedence over color.",
      "options": { "upload": {} },
      "links": [ { "href": "{{self}}" } ]
    },
    "size": {
      "type": "string",
      "title": "Size",
      "description": "Cover: The image will be resized to fill the area and cropped to maintain aspect ratio. Contain: The image will be scaled up or down so that the entire image fits within the area, maintaining aspect ratio.",
      "enum": [
        "COVER",
        "CONTAIN"
      ],
      "options": {
        "enum_titles": [
          "Cover",
          "Contain"
        ]
      }
    },
    "attachment": {
      "type": "string",
      "title": "Attachment",
      "description": "Fixed: The image will remain always in the same position on the screen. Local: The background image will scroll with the content. Scroll: The background image will scroll with the content's container.",
      "enum": [
        "FIXED",
        "LOCAL",
        "SCROLL"
      ],
      "options": {
        "enum_titles": [
          "Fixed",
          "Local",
          "Scroll"
        ]
      }
    },
    "position": {
      "type": "string",
      "title": "Position",
      "description": "The anchor point of the background for cropping. A combination of (top, center, bottom) and (left, center, right). For example, use \"center center\" to center crop.",
    },
    "repeat": {
      "type": "string",
      "title": "Repeat",
      "description": "Repeat: Enables repeating the background to cover the target area. No Repeat: Disables repeating the background (default).",
      "enum": [
        "REPEAT",
        "REPEAT-X",
        "REPEAT-Y",
        "NO-REPEAT",
        "SPACE",
        "ROUND"
      ],
      "options": {
        "enum_titles": [
          "Repeat",
          "Repeat left-to-right only",
          "Repeat top-to-bottom only",
          "No Repeat",
          "Space",
          "Round"
        ]
      }
    }
  }
} as const satisfies YinzCamCardsComponentSchema;
export type LayerBackground = YinzCamCardsComponentProps<typeof layerBackgroundSchema>;

export const layerConditionsSchema = {
  "title": "Conditions",
  "type": "object",
  "format": "normal",
  "properties": {
    "isNative": {
      "type": "boolean",
      "title": "App Only?",
      "description": "Only show this component when displayed within a native mobile app."
    },
    "isDesktop": {
      "type": "boolean",
      "title": "Web Only?",
      "description": "Only show this component when displayed on a website inside a browser."
    },
    "not": {
      "type": "boolean",
      "title": "Invert Logic",
      "description": "Show this component when these conditions are NOT met."
    }
  }
};

export const layerStickySchema = {
  "type": "boolean",
  "title": "Sticky",
  "description": "Whether this layer should stick to the top of its scroll container."
};
