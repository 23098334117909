<Boundary> 
  <CardsContainerWrapper style="
    display: grid;
    grid-template-columns: repeat({(layout.splitEqually)? ((layout.elements && layout.elements.length > 0)? layout.elements.length : 1) : layout.maxColumns || 1}, minmax(0, 1fr));
    grid-auto-rows: {(_.isNil(layout.uniformHeight) || layout.uniformHeight)? '1fr' : 'min-content'};
    gap: {(_.isNumber(layout.gapPixels))? layout.gapPixels : 10}px;
    {(_.isNumber(layout.columnGapPixels))? `column-gap: ${layout.columnGapPixels}px;` : ''}
    {(_.isNumber(layout.rowGapPixels))? `row-gap: ${layout.rowGapPixels}px;` : ''}
    width: 100%;
    height: {layout.height};
    overflow: {layout.overflow || 'revert'};
  " {editorOptions}>
  {#each layout.elements as element, i (element.id)}
    <CardsElement {parentSequenceId} sequenceId={generateSequenceId('ELT', parentSequenceId, i)} {element} height='100%' bind:contentAvailable={childContentAvailable[element.id]} updater={elementUpdater.bind(null, i)} />
  {/each}
  </CardsContainerWrapper>
</Boundary>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }</style>

<script lang="ts">/*
    <div style="
      grid-column-end: {(element.span && element.span.columns)? `${element.span.columns} span` : 'initial'};
      grid-row-end: {(element.span && element.span.rows)? `${element.span.rows} span` : 'initial'};
    "
    class:display-none={childContentAvailable[element.id] === false}
  >
        </div>
*/
import "yinzcam-cards";
import { generateSequenceId } from "../utilities";
import CardsElement from "./CardsElement.svelte";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import "./CardsEditorInterfaces";
import CardsContainerWrapper from "./CardsContainerWrapper.svelte";
import _ from "lodash";
export let parentSequenceId = "";
export let layout;
export let contentAvailable = true;
export let updater;
let childContentAvailable = {};
$: {
    contentAvailable = Object.values(childContentAvailable).some((item) => item);
}
//$: console.log("DND LAYOUT UPDATED", layout);
function elementUpdater(i, element) {
    //console.log("ARRAYGRIDLAYOUT elementUpdater", element);
    layout.elements[i] = element;
    updater(layout.elements);
}
let editorOptions;
$: editorOptions = {
    parentSequenceId,
    items: layout.elements,
    updater
};
</script>
