<Boundary>
  <!--
    align-items: stretch; /* ensures content fills the entire grid area. A bug noted in desktop news items. Original value of "center" */
    transform: translateZ(0); /* this fixes a bug in Safari rendering where it does not correctly apply overflow: hidden when using a border radius */
    /* see https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari */
    transform: translateZ(0);

    {(display)? `display: ${display} !important;` : ''}
  -->
  <CardsComponentWrapper {messageHandler} sticky={expandedElement?.sticky} style="
    display: flex;
    justify-content: center;
    align-items: stretch;
    height: {height};
    {cssDisplay}
    {cssVisibility}
    {buildCssBorder(expandedElement?.border)}
    {(expandedElement?.border?.radius)? 'overflow: hidden;' : ''}
    {buildCssPadding(expandedElement?.padding)}
    {buildCssMargin(expandedElement?.margin)}
    {buildCssBackground(expandedElement?.background)}
    {buildCssBoxShadow(expandedElement?.boxShadow)}
    {buildCssFont(expandedElement?.font)}
    {(expandedElement?.span?.columns)? `grid-column-end: ${element.span.columns} span` : ''};
    {(expandedElement?.span?.rows)? `grid-row-end: ${element.span.rows} span` : ''};
    {(expandedElement?.hidden)? 'display: none;' : ''}
    {(expandedElement?.createStackingContext)? 'isolation: isolate;' : ''}
  " parentAttributes={{...parentAttributes, 'data-element-class': (element)? element.class : ''}} {editorOptions}>
  {#if showPlaceholder}
    <!--
      {element}

      element={expandedElement}
    -->
    <PlaceholderCard
      element={expandedElement}
      {sources}
      width="100%"
      height="100px"
      title={$pbt(cardComponentName)}
    />
  {:else}
    {#if false}
    <div style="width: 100%; height: auto; aspect-ratio: 16/9;">
      asfopjasfiojasfiafisojfaijsofjio
    </div>
    {:else}
    <!--
      {element}

      element={expandedElement}
      {elementStore}
      {sources}
      bind:sourceStore={childSourceStore}
      {height}
    -->
    <svelte:component
      this={cardComponent}

      element={expandedElement}
      {elementStore}
      {sources}
      bind:sourceStore={childSourceStore}
      {height}


      bind:_editable={childEditable}
      bind:_enableEditorMode={childEnableEditorMode}
      bind:_disableEditorMode={childDisableEditorMode}
      _editorData={childEditorData}
      _transformData={childTransformData}
      _saveEditorData={childSaveEditorData}
      _saveTransformData={childSaveTransformData}

      
      bind:contentAvailable={childContentAvailable}
      bind:contentActive={childContentActive}
      bind:parentAttributes={parentAttributes}
      bind:defaultSourceClasses={childDefaultSourceClasses}
      {...spreadProps}
    />
    {/if}
  {/if}
  </CardsComponentWrapper>
</Boundary>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }</style>

<script lang="ts">var _a, _b, _c, _d, _e, _f, _g;
import _, { isArray } from "lodash";
import { getContext, onMount } from "svelte";
import { writable } from "svelte/store";
import "yinzcam-cards";
import PlaceholderCard from "components/cards/library/YinzCam/Core/PlaceholderCard/PlaceholderCard.svelte";
import "./CardsDataSourceRegistration";
import { CardsTabContextKey } from "./context";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import { buildCssBorder, buildCssBackground, buildCssPadding, buildCssMargin, getConditionsCheckStore, buildCssFont, buildCssBoxShadow, getFirstSourceStore, getFirstSourceRegistration, expandTemplateParamsRecursive, rewriteExternalCDNURL, getImageTransformationSlug, applyImageTransformation } from "../utilities/index";
import { CardsPageContextKey } from './context';
import './CardsEditorInterfaces';
import CardsComponentWrapper from './CardsComponentWrapper.svelte';
import { CARDS_ELEMENT_SCHEMA, generateElementDataSourcesSchema } from "./CardsEditorComponentSchemata";
import { getNamed } from 'inversify-token';
import ROOT from '../../../inversify.config';
import { CardsLibraryToken } from "./CardsLibraryToken";
import { pbt } from '../../../js/i18n';
import "json-schema";
import "./CardsDataObjectTransform";
import "./CardsDataPrimitiveTransform";
import * as Upcast from "upcast";
import "./CardsDataArrayTransform";
import "../atoms/BaseAtomSpec";
import "../particles/YinzCam";
import { t } from "../../../js/i18n";
import { monthList, personalStatList } from "../utilities/constants";
import "./actions";
import { windowMetrics as wm } from '../../../js/stores';
export let parentSequenceId = null;
export let sequenceId = null;
export let element;
export let height = 'initial';
export let contentAvailable = true; // exposed upstream for binding
export let contentActive = false; // exposed upstream for binding
export let updater;
const pageContext = getContext(CardsPageContextKey);
const tabContext = getContext(CardsTabContextKey);
const emmEnabled = pageContext.editorModeManager.getEnabledStore();
const emmPreview = pageContext.editorModeManager.getPreviewStore();
const elementStore = writable(element);
const upcast = Upcast.default;
const nullSources = writable([]);
const nullFirstSource = writable(null);
let expandedElement = null;
let mounted = false;
let childContentAvailable; // bound to card component
let childSourceStore; // bound to card component
let cssDisplay = ''; // display CSS on element (default to no change)
let cssVisibility = ''; // visibility CSS on element (default to invisible until content available)
let childContentActive;
let parentAttributes;
let childDefaultSourceClasses;
let sources = nullSources;
//let sourceStore: Readable<any>;
//$: { sourceStore = getFirstSourceStore($sources) }
//$: console.log('SOURCESTORE CHANGED', element.id, $sourceStore);
let sourcesUnsubscribe = null;
let firstSource = nullFirstSource;
let firstSourceRegistration = null;
let firstSourceUnsubscribe = null;
let firstSourceData = writable(null);
let spreadProps = {};
let inlineStyle = '';
let additionalRepeats = 0;
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
const elementConditionStore = writable(element === null || element === void 0 ? void 0 : element.conditions);
$: elementConditionStore.set(element === null || element === void 0 ? void 0 : element.conditions);
$: conditionsCheckStore = getConditionsCheckStore(elementConditionStore, tabContext.dataSourceManager, (_c = (_b = element === null || element === void 0 ? void 0 : element.conditions) === null || _b === void 0 ? void 0 : _b.dataSource) === null || _c === void 0 ? void 0 : _c.sourceId);
let actionOverrides = {};
function messageHandler(action) {
    console.log('message handler', action);
    switch (action.effect) {
        case 'hide':
            actionOverrides.hidden = true;
            break;
        case 'show':
            actionOverrides.hidden = false;
            break;
        case 'set-background':
            actionOverrides.background = action.layerBackground;
            break;
        case 'set-border':
            actionOverrides.border = action.layerBorder;
            break;
        case 'add-repeats':
            additionalRepeats += action.repeats || 0;
            if (!element['__originalRepeat']) {
                element['__originalRepeat'] = element.repeat || 1;
            }
            element.repeat = element['__originalRepeat'] + additionalRepeats;
            updater(element);
            break;
        default:
            console.log("CardsElement - Ignoring message, no suitable message handler found.");
            break;
    }
}
function updateSources(newSources) {
    if (!newSources) {
        newSources = nullSources;
    }
    if (newSources === sources) {
        return;
    }
    if (element.class === 'YinzCam.Stats.FixturesStackedAtom')
        console.log('updateSources changing sources list', element.id, newSources);
    if (sourcesUnsubscribe) {
        if (firstSourceUnsubscribe) {
            firstSourceUnsubscribe();
            firstSourceUnsubscribe = null;
        }
        sourcesUnsubscribe();
        sourcesUnsubscribe = null;
    }
    sources = newSources;
    sourcesUnsubscribe = sources.subscribe((sourceList) => {
        //console.log('updateSources new source list', element.id, sourceList);
        firstSourceRegistration = getFirstSourceRegistration(sourceList);
        let newFirstSource = getFirstSourceStore(sourceList);
        if (!newFirstSource) {
            newFirstSource = nullFirstSource;
        }
        //console.log(`updateSources first source is #${getObjectId(newFirstSource)}`, element.id);
        if (newFirstSource === firstSource) {
            return;
        }
        if (firstSourceUnsubscribe) {
            firstSourceUnsubscribe();
            firstSourceUnsubscribe = null;
        }
        firstSource = newFirstSource;
        //console.log(`updateSources changing first source to #${getObjectId(firstSource)}`, element.id);
        firstSourceUnsubscribe = firstSource.subscribe((sourceData) => {
            //console.log('updateSources changing first source data', element.id, sourceData);
            firstSourceData.set(sourceData);
        });
    });
}
function updateElementData($element, $cardSpec, $sequenceId, $mergedParams, $firstSourceData, $conditionsCheckStore, actionOverrides) {
    var _a, _b, _c;
    //if ($element.class === 'YinzCam.Stats.FixturesStackedAtom') console.log('updateElementData', sequenceId, $element, $mergedParams, $firstSourceData);
    if (!($element === null || $element === void 0 ? void 0 : $element.id) || !$mergedParams) {
        return;
    }
    let newSources;
    if ($sequenceId && $conditionsCheckStore && $element.sourceIds) {
        newSources = tabContext.dataSourceManager.register($element.id, $sequenceId, $element.sourceIds);
    }
    else {
        tabContext.dataSourceManager.unregister($element.id);
        newSources = writable([]);
    }
    updateSources(newSources);
    expandedElement = _.clone($element);
    if ($element.data) {
        /*
        const expandedData = {};
        for (const [key, value] of Object.entries($element.data)) {
          if (typeof value === 'string') {
            try {
              expandedData[key] = expandTemplateParams(value, $mergedParams);
            } catch (err) {
              expandedData[key] = value;
            }
          } else {
            expandedData[key] = value;
          }
        }
        expandedElement.data = expandedData;
        */
        expandedElement.data = expandTemplateParamsRecursive($element.data, $mergedParams);
    }
    // Apply responsive properties
    //console.log('ELEMENT CHECKING RESPONSIVE CONFIGURATION', currentLayout?.responsiveness);
    const responsiveConfiguration = (_c = (_b = (_a = expandedElement === null || expandedElement === void 0 ? void 0 : expandedElement.responsiveness) === null || _a === void 0 ? void 0 : _a.filter((i) => i.maxWidth >= 0)) === null || _b === void 0 ? void 0 : _b.sort((a, b) => a.maxWidth - b.maxWidth)) === null || _c === void 0 ? void 0 : _c.find((i) => i.maxWidth >= $wm.width);
    if (responsiveConfiguration) {
        for (const k in responsiveConfiguration) {
            if (k === 'maxWidth') {
                continue;
            }
            const val = responsiveConfiguration[k];
            if (!_.isNil(val)) {
                expandedElement[k] = val;
            }
        }
    }
    // Apply action overrides
    expandedElement = _.merge(expandedElement, actionOverrides);
    //console.log('expanded element after overrides', expandedElement, actionOverrides);
    // Apply background image optimization
    let modifiedBackground = expandedElement === null || expandedElement === void 0 ? void 0 : expandedElement.background;
    if (modifiedBackground) {
        try {
            modifiedBackground.url = rewriteExternalCDNURL(modifiedBackground.url);
            /*
            if (_clientWidthPx && _clientHeightPx) {
            }
            */
            const xfSlug = getImageTransformationSlug('background', 1, 1);
            if (xfSlug) {
                modifiedBackground.url = applyImageTransformation(modifiedBackground.url, xfSlug);
            }
        }
        catch (e) {
            console.warn('error modifying background image URL', e);
        }
    }
    //elementHash = hash.MD5(expandedElement);
    //console.log('updateElementData - expanded', elementHash, expandedElement);
    //expandedElement = expandedElement;
    elementStore.set(expandedElement);
    // If the card defines a config spec, create spread props for the card
    /*
    {
      "stringProp1": "...",
      "numberProp2": 12345,
      "booleanProp3": true,
      "dateProp3": new Date(...),
      "urlProp4": new URL(...),
      "arrayProp5": [
        { ... }
      ],
      "objectProp6": {
        "stringProp61": "...",
        ...
      }
    }
    */
    if ($cardSpec) {
        const configSpec = $cardSpec.getSchema(); //cardLibraryRecord.getElementConfigSpec();
        const transform = element.transform;
        if ((configSpec === null || configSpec === void 0 ? void 0 : configSpec.type) === "object" && _.isPlainObject(configSpec.properties)) {
            ;
            ;
            ;
            ;
            function runTransformTemporary(transformType, sourceData) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54, _55, _56, _57, _58, _59, _60, _61, _62, _63, _64, _65, _66, _67, _68, _69, _70, _71, _72, _73, _74, _75, _76, _77, _78, _79, _80, _81, _82, _83, _84, _85, _86, _87, _88, _89, _90, _91, _92, _93, _94, _95, _96, _97, _98, _99, _100, _101, _102, _103, _104, _105, _106, _107, _108, _109, _110, _111, _112, _113, _114, _115, _116, _117, _118, _119, _120, _121, _122, _123, _124, _125, _126, _127, _128, _129, _130, _131, _132, _133, _134, _135, _136, _137, _138, _139, _140, _141, _142, _143, _144, _145, _146, _147, _148, _149, _150, _151, _152, _153, _154, _155, _156, _157, _158, _159, _160, _161, _162, _163, _164, _165, _166, _167, _168, _169, _170, _171, _172, _173, _174, _175, _176, _177, _178, _179, _180, _181, _182, _183, _184, _185, _186, _187, _188, _189, _190, _191, _192, _193, _194, _195, _196, _197, _198, _199, _200, _201, _202, _203, _204, _205, _206, _207, _208, _209, _210, _211, _212, _213, _214, _215, _216, _217, _218, _219, _220, _221, _222, _223, _224, _225, _226, _227, _228, _229, _230, _231, _232, _233, _234, _235, _236, _237, _238, _239, _240, _241, _242, _243, _244, _245, _246, _247, _248, _249, _250, _251, _252, _253, _254, _255, _256, _257, _258, _259, _260, _261, _262, _263, _264, _265, _266, _267, _268, _269, _270, _271, _272, _273, _274, _275, _276, _277, _278, _279, _280, _281, _282, _283, _284, _285, _286, _287, _288, _289, _290, _291, _292, _293, _294, _295, _296, _297, _298, _299, _300, _301, _302, _303, _304, _305, _306, _307, _308, _309, _310, _311, _312, _313, _314, _315, _316, _317, _318, _319, _320;
                if (!sourceData || !transformType) {
                    return sourceData;
                }
                function convertContentfulRichTextToArticleBody(data) {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
                    const body = [];
                    if ((data === null || data === void 0 ? void 0 : data.nodeType) === 'document' && _.isArray(data === null || data === void 0 ? void 0 : data.content)) {
                        function getNodeForContainerNode(node) {
                            var _a;
                            const t = node === null || node === void 0 ? void 0 : node.nodeType;
                            if (t === 'ordered-list' || t === 'unordered-list') {
                                return {
                                    type: (t === 'ordered-list') ? 'ol' : 'ul',
                                    nodes: getNodesForListNode(node),
                                };
                            }
                            else if (t === 'table') {
                                return {
                                    type: 'table',
                                    nodes: getNodesForTableNode(node),
                                };
                            }
                            else if (t === 'hyperlink') {
                                return {
                                    type: 'link',
                                    uri: (_a = node === null || node === void 0 ? void 0 : node.data) === null || _a === void 0 ? void 0 : _a.uri,
                                    nodes: getNodesForTextContainerNode(node),
                                };
                            }
                            else if (t === 'paragraph' || t.startsWith('heading-')) {
                                return {
                                    type: 'text-block',
                                    nodes: getNodesForTextContainerNode(node),
                                };
                            }
                        }
                        function getNodesForTextContainerNode(node, allowHyperlink = true, fontSize = undefined) {
                            var _a, _b, _c;
                            if (!((_a = node === null || node === void 0 ? void 0 : node.content) === null || _a === void 0 ? void 0 : _a.length)) {
                                return [];
                            }
                            const headingRx = /heading-(\d+)/g;
                            const headingMatch = headingRx.exec(node.nodeType);
                            if (headingMatch !== null) {
                                const headingLevel = _.clamp(parseInt(headingMatch[1]), 1, 6);
                                const fontSizeRem = 3.5 - (0.5 * (headingLevel - 1));
                                fontSize = `${fontSizeRem}rem`;
                            }
                            const nodes = [];
                            for (const textNode of node.content) {
                                if ((textNode === null || textNode === void 0 ? void 0 : textNode.nodeType) !== 'text' && (!allowHyperlink || (textNode === null || textNode === void 0 ? void 0 : textNode.nodeType) !== 'hyperlink')) {
                                    console.error(`expected text or hyperlink node under ${node.nodeType} node, got:`, textNode);
                                    continue;
                                }
                                if ((textNode === null || textNode === void 0 ? void 0 : textNode.nodeType) === 'hyperlink') {
                                    nodes.push({
                                        type: 'link',
                                        uri: (_b = textNode === null || textNode === void 0 ? void 0 : textNode.data) === null || _b === void 0 ? void 0 : _b.uri,
                                        nodes: getNodesForTextContainerNode(textNode, false, fontSize),
                                    });
                                }
                                else if (textNode.value) {
                                    // regular text node
                                    const props = {};
                                    props.text = textNode.value;
                                    props.size = fontSize;
                                    if (((_c = textNode.marks) === null || _c === void 0 ? void 0 : _c.length) > 0) {
                                        for (const mark of textNode.marks) {
                                            switch (mark === null || mark === void 0 ? void 0 : mark.type) {
                                                case 'bold':
                                                    props.weight = '700';
                                                    break;
                                                case 'italic':
                                                    props.style = 'italic';
                                                    break;
                                                case 'underline':
                                                    props.decoration = `${props.decoration || ''} underline`.trimStart();
                                                    break;
                                                case 'strikethrough':
                                                    props.decoration = `${props.decoration || ''} line-through`.trimStart();
                                                    break;
                                                case 'code':
                                                    props.family = 'monospace';
                                                    break;
                                                case 'superscript':
                                                    props.subSuper = 'super';
                                                    break;
                                                case 'subscript':
                                                    props.subSuper = 'sub';
                                                    break;
                                            }
                                        }
                                    }
                                    nodes.push({
                                        type: 'text',
                                        particleProps: props,
                                    });
                                }
                            }
                            return nodes;
                        }
                        function getNodesForListNode(node) {
                            var _a, _b;
                            if (!((_a = node === null || node === void 0 ? void 0 : node.content) === null || _a === void 0 ? void 0 : _a.length)) {
                                return [];
                            }
                            const nodes = [];
                            for (const itemNode of node.content) {
                                if ((itemNode === null || itemNode === void 0 ? void 0 : itemNode.nodeType) !== 'list-item') {
                                    console.error(`expected list item node under ${node.type} node, got:`, itemNode);
                                    continue;
                                }
                                if (!((_b = itemNode === null || itemNode === void 0 ? void 0 : itemNode.content) === null || _b === void 0 ? void 0 : _b.length)) {
                                    continue;
                                }
                                const subNodes = [];
                                for (const subNode of itemNode.content) {
                                    const outNode = getNodeForContainerNode(subNode);
                                    if (outNode) {
                                        subNodes.push(outNode);
                                    }
                                }
                                if (subNodes.length > 0) {
                                    nodes.push({
                                        type: 'li',
                                        nodes: subNodes,
                                    });
                                }
                            }
                            return nodes;
                        }
                        function getNodesForTableNode(node) {
                            var _a, _b, _c;
                            if (!((_a = node === null || node === void 0 ? void 0 : node.content) === null || _a === void 0 ? void 0 : _a.length)) {
                                return [];
                            }
                            const nodes = [];
                            for (const rowNode of node.content) {
                                if ((rowNode === null || rowNode === void 0 ? void 0 : rowNode.nodeType) !== 'table-row') {
                                    console.error(`expected table row node under ${node.nodeType} node, got:`, rowNode);
                                    continue;
                                }
                                if (!((_b = rowNode === null || rowNode === void 0 ? void 0 : rowNode.content) === null || _b === void 0 ? void 0 : _b.length)) {
                                    continue;
                                }
                                const subNodes = [];
                                let headerRow = false;
                                for (const cellNode of rowNode.content) {
                                    if ((cellNode === null || cellNode === void 0 ? void 0 : cellNode.nodeType) !== 'table-header-cell' && (cellNode === null || cellNode === void 0 ? void 0 : cellNode.nodeType) !== 'table-cell') {
                                        console.error(`expected table cell under ${rowNode.nodeType} node, got:`, cellNode);
                                        continue;
                                    }
                                    if (!((_c = cellNode === null || cellNode === void 0 ? void 0 : cellNode.content) === null || _c === void 0 ? void 0 : _c.length)) {
                                        continue;
                                    }
                                    headerRow || (headerRow = cellNode.nodeType === 'table-header-cell');
                                    const subSubNodes = [];
                                    for (const containerNode of cellNode.content) {
                                        const outNode = getNodeForContainerNode(containerNode);
                                        if (outNode) {
                                            subSubNodes.push(outNode);
                                        }
                                    }
                                    if (subSubNodes.length > 0) {
                                        subNodes.push({
                                            type: 'table-cell',
                                            nodes: subSubNodes,
                                        });
                                    }
                                }
                                if (subNodes.length > 0) {
                                    nodes.push({
                                        type: (headerRow) ? 'table-header-row' : 'table-row',
                                        nodes: subNodes,
                                    });
                                }
                            }
                            return nodes;
                        }
                        for (const node of data === null || data === void 0 ? void 0 : data.content) {
                            const t = node === null || node === void 0 ? void 0 : node.nodeType;
                            if (t === 'embedded-asset-block') {
                                const fields = (_b = (_a = node === null || node === void 0 ? void 0 : node.data) === null || _a === void 0 ? void 0 : _a.target) === null || _b === void 0 ? void 0 : _b.fields;
                                const file = fields === null || fields === void 0 ? void 0 : fields.file;
                                if (!file) {
                                    console.error(`expected data.target.fields.file node under ${t} node, got:`, node.content);
                                    continue;
                                }
                                let captionProps = undefined;
                                if (fields.description) {
                                    captionProps = {
                                        captionText: {
                                            text: fields.description,
                                        }
                                    };
                                }
                                if ((_c = file === null || file === void 0 ? void 0 : file.contentType) === null || _c === void 0 ? void 0 : _c.startsWith('image/')) {
                                    body.push({
                                        type: 'image',
                                        particleProps: {
                                            source: file.url,
                                            alt: fields.title || file.fileName || "",
                                        },
                                        captionProps,
                                    });
                                }
                                else if ((_d = file === null || file === void 0 ? void 0 : file.contentType) === null || _d === void 0 ? void 0 : _d.startsWith('video/')) {
                                    body.push({
                                        type: 'video',
                                        particleProps: {
                                            sourceType: 'Direct Link',
                                            sourceUrl: file.url,
                                        },
                                        captionProps,
                                    });
                                }
                                else if ((_e = file === null || file === void 0 ? void 0 : file.contentType) === null || _e === void 0 ? void 0 : _e.startsWith('audio/')) {
                                    body.push({
                                        type: 'audio',
                                        particleProps: {
                                            source: file.url,
                                        },
                                        captionProps,
                                    });
                                }
                            }
                            else if (t === 'embedded-entry-block') {
                                const contentType = (_k = (_j = (_h = (_g = (_f = node === null || node === void 0 ? void 0 : node.data) === null || _f === void 0 ? void 0 : _f.target) === null || _g === void 0 ? void 0 : _g.sys) === null || _h === void 0 ? void 0 : _h.contentType) === null || _j === void 0 ? void 0 : _j.sys) === null || _k === void 0 ? void 0 : _k.id;
                                const fields = (_m = (_l = node === null || node === void 0 ? void 0 : node.data) === null || _l === void 0 ? void 0 : _l.target) === null || _m === void 0 ? void 0 : _m.fields;
                                if (contentType === 'webEmbed') {
                                    body.push({
                                        type: 'web-embed',
                                        particleProps: {
                                            name: fields.name,
                                            source: fields.url,
                                        },
                                    });
                                }
                            }
                            else {
                                // hyperlink, list, paragraph, heading, ...
                                const outNode = getNodeForContainerNode(node);
                                if (outNode) {
                                    body.push(outNode);
                                }
                            }
                        }
                    }
                    return body;
                }
                let ret = sourceData;
                try {
                    if (transformType === 'contentful-navigation') {
                        //console.log('SOURCE DATA FOR CONTENTFUL NAVIGATION TRANSFORM', sourceData);
                    }
                    else if (transformType === 'contentful-search-metadata') {
                        const title = $t('Search Results for');
                        return {
                            richText: { html: `${title} "${$mergedParams['query']}"` },
                        };
                    }
                    else if (transformType === 'contentful-video-viewer') {
                        //console.log('SOURCE DATA FOR CONTENTFUL VIDEO VIEWER', sourceData);
                        return Object.assign(Object.assign({ slug: sourceData === null || sourceData === void 0 ? void 0 : sourceData.slug, categories: ((sourceData === null || sourceData === void 0 ? void 0 : sourceData.categories) || []).map((c) => ({
                                text: { text: c.name },
                            })), titleText: {
                                text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.name,
                            }, descriptionText: {
                                text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.description,
                            }, publishDateTime: sourceData === null || sourceData === void 0 ? void 0 : sourceData.publishDateTime }, (((_a = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _a === void 0 ? void 0 : _a[0]) && { author: {
                                image: {
                                    source: (_e = (_d = (_c = (_b = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.primaryImage) === null || _d === void 0 ? void 0 : _d.file) === null || _e === void 0 ? void 0 : _e.url,
                                },
                                nameText: {
                                    text: (_f = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _f === void 0 ? void 0 : _f[0].displayName,
                                },
                                positionText: {
                                    text: (_g = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _g === void 0 ? void 0 : _g[0].description,
                                },
                            } })), ((sourceData === null || sourceData === void 0 ? void 0 : sourceData.videoSourceType) && { video: Object.assign({ sourceType: sourceData === null || sourceData === void 0 ? void 0 : sourceData.videoSourceType, sourceKey: sourceData === null || sourceData === void 0 ? void 0 : sourceData.videoSourceKey, sourceUrl: (_j = (_h = sourceData === null || sourceData === void 0 ? void 0 : sourceData.videoSourceLink) === null || _h === void 0 ? void 0 : _h.file) === null || _j === void 0 ? void 0 : _j.url }, (((_k = sourceData === null || sourceData === void 0 ? void 0 : sourceData.featureImages) === null || _k === void 0 ? void 0 : _k[0]) && { poster: (_o = (_m = (_l = sourceData === null || sourceData === void 0 ? void 0 : sourceData.featureImages) === null || _l === void 0 ? void 0 : _l[0]) === null || _m === void 0 ? void 0 : _m.file) === null || _o === void 0 ? void 0 : _o.url })) }));
                    }
                    else if (transformType === 'contentful-page-image') {
                        console.log('SOURCE DATA FOR CONTENTFUL PAGE IMAGE', sourceData);
                        return {
                            image: { source: (_p = sourceData === null || sourceData === void 0 ? void 0 : sourceData.file) === null || _p === void 0 ? void 0 : _p.url }
                        };
                    }
                    else if (transformType === 'contentful-page-metadata') {
                        return {
                            richText: { html: sourceData === null || sourceData === void 0 ? void 0 : sourceData.name },
                            image: { source: (_r = (_q = sourceData === null || sourceData === void 0 ? void 0 : sourceData.leadImage) === null || _q === void 0 ? void 0 : _q.file) === null || _r === void 0 ? void 0 : _r.url }
                        };
                    }
                    else if (transformType === 'contentful-page-cta') {
                        //console.log('SOURCE DATA FOR CONTENTFUL PAGE CTA TRANSFORM', sourceData);
                        return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ link: sourceData === null || sourceData === void 0 ? void 0 : sourceData.link }, ((sourceData === null || sourceData === void 0 ? void 0 : sourceData.featureImage) && { thumbnail: {
                                source: (_t = (_s = sourceData === null || sourceData === void 0 ? void 0 : sourceData.featureImage) === null || _s === void 0 ? void 0 : _s.file) === null || _t === void 0 ? void 0 : _t.url,
                            } })), { headerText: {
                                text: (sourceData === null || sourceData === void 0 ? void 0 : sourceData.title) || '',
                            }, bodyText: {
                                text: (sourceData === null || sourceData === void 0 ? void 0 : sourceData.description) || '',
                            } }), (((_u = sourceData === null || sourceData === void 0 ? void 0 : sourceData.buttons) === null || _u === void 0 ? void 0 : _u[0]) && { ctaButton: Object.assign(Object.assign({}, (((_w = (_v = sourceData === null || sourceData === void 0 ? void 0 : sourceData.buttons) === null || _v === void 0 ? void 0 : _v[0]) === null || _w === void 0 ? void 0 : _w.label) && { text: { text: (_y = (_x = sourceData === null || sourceData === void 0 ? void 0 : sourceData.buttons) === null || _x === void 0 ? void 0 : _x[0]) === null || _y === void 0 ? void 0 : _y.label } })), (((_0 = (_z = sourceData === null || sourceData === void 0 ? void 0 : sourceData.buttons) === null || _z === void 0 ? void 0 : _z[0]) === null || _0 === void 0 ? void 0 : _0.linkUrl) && { link: (_2 = (_1 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.buttons) === null || _1 === void 0 ? void 0 : _1[0]) === null || _2 === void 0 ? void 0 : _2.linkUrl })) })), (!_.isNil(sourceData === null || sourceData === void 0 ? void 0 : sourceData.applyFeatureImageGradient) && { imageGradient: sourceData === null || sourceData === void 0 ? void 0 : sourceData.applyFeatureImageGradient })), (!_.isNil(sourceData === null || sourceData === void 0 ? void 0 : sourceData.reverseTextAndImage) && { reverseOrientation: sourceData === null || sourceData === void 0 ? void 0 : sourceData.reverseTextAndImage }));
                    }
                    else if (transformType === 'contentful-page-accordion') {
                        return {
                            accordionTabs: ((sourceData === null || sourceData === void 0 ? void 0 : sourceData.items) || []).map((i) => {
                                var _a, _b;
                                return ({
                                    heading: { text: i === null || i === void 0 ? void 0 : i.name },
                                    articleBody: convertContentfulRichTextToArticleBody((_a = i === null || i === void 0 ? void 0 : i.content) === null || _a === void 0 ? void 0 : _a.body),
                                    linkUrl: (_b = i === null || i === void 0 ? void 0 : i.content) === null || _b === void 0 ? void 0 : _b.linkUrl,
                                });
                            }),
                        };
                    }
                    else if (transformType === 'contentful-pages-as-buttons') {
                        //console.log('SOURCE DATA FOR CONTENTFUL PAGES AS BUTTONS', sourceData);
                        return {
                            button: {
                                text: { text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.name },
                                link: (sourceData === null || sourceData === void 0 ? void 0 : sourceData.linkUrl) || (sourceData === null || sourceData === void 0 ? void 0 : sourceData.slug),
                            },
                        };
                    }
                    else if (transformType === 'yinzcam-stats-standings') {
                        // console.log('SOURCE DATA FOR YINZCAM STANDINGS TRANSFORM', sourceData);
                        const standingsRoot = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Standings;
                        const tableColumns = [];
                        const statsGroup = ((_4 = (_3 = standingsRoot === null || standingsRoot === void 0 ? void 0 : standingsRoot.Division) === null || _3 === void 0 ? void 0 : _3.StatsGroup) === null || _4 === void 0 ? void 0 : _4._attributes) || {};
                        for (let i = 3; statsGroup["Stat" + i]; i += 1) {
                            tableColumns.push(statsGroup["Stat" + i]);
                        }
                        const standings = (_6 = [].concat((_5 = standingsRoot === null || standingsRoot === void 0 ? void 0 : standingsRoot.Division) === null || _5 === void 0 ? void 0 : _5.Standing)) === null || _6 === void 0 ? void 0 : _6.filter(e => e);
                        const tableData = standings === null || standings === void 0 ? void 0 : standings.map((teamStanding, index) => {
                            var _a, _b, _c, _d, _e;
                            const teamTriCode = (_a = teamStanding._attributes) === null || _a === void 0 ? void 0 : _a.TriCode;
                            const chevronCode = (_b = teamStanding._attributes) === null || _b === void 0 ? void 0 : _b.Change;
                            let chevronStatus = 'NONE';
                            if (chevronCode === 'U') {
                                chevronStatus = 'UP';
                            }
                            else if (chevronCode === 'D') {
                                chevronStatus = 'DOWN';
                            }
                            let q = NaN;
                            try {
                                q = parseInt((_c = teamStanding._attributes) === null || _c === void 0 ? void 0 : _c.Qualification);
                            }
                            catch (e) {
                                console.log('unable to parse qualification from standings response', e);
                            }
                            let championStatus = '';
                            if (isNaN(q)) {
                                if (index < 4) {
                                    championStatus = 'GREEN';
                                }
                                else if (index < 6) {
                                    championStatus = 'YELLOW';
                                }
                                else if (index > 16) {
                                    championStatus = 'RED';
                                }
                            }
                            else {
                                if (q === 1) {
                                    championStatus = 'GREEN';
                                }
                                else if (q === 4) {
                                    championStatus = 'YELLOW';
                                }
                                else if (q === 8) {
                                    championStatus = 'RED';
                                }
                            }
                            let retObj = {
                                position: (_d = teamStanding._attributes) === null || _d === void 0 ? void 0 : _d.LeagueRank,
                                teamLogo: `https://resources-uk.yinzcam.com/soccer/shared/logos/fa_${teamTriCode === null || teamTriCode === void 0 ? void 0 : teamTriCode.toLowerCase()}_light.png`,
                                teamTriCode,
                                teamName: (_e = teamStanding._attributes) === null || _e === void 0 ? void 0 : _e.Team,
                                upDown: chevronStatus,
                                recordType: championStatus,
                            };
                            tableColumns.forEach((e, i) => {
                                var _a, _b;
                                retObj[e] = ((_b = (_a = teamStanding.StatsGroup) === null || _a === void 0 ? void 0 : _a._attributes) === null || _b === void 0 ? void 0 : _b["Stat" + (i + 3)]) || "";
                            });
                            return retObj;
                        });
                        const leagueLogoUrl = ((_9 = (_8 = (_7 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Standings) === null || _7 === void 0 ? void 0 : _7.CompetitionInfo) === null || _8 === void 0 ? void 0 : _8.LogoUrlDark) === null || _9 === void 0 ? void 0 : _9._text) || ((_12 = (_11 = (_10 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Standings) === null || _10 === void 0 ? void 0 : _10.CompetitionInfo) === null || _11 === void 0 ? void 0 : _11.LogoUrlSquare) === null || _12 === void 0 ? void 0 : _12._text);
                        return {
                            imgLeagueLogo: { source: leagueLogoUrl },
                            tableColumns,
                            tableData
                        };
                    }
                    else if (transformType === 'yinzcam-game-list') {
                        // console.log('SOURCE DATA FOR YC GAME LIST TRANSFORM', sourceData);
                        let opponentTeam = CONFIG.tricode === ((_14 = (_13 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _13 === void 0 ? void 0 : _13.TriCode) === null || _14 === void 0 ? void 0 : _14.toLowerCase()) ? sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam : sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam;
                        let matchCentreUrl = (_16 = (_15 = [].concat(sourceData === null || sourceData === void 0 ? void 0 : sourceData.Buttons)) === null || _15 === void 0 ? void 0 : _15.find(button => { var _a; return ((_a = button === null || button === void 0 ? void 0 : button.Title) === null || _a === void 0 ? void 0 : _a.toUpperCase()) === "MATCH CENTRE"; })) === null || _16 === void 0 ? void 0 : _16.WebActionUrl;
                        return {
                            matchStatus: ('C' === (sourceData === null || sourceData === void 0 ? void 0 : sourceData.State)) ? 'live' : ('F' === (sourceData === null || sourceData === void 0 ? void 0 : sourceData.State)) ? 'post' : 'pre',
                            imageHomeTeam: { source: (_17 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _17 === void 0 ? void 0 : _17.ImageUrl },
                            textHomeTeam: { text: (_18 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _18 === void 0 ? void 0 : _18.TriCode },
                            imageAwayTeam: { source: (_19 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _19 === void 0 ? void 0 : _19.ImageUrl },
                            textAwayTeam: { text: (_20 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _20 === void 0 ? void 0 : _20.TriCode },
                            btnCupName: Object.assign(Object.assign({}, (((_21 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Competition) === null || _21 === void 0 ? void 0 : _21.LogoUrl) && { image: { source: (_22 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Competition) === null || _22 === void 0 ? void 0 : _22.LogoUrl } })), (((_23 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Competition) === null || _23 === void 0 ? void 0 : _23.Name) && !((_24 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Competition) === null || _24 === void 0 ? void 0 : _24.LogoUrl) && { text: { text: (_25 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Competition) === null || _25 === void 0 ? void 0 : _25.Name } })),
                            matchDateTime: sourceData === null || sourceData === void 0 ? void 0 : sourceData.Timestamp,
                            textStadiumName: { text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.Venue },
                            btnBroadcasters: ((sourceData === null || sourceData === void 0 ? void 0 : sourceData.BroadcasterUrls) || [])
                                .map((b) => {
                                return Object.assign(Object.assign({}, ((b === null || b === void 0 ? void 0 : b.ImageUrl) && { image: { source: b === null || b === void 0 ? void 0 : b.ImageUrl } })), ((b === null || b === void 0 ? void 0 : b.LinkUrl) && { link: b === null || b === void 0 ? void 0 : b.LinkUrl }));
                            }),
                            btnActions: (_26 = ((sourceData === null || sourceData === void 0 ? void 0 : sourceData.Buttons) || [])) === null || _26 === void 0 ? void 0 : _26.map((b) => {
                                return Object.assign(Object.assign(Object.assign({}, ((b === null || b === void 0 ? void 0 : b.ImageUrl) && { image: { source: b === null || b === void 0 ? void 0 : b.ImageUrl } })), ((b === null || b === void 0 ? void 0 : b.Title) && { text: { text: b === null || b === void 0 ? void 0 : b.Title } })), (((b === null || b === void 0 ? void 0 : b.WebActionUrl) || (b === null || b === void 0 ? void 0 : b.LinkUrl)) && { link: (b === null || b === void 0 ? void 0 : b.WebActionUrl) || (b === null || b === void 0 ? void 0 : b.LinkUrl) }));
                            }),
                            textHomeScore: { text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeScore },
                            textAwayScore: { text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayScore },
                            textMatchMinutes: { text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.Clock },
                            // extras for fixtures card, needs to be aligned with above
                            imgLeaguelogo: { source: (_27 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Competition) === null || _27 === void 0 ? void 0 : _27.LogoUrlSquare },
                            txtStadiumName: { text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.Venue },
                            txtHomeTeam: { text: ((_28 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _28 === void 0 ? void 0 : _28.ShortName) || ((_29 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _29 === void 0 ? void 0 : _29.Name) },
                            txtAwayTeam: { text: ((_30 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _30 === void 0 ? void 0 : _30.ShortName) || ((_31 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _31 === void 0 ? void 0 : _31.Name) },
                            txtHomeTeamAbbr: { text: (_32 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _32 === void 0 ? void 0 : _32.TriCode },
                            txtAwayTeamAbbr: { text: (_33 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _33 === void 0 ? void 0 : _33.TriCode },
                            imgHomeTeam: { source: (_34 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _34 === void 0 ? void 0 : _34.ImageUrl },
                            imgAwayTeam: { source: (_35 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _35 === void 0 ? void 0 : _35.ImageUrl },
                            homeTeamScore: { text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeScore },
                            awayTeamScore: { text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayScore },
                            matchMinutes: { text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.Clock },
                            matchCentreUrl,
                            btnCTA: (_37 = (_36 = ((sourceData === null || sourceData === void 0 ? void 0 : sourceData.Buttons) || [])) === null || _36 === void 0 ? void 0 : _36.filter((b) => { var _a; return !((_a = b === null || b === void 0 ? void 0 : b.WebActionUrl) === null || _a === void 0 ? void 0 : _a.includes('GAME_HIGHLIGHTS')); })) === null || _37 === void 0 ? void 0 : _37.map((b) => {
                                return Object.assign(Object.assign(Object.assign({}, ((b === null || b === void 0 ? void 0 : b.ImageUrl) && { image: { source: b === null || b === void 0 ? void 0 : b.ImageUrl } })), ((b === null || b === void 0 ? void 0 : b.Title) && { text: { text: b === null || b === void 0 ? void 0 : b.Title } })), (((b === null || b === void 0 ? void 0 : b.WebActionUrl) || (b === null || b === void 0 ? void 0 : b.LinkUrl)) && { link: (b === null || b === void 0 ? void 0 : b.WebActionUrl) || (b === null || b === void 0 ? void 0 : b.LinkUrl) }));
                            }),
                            imgOpponentLogo: { source: opponentTeam === null || opponentTeam === void 0 ? void 0 : opponentTeam.ImageUrl },
                            txtOpponentTricode: { text: opponentTeam === null || opponentTeam === void 0 ? void 0 : opponentTeam.TriCode },
                            optionHomeOrAway: CONFIG.tricode === ((_39 = (_38 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _38 === void 0 ? void 0 : _38.TriCode) === null || _39 === void 0 ? void 0 : _39.toLowerCase()) ? "home" : "away"
                        };
                    }
                    else if (transformType === 'yinzcam-stats-player') {
                        //console.log('SOURCE DATA FOR YINZCAM STATS PLAYER', sourceData);
                        // name parsing
                        const shortName = (_41 = (_40 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) === null || _40 === void 0 ? void 0 : _40.ShortName) === null || _41 === void 0 ? void 0 : _41._text;
                        const longName = (_43 = (_42 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) === null || _42 === void 0 ? void 0 : _42.Name) === null || _43 === void 0 ? void 0 : _43._text;
                        let firstName = "";
                        let lastName = "";
                        let personalStatMap = { "Date of Birth": "BirthDate", "Nationality": "BirthPlace" };
                        let btnSocialMedia = (_45 = [].concat((_44 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.SocialButtons) === null || _44 === void 0 ? void 0 : _44.Button)) === null || _45 === void 0 ? void 0 : _45.map((button) => {
                            var _a, _b;
                            return ({
                                image: { source: (_a = button === null || button === void 0 ? void 0 : button.LightImageURL) === null || _a === void 0 ? void 0 : _a._text },
                                link: (_b = button === null || button === void 0 ? void 0 : button.URL) === null || _b === void 0 ? void 0 : _b._text
                            });
                        });
                        const getDateOfBirth = (date) => {
                            var _a, _b;
                            if (!date)
                                return 0;
                            const dateOfBirth = new Date(date);
                            return `${dateOfBirth === null || dateOfBirth === void 0 ? void 0 : dateOfBirth.getDate()} ${monthList[dateOfBirth === null || dateOfBirth === void 0 ? void 0 : dateOfBirth.getMonth()]} ${`'${(_b = (_a = dateOfBirth === null || dateOfBirth === void 0 ? void 0 : dateOfBirth.getFullYear()) === null || _a === void 0 ? void 0 : _a.toString()) === null || _b === void 0 ? void 0 : _b.slice(2, 4)}`}`;
                        };
                        const getHeightInInches = (height) => {
                            if (!height)
                                return 0;
                            const inches = parseInt(height === null || height === void 0 ? void 0 : height.slice(0, 3)) / 2.54;
                            const feet = Math.floor(inches / 12);
                            const remInches = Math.floor(inches % 12);
                            return `${feet}' ${remInches}"`;
                        };
                        const getHomeValue = (statName, playerCard) => {
                            var _a, _b, _c, _d;
                            if (statName === "Height") {
                                return getHeightInInches((_a = playerCard[statName]) === null || _a === void 0 ? void 0 : _a._text);
                            }
                            if (statName === "Date of Birth") {
                                return getDateOfBirth((_b = playerCard[personalStatMap[statName]]) === null || _b === void 0 ? void 0 : _b._text);
                            }
                            return ((_c = playerCard[statName]) === null || _c === void 0 ? void 0 : _c._text) || ((_d = playerCard[personalStatMap[statName]]) === null || _d === void 0 ? void 0 : _d._text) || "0";
                        };
                        let personalStatsListMap = personalStatList === null || personalStatList === void 0 ? void 0 : personalStatList.map(statName => ({
                            name: { text: statName },
                            homeValue: { text: getHomeValue(statName, sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) }
                        }));
                        if (shortName && longName) {
                            const toks = longName.split(shortName, 2);
                            if ((toks === null || toks === void 0 ? void 0 : toks.length) === 2) {
                                firstName = toks[0];
                                lastName = shortName;
                            }
                            else {
                                const toks2 = shortName.split(" ", 2);
                                if ((toks2 === null || toks2 === void 0 ? void 0 : toks2.length) === 2) {
                                    firstName = toks2[0];
                                    lastName = toks2[1];
                                }
                                else {
                                    lastName = shortName;
                                }
                            }
                        }
                        else if (shortName) {
                            const toks2 = shortName.split(" ", 2);
                            if ((toks2 === null || toks2 === void 0 ? void 0 : toks2.length) === 2) {
                                firstName = toks2[0];
                                lastName = toks2[1];
                            }
                            else {
                                lastName = shortName;
                            }
                        }
                        else if (longName) {
                            const toks2 = longName.split(" ", 2);
                            if ((toks2 === null || toks2 === void 0 ? void 0 : toks2.length) === 2) {
                                firstName = toks2[0];
                                lastName = toks2[1];
                            }
                            else {
                                lastName = longName;
                            }
                        }
                        return {
                            txtFirstName: { text: firstName },
                            txtLastName: { text: lastName },
                            txtPlayerName: { text: (_47 = (_46 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) === null || _46 === void 0 ? void 0 : _46.ShortName) === null || _47 === void 0 ? void 0 : _47._text },
                            txtPlayerFullName: { text: (_49 = (_48 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) === null || _48 === void 0 ? void 0 : _48.Name) === null || _49 === void 0 ? void 0 : _49._text },
                            txtDOB: { text: (_51 = (_50 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) === null || _50 === void 0 ? void 0 : _50.BirthDate) === null || _51 === void 0 ? void 0 : _51._text },
                            txtPOB: { text: (_53 = (_52 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) === null || _52 === void 0 ? void 0 : _52.BirthPlace) === null || _53 === void 0 ? void 0 : _53._text },
                            txtHeight: { text: (_55 = (_54 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) === null || _54 === void 0 ? void 0 : _54.Height) === null || _55 === void 0 ? void 0 : _55._text },
                            txtWeight: { text: (_57 = (_56 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) === null || _56 === void 0 ? void 0 : _56.Weight) === null || _57 === void 0 ? void 0 : _57._text },
                            imgPlayerImage: { source: ((_59 = (_58 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) === null || _58 === void 0 ? void 0 : _58.HeadlineImageUrl) === null || _59 === void 0 ? void 0 : _59._text) || ((_61 = (_60 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) === null || _60 === void 0 ? void 0 : _60.ImageUrl) === null || _61 === void 0 ? void 0 : _61._text) },
                            txtPlayerNumber: { text: (_63 = (_62 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) === null || _62 === void 0 ? void 0 : _62.Number) === null || _63 === void 0 ? void 0 : _63._text },
                            txtPlayerPosition: { text: (_65 = (_64 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) === null || _64 === void 0 ? void 0 : _64.Position) === null || _65 === void 0 ? void 0 : _65._text },
                            playerStats: ((_66 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Stats) === null || _66 === void 0 ? void 0 : _66.Stat).map(stat => {
                                var _a, _b, _c, _d, _e, _f, _g;
                                return Object.assign(Object.assign(Object.assign({}, (((_a = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _a === void 0 ? void 0 : _a.Name) && { name: { text: (_b = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _b === void 0 ? void 0 : _b.Name } })), (((_c = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _c === void 0 ? void 0 : _c.HomeValue) && { homeValue: { text: (_d = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _d === void 0 ? void 0 : _d.HomeValue } })), (((_e = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _e === void 0 ? void 0 : _e.Name) && { highlightColor: ((_f = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _f === void 0 ? void 0 : _f.Name.toLowerCase()) === 'yellow cards' ? "#F6BE00" : ((_g = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _g === void 0 ? void 0 : _g.Name.toLowerCase()) === 'red cards' ? "#D20028" : "" }));
                            }),
                            personalStats: personalStatsListMap,
                            txtSummary: { html: (_68 = (_67 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) === null || _67 === void 0 ? void 0 : _67.HtmlBio) === null || _68 === void 0 ? void 0 : _68._text },
                            image: { source: (_70 = (_69 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) === null || _69 === void 0 ? void 0 : _69.ImageUrl) === null || _70 === void 0 ? void 0 : _70._text },
                            btnSocialMedia
                        };
                    }
                    else if (transformType === 'yinzcam-player-action-image') {
                        return {
                            image: { source: ((_72 = Object.keys((_71 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) === null || _71 === void 0 ? void 0 : _71.ActionImageUrl)) === null || _72 === void 0 ? void 0 : _72.length) > 0 ? (_74 = (_73 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) === null || _73 === void 0 ? void 0 : _73.ActionImageUrl) === null || _74 === void 0 ? void 0 : _74._text : "https://resources-uk.yinzcam.com/soccer/shared/player-action-silhouette.png" }
                        };
                    }
                    else if (transformType === 'yinzcam-game-scores') {
                        // console.log('SOURCE DATA FOR YINZCAM GAME SCORES', sourceData);
                        let opponentTeam = CONFIG.tricode === ((_77 = (_76 = (_75 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _75 === void 0 ? void 0 : _75._attributes) === null || _76 === void 0 ? void 0 : _76.TriCode) === null || _77 === void 0 ? void 0 : _77.toLowerCase()) ? sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam : sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam;
                        let matchCentreUrl = (_81 = (_80 = (_79 = (_78 = [].concat(sourceData === null || sourceData === void 0 ? void 0 : sourceData.Buttons)) === null || _78 === void 0 ? void 0 : _78.find(button => { var _a, _b, _c; return ["MATCH CENTRE", "LIVE", "MATCH PREVIEW"].includes((_c = (_b = (_a = button === null || button === void 0 ? void 0 : button.Button) === null || _a === void 0 ? void 0 : _a.Title) === null || _b === void 0 ? void 0 : _b._text) === null || _c === void 0 ? void 0 : _c.toUpperCase()); })) === null || _79 === void 0 ? void 0 : _79.Button) === null || _80 === void 0 ? void 0 : _80.WebActionURL) === null || _81 === void 0 ? void 0 : _81._text;
                        // console.log({opponentTeam, tricode : CONFIG.tricode, condn: CONFIG.tricode === sourceData?.HomeTeam?.TriCode?.toLowerCase()});
                        return {
                            matchStatus: ('C' === ((_82 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.State) === null || _82 === void 0 ? void 0 : _82._text)) ? 'live' : ('F' === ((_83 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.State) === null || _83 === void 0 ? void 0 : _83._text)) ? 'post' : 'pre',
                            imageHomeTeam: { source: (_85 = (_84 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _84 === void 0 ? void 0 : _84.ImageUrl) === null || _85 === void 0 ? void 0 : _85._text },
                            textHomeTeam: { text: (_87 = (_86 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _86 === void 0 ? void 0 : _86._attributes) === null || _87 === void 0 ? void 0 : _87.TriCode },
                            imageAwayTeam: { source: (_89 = (_88 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _88 === void 0 ? void 0 : _88.ImageUrl) === null || _89 === void 0 ? void 0 : _89._text },
                            textAwayTeam: { text: (_91 = (_90 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _90 === void 0 ? void 0 : _90._attributes) === null || _91 === void 0 ? void 0 : _91.TriCode },
                            btnCupName: Object.assign(Object.assign({}, (((_92 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Competition) === null || _92 === void 0 ? void 0 : _92.LogoUrl) && { image: { source: (_94 = (_93 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Competition) === null || _93 === void 0 ? void 0 : _93.LogoUrl) === null || _94 === void 0 ? void 0 : _94._text } })), (((_96 = (_95 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Competition) === null || _95 === void 0 ? void 0 : _95._attributes) === null || _96 === void 0 ? void 0 : _96.Name) && !((_97 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Competition) === null || _97 === void 0 ? void 0 : _97.LogoUrl) && { text: { text: (_99 = (_98 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Competition) === null || _98 === void 0 ? void 0 : _98._attributes) === null || _99 === void 0 ? void 0 : _99.Name } })),
                            matchDateTime: (_100 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Timestamp) === null || _100 === void 0 ? void 0 : _100._text,
                            textStadiumName: { text: (_101 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Venue) === null || _101 === void 0 ? void 0 : _101._text },
                            btnBroadcasters: (_103 = [].concat((_102 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.BroadcasterUrls) === null || _102 === void 0 ? void 0 : _102.Url)) === null || _103 === void 0 ? void 0 : _103.map((b) => {
                                var _a, _b, _c;
                                return Object.assign(Object.assign({}, (((_a = b === null || b === void 0 ? void 0 : b.ImageUrl) === null || _a === void 0 ? void 0 : _a._text) && { image: { source: b === null || b === void 0 ? void 0 : b.ImageUrl._text } })), (((_b = b === null || b === void 0 ? void 0 : b._attributes) === null || _b === void 0 ? void 0 : _b.ClickthroughUrl) && { link: (_c = b === null || b === void 0 ? void 0 : b._attributes) === null || _c === void 0 ? void 0 : _c.ClickthroughUrl }));
                            }),
                            btnActions: (_106 = (_105 = ([].concat((_104 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Buttons) === null || _104 === void 0 ? void 0 : _104.Button) || [])) === null || _105 === void 0 ? void 0 : _105.filter((b) => { var _a, _b; return !((_b = (_a = b === null || b === void 0 ? void 0 : b.WebActionURL) === null || _a === void 0 ? void 0 : _a._text) === null || _b === void 0 ? void 0 : _b.includes('GAME_HIGHLIGHTS')); })) === null || _106 === void 0 ? void 0 : _106.map((b) => {
                                var _a, _b, _c, _d, _e, _f;
                                return Object.assign(Object.assign(Object.assign({}, ((b === null || b === void 0 ? void 0 : b.ImageUrl) && { image: { source: (_a = b === null || b === void 0 ? void 0 : b.ImageUrl) === null || _a === void 0 ? void 0 : _a._text } })), ((b === null || b === void 0 ? void 0 : b.Title) && { text: { text: (_b = b === null || b === void 0 ? void 0 : b.Title) === null || _b === void 0 ? void 0 : _b._text } })), ((((_c = b === null || b === void 0 ? void 0 : b.WebActionURL) === null || _c === void 0 ? void 0 : _c._text) || ((_d = b === null || b === void 0 ? void 0 : b.URL) === null || _d === void 0 ? void 0 : _d._text)) && { link: ((_e = b === null || b === void 0 ? void 0 : b.WebActionURL) === null || _e === void 0 ? void 0 : _e._text) || ((_f = b === null || b === void 0 ? void 0 : b.URL) === null || _f === void 0 ? void 0 : _f._text) }));
                            }),
                            textHomeScore: { text: (_108 = (_107 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _107 === void 0 ? void 0 : _107._attributes) === null || _108 === void 0 ? void 0 : _108.Score },
                            textAwayScore: { text: (_110 = (_109 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _109 === void 0 ? void 0 : _109._attributes) === null || _110 === void 0 ? void 0 : _110.Score },
                            textMatchMinutes: { text: (_111 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Clock) === null || _111 === void 0 ? void 0 : _111._text },
                            // extras for fixtures card, needs to be aligned with above
                            imgLeaguelogo: { source: (_113 = (_112 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Competition) === null || _112 === void 0 ? void 0 : _112.LogoUrlSquare) === null || _113 === void 0 ? void 0 : _113._text },
                            txtStadiumName: { text: (_114 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Venue) === null || _114 === void 0 ? void 0 : _114._text },
                            txtHomeTeam: { text: ((_116 = (_115 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _115 === void 0 ? void 0 : _115._attributes) === null || _116 === void 0 ? void 0 : _116.ShortName) || ((_118 = (_117 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _117 === void 0 ? void 0 : _117._attributes) === null || _118 === void 0 ? void 0 : _118.Name) },
                            txtAwayTeam: { text: ((_120 = (_119 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _119 === void 0 ? void 0 : _119._attributes) === null || _120 === void 0 ? void 0 : _120.ShortName) || ((_122 = (_121 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _121 === void 0 ? void 0 : _121._attributes) === null || _122 === void 0 ? void 0 : _122.Name) },
                            txtHomeTeamAbbr: { text: (_124 = (_123 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _123 === void 0 ? void 0 : _123._attributes) === null || _124 === void 0 ? void 0 : _124.TriCode },
                            txtAwayTeamAbbr: { text: (_126 = (_125 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _125 === void 0 ? void 0 : _125._attributes) === null || _126 === void 0 ? void 0 : _126.TriCode },
                            imgHomeTeam: { source: (_128 = (_127 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _127 === void 0 ? void 0 : _127.ImageUrl) === null || _128 === void 0 ? void 0 : _128._text },
                            imgAwayTeam: { source: (_130 = (_129 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _129 === void 0 ? void 0 : _129.ImageUrl) === null || _130 === void 0 ? void 0 : _130._text },
                            homeTeamDarkLogoUrl: (_132 = (_131 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _131 === void 0 ? void 0 : _131.ImageUrlDark) === null || _132 === void 0 ? void 0 : _132._text,
                            awayTeamDarkLogoUrl: (_134 = (_133 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _133 === void 0 ? void 0 : _133.ImageUrlDark) === null || _134 === void 0 ? void 0 : _134._text,
                            homeTeamScore: { text: (_136 = (_135 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _135 === void 0 ? void 0 : _135._attributes) === null || _136 === void 0 ? void 0 : _136.Score },
                            awayTeamScore: { text: (_138 = (_137 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _137 === void 0 ? void 0 : _137._attributes) === null || _138 === void 0 ? void 0 : _138.Score },
                            matchMinutes: { text: (_139 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Clock) === null || _139 === void 0 ? void 0 : _139._text },
                            btnCTA: (_142 = (_141 = ([].concat((_140 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Buttons) === null || _140 === void 0 ? void 0 : _140.Button) || [])) === null || _141 === void 0 ? void 0 : _141.filter((b) => { var _a, _b; return !((_b = (_a = b === null || b === void 0 ? void 0 : b.WebActionURL) === null || _a === void 0 ? void 0 : _a._text) === null || _b === void 0 ? void 0 : _b.includes('GAME_HIGHLIGHTS')); })) === null || _142 === void 0 ? void 0 : _142.map((b) => {
                                var _a, _b, _c, _d, _e, _f;
                                return Object.assign(Object.assign(Object.assign({}, ((b === null || b === void 0 ? void 0 : b.ImageURL) && { image: { source: (_a = b === null || b === void 0 ? void 0 : b.ImageURL) === null || _a === void 0 ? void 0 : _a._text } })), ((b === null || b === void 0 ? void 0 : b.Title) && { text: { text: (_b = b === null || b === void 0 ? void 0 : b.Title) === null || _b === void 0 ? void 0 : _b._text } })), ((((_c = b === null || b === void 0 ? void 0 : b.WebActionURL) === null || _c === void 0 ? void 0 : _c._text) || ((_d = b === null || b === void 0 ? void 0 : b.URL) === null || _d === void 0 ? void 0 : _d._text)) && { link: ((_e = b === null || b === void 0 ? void 0 : b.WebActionURL) === null || _e === void 0 ? void 0 : _e._text) || ((_f = b === null || b === void 0 ? void 0 : b.URL) === null || _f === void 0 ? void 0 : _f._text) }));
                            }),
                            imgOpponentLogo: { source: (_143 = opponentTeam === null || opponentTeam === void 0 ? void 0 : opponentTeam.ImageUrl) === null || _143 === void 0 ? void 0 : _143._text },
                            txtOpponentTricode: { text: (_144 = opponentTeam === null || opponentTeam === void 0 ? void 0 : opponentTeam._attributes) === null || _144 === void 0 ? void 0 : _144.TriCode },
                            optionHomeOrAway: CONFIG.tricode.toLowerCase() === ((_147 = (_146 = (_145 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _145 === void 0 ? void 0 : _145._attributes) === null || _146 === void 0 ? void 0 : _146.TriCode) === null || _147 === void 0 ? void 0 : _147.toLowerCase()) ? "home" : "away",
                            matchCentreUrl
                        };
                    }
                    else if (transformType === 'contentful-media') {
                        //console.log('SOURCE DATA FOR CONTENTFUL MEDIA TRANSFORM', sourceData);
                        return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ slug: sourceData === null || sourceData === void 0 ? void 0 : sourceData.slug, publishDateTime: sourceData === null || sourceData === void 0 ? void 0 : sourceData.publishDateTime }, ((sourceData === null || sourceData === void 0 ? void 0 : sourceData.readingTime) > 0 && { viewTimeDuration: `PT${Math.round(sourceData === null || sourceData === void 0 ? void 0 : sourceData.readingTime)}M` })), (((_148 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.featureImages) === null || _148 === void 0 ? void 0 : _148[0]) && { thumbnail: {
                                source: (_151 = (_150 = (_149 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.featureImages) === null || _149 === void 0 ? void 0 : _149[0]) === null || _150 === void 0 ? void 0 : _150.file) === null || _151 === void 0 ? void 0 : _151.url,
                            } })), (((_153 = (_152 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.featureImages) === null || _152 === void 0 ? void 0 : _152[0]) === null || _153 === void 0 ? void 0 : _153.description) && { caption: {
                                captionText: {
                                    text: (_155 = (_154 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.featureImages) === null || _154 === void 0 ? void 0 : _154[0]) === null || _155 === void 0 ? void 0 : _155.description,
                                }
                            } })), { categories: ((sourceData === null || sourceData === void 0 ? void 0 : sourceData.categories) || []).map((c) => ({
                                text: { text: c.name },
                            })), titleText: {
                                text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.name,
                            }, descriptionText: {
                                text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.description,
                            } }), (((_156 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _156 === void 0 ? void 0 : _156[0]) && { author: {
                                image: {
                                    source: (_160 = (_159 = (_158 = (_157 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _157 === void 0 ? void 0 : _157[0]) === null || _158 === void 0 ? void 0 : _158.primaryImage) === null || _159 === void 0 ? void 0 : _159.file) === null || _160 === void 0 ? void 0 : _160.url,
                                },
                                nameText: {
                                    text: (_161 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _161 === void 0 ? void 0 : _161[0].displayName,
                                },
                                positionText: {
                                    text: (_162 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _162 === void 0 ? void 0 : _162[0].description,
                                },
                            } })), ((sourceData === null || sourceData === void 0 ? void 0 : sourceData.videoSourceType) && { video: Object.assign({ sourceType: sourceData === null || sourceData === void 0 ? void 0 : sourceData.videoSourceType, sourceKey: sourceData === null || sourceData === void 0 ? void 0 : sourceData.videoSourceKey, sourceUrl: (_164 = (_163 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.videoSourceLink) === null || _163 === void 0 ? void 0 : _163.file) === null || _164 === void 0 ? void 0 : _164.url }, (((_165 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.featureImages) === null || _165 === void 0 ? void 0 : _165[0]) && { poster: (_168 = (_167 = (_166 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.featureImages) === null || _166 === void 0 ? void 0 : _166[0]) === null || _167 === void 0 ? void 0 : _167.file) === null || _168 === void 0 ? void 0 : _168.url })) })), ((sourceData === null || sourceData === void 0 ? void 0 : sourceData.mediaType) && { mediaType: sourceData === null || sourceData === void 0 ? void 0 : sourceData.mediaType.toLowerCase() }));
                    }
                    else if (transformType === 'contentful-article-header' || transformType === 'contentful-media-header') {
                        //console.log('SOURCE DATA FOR CONTENTFUL ARTICLE HEADER TRANSFORM', sourceData);
                        return Object.assign(Object.assign(Object.assign(Object.assign({ slug: sourceData === null || sourceData === void 0 ? void 0 : sourceData.slug, publishDateTime: sourceData === null || sourceData === void 0 ? void 0 : sourceData.publishDateTime }, ((sourceData === null || sourceData === void 0 ? void 0 : sourceData.duration) > 0 && { viewTimeDuration: `PT${Math.round((sourceData === null || sourceData === void 0 ? void 0 : sourceData.duration) * 60)}S` })), { titleText: {
                                text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.name,
                            }, descriptionText: {
                                text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.description,
                            } }), (((_169 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _169 === void 0 ? void 0 : _169[0]) && { author: {
                                image: {
                                    source: (_172 = (_171 = (_170 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _170 === void 0 ? void 0 : _170[0].primaryImage) === null || _171 === void 0 ? void 0 : _171.file) === null || _172 === void 0 ? void 0 : _172.url,
                                },
                                nameText: {
                                    text: (_173 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _173 === void 0 ? void 0 : _173[0].displayName,
                                },
                                positionText: {
                                    text: (_174 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _174 === void 0 ? void 0 : _174[0].description,
                                },
                            } })), { showUserIcon: !!((_178 = (_177 = (_176 = (_175 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _175 === void 0 ? void 0 : _175[0]) === null || _176 === void 0 ? void 0 : _176.primaryImage) === null || _177 === void 0 ? void 0 : _177.file) === null || _178 === void 0 ? void 0 : _178.url), showAuthor: !!((_180 = (_179 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _179 === void 0 ? void 0 : _179[0]) === null || _180 === void 0 ? void 0 : _180.displayName) });
                    }
                    else if (transformType === 'contentful-article-body' || transformType === 'contentful-page-article') {
                        //console.log('SOURCE DATA FOR CONTENTFUL ARTICLE BODY TRANSFORM', sourceData);
                        ret = _.cloneDeep(sourceData);
                        ret.body = convertContentfulRichTextToArticleBody(((_181 = ret.articleBody) === null || _181 === void 0 ? void 0 : _181.body) || ret.body);
                    }
                    else if (transformType === 'contentful-gallery-viewer') {
                        return Object.assign(Object.assign({ slug: sourceData === null || sourceData === void 0 ? void 0 : sourceData.slug }, (((_182 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _182 === void 0 ? void 0 : _182[0]) && { photoAuthor: {
                                image: {
                                    source: (_185 = (_184 = (_183 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _183 === void 0 ? void 0 : _183[0].primaryImage) === null || _184 === void 0 ? void 0 : _184.file) === null || _185 === void 0 ? void 0 : _185.url,
                                },
                                nameText: {
                                    text: (_186 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _186 === void 0 ? void 0 : _186[0].displayName,
                                },
                                positionText: {
                                    text: (_187 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _187 === void 0 ? void 0 : _187[0].description,
                                },
                            } })), (((_188 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.photos) === null || _188 === void 0 ? void 0 : _188[0]) && { photos: (_189 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.photos) === null || _189 === void 0 ? void 0 : _189.map((item) => {
                                var _a;
                                return {
                                    source: (_a = item === null || item === void 0 ? void 0 : item.file) === null || _a === void 0 ? void 0 : _a.url,
                                    title: item === null || item === void 0 ? void 0 : item.title,
                                    description: item === null || item === void 0 ? void 0 : item.description,
                                };
                            }) }));
                    }
                    else if (transformType === 'contentful-gallery-photo') {
                        return {
                            image: {
                                source: (_190 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.file) === null || _190 === void 0 ? void 0 : _190.url,
                            }
                        };
                    }
                    else if (transformType === 'contentful-site-navigation') {
                        return {
                            slug: sourceData === null || sourceData === void 0 ? void 0 : sourceData.slug,
                            headingText: { text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.title },
                            pages: ((_191 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.pages) === null || _191 === void 0 ? void 0 : _191.map(page => {
                                return Object.assign(Object.assign({}, ((page === null || page === void 0 ? void 0 : page.title) && { pageName: { text: page === null || page === void 0 ? void 0 : page.title } })), ((page === null || page === void 0 ? void 0 : page.linkUrl) && { linkUrl: page === null || page === void 0 ? void 0 : page.linkUrl }));
                            }))
                        };
                    }
                    else if (transformType === 'yinzcam-team-roster') {
                        //console.log('YINZCAM TEAM ROSTER TRANSFORM', sourceData);
                        if (!sourceData) {
                            return;
                        }
                        return {
                            imgPlayerImage: { source: ((_192 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.CardHeadshotImageUrl) === null || _192 === void 0 ? void 0 : _192._text) || ((_193 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.ImageUrl) === null || _193 === void 0 ? void 0 : _193._text) },
                            txtFirstName: { text: (_195 = (_194 = sourceData === null || sourceData === void 0 ? void 0 : sourceData._attributes) === null || _194 === void 0 ? void 0 : _194.Name) === null || _195 === void 0 ? void 0 : _195.split(" ")[0] },
                            txtLastName: { text: (_197 = (_196 = sourceData === null || sourceData === void 0 ? void 0 : sourceData._attributes) === null || _196 === void 0 ? void 0 : _196.Name) === null || _197 === void 0 ? void 0 : _197.split(" ")[1] },
                            txtPlayerNumber: { text: (_198 = sourceData === null || sourceData === void 0 ? void 0 : sourceData._attributes) === null || _198 === void 0 ? void 0 : _198.Number },
                            txtPlayerPosition: { text: (_199 = sourceData === null || sourceData === void 0 ? void 0 : sourceData._attributes) === null || _199 === void 0 ? void 0 : _199.PositionFull },
                            playerStats: (_201 = ((_200 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Stats) === null || _200 === void 0 ? void 0 : _200.Stat)) === null || _201 === void 0 ? void 0 : _201.map(stat => {
                                var _a, _b, _c, _d, _e, _f, _g;
                                return Object.assign(Object.assign(Object.assign({}, (((_a = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _a === void 0 ? void 0 : _a.Name) && { name: { text: (_b = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _b === void 0 ? void 0 : _b.Name } })), (((_c = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _c === void 0 ? void 0 : _c.HomeValue) && { value: { text: (_d = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _d === void 0 ? void 0 : _d.HomeValue } })), (((_e = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _e === void 0 ? void 0 : _e.Name) && { highlightColor: ((_f = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _f === void 0 ? void 0 : _f.Name.toLowerCase()) === 'yellow cards' ? "#F6BE00" : ((_g = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _g === void 0 ? void 0 : _g.Name.toLowerCase()) === 'red cards' ? "#D20028" : "" }));
                            }),
                            btnPlayerBio: { link: (_203 = (_202 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.CardData) === null || _202 === void 0 ? void 0 : _202.WebActionURL) === null || _203 === void 0 ? void 0 : _203._text },
                        };
                    }
                    else if (transformType === 'yinzcam-game-box') {
                        // console.log("Game Box data => ", sourceData);
                        const extractStatObject = (statsArray) => {
                            let newStatsArray = [];
                            statsArray === null || statsArray === void 0 ? void 0 : statsArray.map(stat => {
                                var _a, _b, _c, _d;
                                newStatsArray === null || newStatsArray === void 0 ? void 0 : newStatsArray.push({
                                    statName: (_a = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _a === void 0 ? void 0 : _a.Name,
                                    homeValue: parseInt((_b = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _b === void 0 ? void 0 : _b.HomeValue),
                                    awayValue: parseInt((_c = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _c === void 0 ? void 0 : _c.AwayValue),
                                    maxValue: parseInt((_d = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _d === void 0 ? void 0 : _d.Max)
                                });
                            });
                            return newStatsArray;
                        };
                        if (!sourceData)
                            return;
                        let scoringPlay = Array.isArray((_205 = (_204 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Scoring) === null || _204 === void 0 ? void 0 : _204.ScoringPeriod) === null || _205 === void 0 ? void 0 : _205.ScoringPlay) ? (_207 = (_206 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Scoring) === null || _206 === void 0 ? void 0 : _206.ScoringPeriod) === null || _207 === void 0 ? void 0 : _207.ScoringPlay : [(_209 = (_208 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Scoring) === null || _208 === void 0 ? void 0 : _208.ScoringPeriod) === null || _209 === void 0 ? void 0 : _209.ScoringPlay];
                        let homePlayers = scoringPlay === null || scoringPlay === void 0 ? void 0 : scoringPlay.filter(play => { var _a; return ((_a = play === null || play === void 0 ? void 0 : play._attributes) === null || _a === void 0 ? void 0 : _a.IsHome) === "true"; });
                        let awayPlayers = scoringPlay === null || scoringPlay === void 0 ? void 0 : scoringPlay.filter(play => { var _a; return ((_a = play === null || play === void 0 ? void 0 : play._attributes) === null || _a === void 0 ? void 0 : _a.IsHome) === "false"; });
                        let matchStats = [...extractStatObject((_210 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.OffenseStats) === null || _210 === void 0 ? void 0 : _210.Stat),
                            ...extractStatObject((_211 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.DefenseStats) === null || _211 === void 0 ? void 0 : _211.Stat),
                            ...extractStatObject((_212 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Stats) === null || _212 === void 0 ? void 0 : _212.Stat)];
                        let leaderStatList = (sourceData === null || sourceData === void 0 ? void 0 : sourceData.Leaders) && ((_213 = Object.entries(sourceData === null || sourceData === void 0 ? void 0 : sourceData.Leaders)) === null || _213 === void 0 ? void 0 : _213.map((leader) => {
                            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12;
                            if ((_a = leader[0]) === null || _a === void 0 ? void 0 : _a.includes("Category")) {
                                return {
                                    categoryName: (_c = (_b = leader[1]) === null || _b === void 0 ? void 0 : _b._attributes) === null || _c === void 0 ? void 0 : _c.Title,
                                    away: {
                                        name: (_f = (_e = (_d = leader[1]) === null || _d === void 0 ? void 0 : _d.Player0) === null || _e === void 0 ? void 0 : _e._attributes) === null || _f === void 0 ? void 0 : _f.Name,
                                        number: (_j = (_h = (_g = leader[1]) === null || _g === void 0 ? void 0 : _g.Player0) === null || _h === void 0 ? void 0 : _h._attributes) === null || _j === void 0 ? void 0 : _j.Number,
                                        imageURL: (_m = (_l = (_k = leader[1]) === null || _k === void 0 ? void 0 : _k.Player0) === null || _l === void 0 ? void 0 : _l.ImageUrl) === null || _m === void 0 ? void 0 : _m._text,
                                        position: (_q = (_p = (_o = leader[1]) === null || _o === void 0 ? void 0 : _o.Player0) === null || _p === void 0 ? void 0 : _p._attributes) === null || _q === void 0 ? void 0 : _q.Position,
                                        value: (_t = (_s = (_r = leader[1]) === null || _r === void 0 ? void 0 : _r.Player0) === null || _s === void 0 ? void 0 : _s._attributes) === null || _t === void 0 ? void 0 : _t.Value,
                                        teamLogo: (_v = (_u = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _u === void 0 ? void 0 : _u.ImageUrl) === null || _v === void 0 ? void 0 : _v._text
                                    },
                                    home: {
                                        name: (_y = (_x = (_w = leader[1]) === null || _w === void 0 ? void 0 : _w.Player1) === null || _x === void 0 ? void 0 : _x._attributes) === null || _y === void 0 ? void 0 : _y.Name,
                                        number: (_1 = (_0 = (_z = leader[1]) === null || _z === void 0 ? void 0 : _z.Player1) === null || _0 === void 0 ? void 0 : _0._attributes) === null || _1 === void 0 ? void 0 : _1.Number,
                                        imageURL: (_4 = (_3 = (_2 = leader[1]) === null || _2 === void 0 ? void 0 : _2.Player1) === null || _3 === void 0 ? void 0 : _3.ImageUrl) === null || _4 === void 0 ? void 0 : _4._text,
                                        position: (_7 = (_6 = (_5 = leader[1]) === null || _5 === void 0 ? void 0 : _5.Player1) === null || _6 === void 0 ? void 0 : _6._attributes) === null || _7 === void 0 ? void 0 : _7.Position,
                                        value: (_10 = (_9 = (_8 = leader[1]) === null || _8 === void 0 ? void 0 : _8.Player1) === null || _9 === void 0 ? void 0 : _9._attributes) === null || _10 === void 0 ? void 0 : _10.Value,
                                        teamLogo: (_12 = (_11 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _11 === void 0 ? void 0 : _11.ImageUrl) === null || _12 === void 0 ? void 0 : _12._text
                                    }
                                };
                            }
                        }).slice(1));
                        let referee = ((_215 = (_214 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Referees) === null || _214 === void 0 ? void 0 : _214.Referee) === null || _215 === void 0 ? void 0 : _215.length) > 0 ? (_219 = (_218 = (_217 = (_216 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Referees) === null || _216 === void 0 ? void 0 : _216.Referee) === null || _217 === void 0 ? void 0 : _217.find(ref => { var _a; return ((_a = ref === null || ref === void 0 ? void 0 : ref._attributes) === null || _a === void 0 ? void 0 : _a.Position) === "Central Referee"; })) === null || _218 === void 0 ? void 0 : _218._attributes) === null || _219 === void 0 ? void 0 : _219.Name : (_222 = (_221 = (_220 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Referees) === null || _220 === void 0 ? void 0 : _220.Referee) === null || _221 === void 0 ? void 0 : _221._attributes) === null || _222 === void 0 ? void 0 : _222.Name;
                        let venueAddress = (_224 = (_223 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.VenueInfo) === null || _223 === void 0 ? void 0 : _223.Address) === null || _224 === void 0 ? void 0 : _224._text;
                        return {
                            homePlayerGoals: homePlayers === null || homePlayers === void 0 ? void 0 : homePlayers.map(player => {
                                var _a, _b, _c, _d;
                                return Object.assign(Object.assign({}, (((_a = player === null || player === void 0 ? void 0 : player.Description) === null || _a === void 0 ? void 0 : _a._text) && { name: { text: (_b = player === null || player === void 0 ? void 0 : player.Description) === null || _b === void 0 ? void 0 : _b._text } })), (((_c = player === null || player === void 0 ? void 0 : player._attributes) === null || _c === void 0 ? void 0 : _c.Time) && { goalMinute: { text: (_d = player === null || player === void 0 ? void 0 : player._attributes) === null || _d === void 0 ? void 0 : _d.Time } }));
                            }),
                            awayPlayerGoals: awayPlayers === null || awayPlayers === void 0 ? void 0 : awayPlayers.map(player => {
                                var _a, _b, _c, _d;
                                return Object.assign(Object.assign({}, (((_a = player === null || player === void 0 ? void 0 : player.Description) === null || _a === void 0 ? void 0 : _a._text) && { name: { text: (_b = player === null || player === void 0 ? void 0 : player.Description) === null || _b === void 0 ? void 0 : _b._text } })), (((_c = player === null || player === void 0 ? void 0 : player._attributes) === null || _c === void 0 ? void 0 : _c.Time) && { goalMinute: { text: (_d = player === null || player === void 0 ? void 0 : player._attributes) === null || _d === void 0 ? void 0 : _d.Time } }));
                            }),
                            homeTeamDetails: {
                                icon: { source: (_226 = (_225 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _225 === void 0 ? void 0 : _225.ImageUrl) === null || _226 === void 0 ? void 0 : _226._text },
                                name: { text: (_228 = (_227 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _227 === void 0 ? void 0 : _227._attributes) === null || _228 === void 0 ? void 0 : _228.FullName },
                                tricode: (_230 = (_229 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _229 === void 0 ? void 0 : _229._attributes) === null || _230 === void 0 ? void 0 : _230.TriCode,
                            },
                            awayTeamDetails: {
                                icon: { source: (_232 = (_231 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _231 === void 0 ? void 0 : _231.ImageUrl) === null || _232 === void 0 ? void 0 : _232._text },
                                name: { text: (_234 = (_233 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _233 === void 0 ? void 0 : _233._attributes) === null || _234 === void 0 ? void 0 : _234.FullName },
                                tricode: (_236 = (_235 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _235 === void 0 ? void 0 : _235._attributes) === null || _236 === void 0 ? void 0 : _236.TriCode,
                            },
                            imgLeagueIcon: { source: (_238 = (_237 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Competition) === null || _237 === void 0 ? void 0 : _237.LogoUrl) === null || _238 === void 0 ? void 0 : _238._text },
                            matchDateTime: (_239 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Timestamp) === null || _239 === void 0 ? void 0 : _239._text,
                            matchStatus: ('C' === ((_240 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.State) === null || _240 === void 0 ? void 0 : _240._text)) ? 'live' : ('F' === ((_241 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.State) === null || _241 === void 0 ? void 0 : _241._text)) ? 'post' : 'pre',
                            txtHomeScore: { text: (_243 = (_242 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Score) === null || _242 === void 0 ? void 0 : _242._attributes) === null || _243 === void 0 ? void 0 : _243.Home },
                            txtAwayScore: { text: (_245 = (_244 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Score) === null || _244 === void 0 ? void 0 : _244._attributes) === null || _245 === void 0 ? void 0 : _245.Away },
                            txtVenueName: { text: (_246 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Venue) === null || _246 === void 0 ? void 0 : _246._text },
                            txtMinute: { text: (_247 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Clock) === null || _247 === void 0 ? void 0 : _247._text },
                            imgAwayLogo: { source: (_249 = (_248 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _248 === void 0 ? void 0 : _248.ImageUrl) === null || _249 === void 0 ? void 0 : _249._text },
                            imgHomeLogo: { source: (_251 = (_250 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _250 === void 0 ? void 0 : _250.ImageUrl) === null || _251 === void 0 ? void 0 : _251._text },
                            matchStats,
                            leaderStatList,
                            txtRefereeName: { text: referee },
                            venueAddress,
                            imgHomeTeamLogo: { source: (_253 = (_252 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _252 === void 0 ? void 0 : _252.ImageUrl) === null || _253 === void 0 ? void 0 : _253._text },
                            imgAwayTeamLogo: { source: (_255 = (_254 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _254 === void 0 ? void 0 : _254.ImageUrl) === null || _255 === void 0 ? void 0 : _255._text },
                            homeLogoLightUrl: (_257 = (_256 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _256 === void 0 ? void 0 : _256.ImageUrlDark) === null || _257 === void 0 ? void 0 : _257._text,
                            awayLogoLightUrl: (_259 = (_258 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _258 === void 0 ? void 0 : _258.ImageUrlDark) === null || _259 === void 0 ? void 0 : _259._text,
                            leagueLogoLightUrl: (_261 = (_260 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Competition) === null || _260 === void 0 ? void 0 : _260.LogoUrlDark) === null || _261 === void 0 ? void 0 : _261._text,
                            homeTricode: (_263 = (_262 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _262 === void 0 ? void 0 : _262._attributes) === null || _263 === void 0 ? void 0 : _263.TriCode,
                            awayTricode: (_265 = (_264 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _264 === void 0 ? void 0 : _264._attributes) === null || _265 === void 0 ? void 0 : _265.TriCode,
                        };
                    }
                    else if (transformType === 'yinzcam-game-formation') {
                        if (!sourceData) {
                            return;
                        }
                        // console.log("GAME FORMATION DATA => ", sourceData);
                        const extractPlayerStats = (playerDetails) => {
                            let stats = [];
                            playerDetails === null || playerDetails === void 0 ? void 0 : playerDetails.map(detail => {
                                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
                                if (isArray(detail === null || detail === void 0 ? void 0 : detail.Player)) {
                                    (_a = detail === null || detail === void 0 ? void 0 : detail.Player) === null || _a === void 0 ? void 0 : _a.map(data => {
                                        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                                        let playerStat = {};
                                        let attributeData = ((_a = data === null || data === void 0 ? void 0 : data.Player) === null || _a === void 0 ? void 0 : _a._attributes) || (data === null || data === void 0 ? void 0 : data._attributes);
                                        playerStat = {
                                            position: { text: (attributeData === null || attributeData === void 0 ? void 0 : attributeData.PositionNumber) || (attributeData === null || attributeData === void 0 ? void 0 : attributeData.Number) },
                                            playerName: { text: attributeData === null || attributeData === void 0 ? void 0 : attributeData.Name },
                                            playingPosition: { text: (attributeData === null || attributeData === void 0 ? void 0 : attributeData.Position) || "" },
                                            playerImageUrl: ((_c = (_b = data === null || data === void 0 ? void 0 : data.Player) === null || _b === void 0 ? void 0 : _b.ImageUrl) === null || _c === void 0 ? void 0 : _c._text) || ((_d = data === null || data === void 0 ? void 0 : data.ImageUrl) === null || _d === void 0 ? void 0 : _d._text),
                                            events: {
                                                goalTimes: (attributeData === null || attributeData === void 0 ? void 0 : attributeData.GoalTimes) && ((_e = attributeData === null || attributeData === void 0 ? void 0 : attributeData.GoalTimes) === null || _e === void 0 ? void 0 : _e.split(",")),
                                                assistTimes: (attributeData === null || attributeData === void 0 ? void 0 : attributeData.AssistTimes) && ((_f = attributeData === null || attributeData === void 0 ? void 0 : attributeData.AssistTimes) === null || _f === void 0 ? void 0 : _f.split(",")),
                                                redCardTimes: (attributeData === null || attributeData === void 0 ? void 0 : attributeData.RedCardTimes) && ((_g = attributeData === null || attributeData === void 0 ? void 0 : attributeData.RedCardTimes) === null || _g === void 0 ? void 0 : _g.split(",")),
                                                yellowCardTimes: (attributeData === null || attributeData === void 0 ? void 0 : attributeData.YellowCardTimes) && ((_h = attributeData === null || attributeData === void 0 ? void 0 : attributeData.YellowCardTimes) === null || _h === void 0 ? void 0 : _h.split(",")),
                                                subbedOffTimes: (attributeData === null || attributeData === void 0 ? void 0 : attributeData.SubOffTimes) && ((_j = attributeData === null || attributeData === void 0 ? void 0 : attributeData.SubOffTimes) === null || _j === void 0 ? void 0 : _j.split(",")),
                                                subbedOnTimes: (attributeData === null || attributeData === void 0 ? void 0 : attributeData.SubOnTimes) && ((_k = attributeData === null || attributeData === void 0 ? void 0 : attributeData.SubOnTimes) === null || _k === void 0 ? void 0 : _k.split(",")),
                                            }
                                        };
                                        stats.push(playerStat);
                                    });
                                }
                                else {
                                    let playerStat = {};
                                    let attributeData = ((_b = detail === null || detail === void 0 ? void 0 : detail.Player) === null || _b === void 0 ? void 0 : _b._attributes) || (detail === null || detail === void 0 ? void 0 : detail._attributes);
                                    playerStat = {
                                        position: { text: (attributeData === null || attributeData === void 0 ? void 0 : attributeData.PositionNumber) || (attributeData === null || attributeData === void 0 ? void 0 : attributeData.Number) },
                                        playerName: { text: (attributeData === null || attributeData === void 0 ? void 0 : attributeData.Name) || (attributeData === null || attributeData === void 0 ? void 0 : attributeData.Name) },
                                        playingPosition: { text: (attributeData === null || attributeData === void 0 ? void 0 : attributeData.Position) || "" },
                                        playerImageUrl: ((_d = (_c = detail === null || detail === void 0 ? void 0 : detail.Player) === null || _c === void 0 ? void 0 : _c.ImageUrl) === null || _d === void 0 ? void 0 : _d._text) || ((_e = detail === null || detail === void 0 ? void 0 : detail.ImageUrl) === null || _e === void 0 ? void 0 : _e._text),
                                        events: {
                                            goalTimes: (attributeData === null || attributeData === void 0 ? void 0 : attributeData.GoalTimes) && ((_f = attributeData === null || attributeData === void 0 ? void 0 : attributeData.GoalTimes) === null || _f === void 0 ? void 0 : _f.split(",")),
                                            assistTimes: (attributeData === null || attributeData === void 0 ? void 0 : attributeData.AssistTimes) && ((_g = attributeData === null || attributeData === void 0 ? void 0 : attributeData.AssistTimes) === null || _g === void 0 ? void 0 : _g.split(",")),
                                            redCardTimes: (attributeData === null || attributeData === void 0 ? void 0 : attributeData.RedCardTimes) && ((_h = attributeData === null || attributeData === void 0 ? void 0 : attributeData.RedCardTimes) === null || _h === void 0 ? void 0 : _h.split(",")),
                                            yellowCardTimes: (attributeData === null || attributeData === void 0 ? void 0 : attributeData.YellowCardTimes) && ((_j = attributeData === null || attributeData === void 0 ? void 0 : attributeData.YellowCardTimes) === null || _j === void 0 ? void 0 : _j.split(",")),
                                            subbedOffTimes: (attributeData === null || attributeData === void 0 ? void 0 : attributeData.SubOffTimes) && ((_k = attributeData === null || attributeData === void 0 ? void 0 : attributeData.SubOffTimes) === null || _k === void 0 ? void 0 : _k.split(",")),
                                            subbedOnTimes: (attributeData === null || attributeData === void 0 ? void 0 : attributeData.SubOnTimes) && ((_l = attributeData === null || attributeData === void 0 ? void 0 : attributeData.SubOnTimes) === null || _l === void 0 ? void 0 : _l.split(",")),
                                        }
                                    };
                                    stats.push(playerStat);
                                }
                            });
                            return stats;
                        };
                        let isHomeTeam = ($mergedParams === null || $mergedParams === void 0 ? void 0 : $mergedParams.selectedTeam) === 'home';
                        let formationTeamDetails = isHomeTeam ? (_266 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Formations) === null || _266 === void 0 ? void 0 : _266.HomeTeamFormation : (_267 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Formations) === null || _267 === void 0 ? void 0 : _267.AwayTeamFormation;
                        let subTeamDetails = isHomeTeam ? (_268 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Substitutes) === null || _268 === void 0 ? void 0 : _268.HomeTeam : (_269 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Substitutes) === null || _269 === void 0 ? void 0 : _269.AwayTeam;
                        let imgTeamLogo = { source: isHomeTeam ? (_271 = (_270 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _270 === void 0 ? void 0 : _270.ImageUrl) === null || _271 === void 0 ? void 0 : _271._text : (_273 = (_272 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _272 === void 0 ? void 0 : _272.ImageUrl) === null || _273 === void 0 ? void 0 : _273._text };
                        let txtTeamName = { text: isHomeTeam ? (_275 = (_274 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _274 === void 0 ? void 0 : _274._attributes) === null || _275 === void 0 ? void 0 : _275.FullName : (_277 = (_276 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _276 === void 0 ? void 0 : _276._attributes) === null || _277 === void 0 ? void 0 : _277.FullName };
                        let teamDetails = (_278 = formationTeamDetails === null || formationTeamDetails === void 0 ? void 0 : formationTeamDetails.Row) === null || _278 === void 0 ? void 0 : _278.map(detail => {
                            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8;
                            let rowDetail = [];
                            if (isArray(detail === null || detail === void 0 ? void 0 : detail.Player)) {
                                (_a = detail === null || detail === void 0 ? void 0 : detail.Player) === null || _a === void 0 ? void 0 : _a.map(data => {
                                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
                                    rowDetail.push({
                                        playerImage: (_a = data === null || data === void 0 ? void 0 : data.ImageUrl) === null || _a === void 0 ? void 0 : _a._text,
                                        goalTimes: ((_b = data === null || data === void 0 ? void 0 : data._attributes) === null || _b === void 0 ? void 0 : _b.GoalTimes) && ((_d = (_c = data === null || data === void 0 ? void 0 : data._attributes) === null || _c === void 0 ? void 0 : _c.GoalTimes) === null || _d === void 0 ? void 0 : _d.split(",")),
                                        assistTimes: ((_e = data === null || data === void 0 ? void 0 : data._attributes) === null || _e === void 0 ? void 0 : _e.AssistTimes) && ((_g = (_f = data === null || data === void 0 ? void 0 : data._attributes) === null || _f === void 0 ? void 0 : _f.AssistTimes) === null || _g === void 0 ? void 0 : _g.split(",")),
                                        redCardTimes: ((_h = data === null || data === void 0 ? void 0 : data._attributes) === null || _h === void 0 ? void 0 : _h.RedCardTimes) && ((_k = (_j = data === null || data === void 0 ? void 0 : data._attributes) === null || _j === void 0 ? void 0 : _j.RedCardTimes) === null || _k === void 0 ? void 0 : _k.split(",")),
                                        yellowCardTimes: ((_l = data === null || data === void 0 ? void 0 : data._attributes) === null || _l === void 0 ? void 0 : _l.YellowCardTimes) && ((_o = (_m = data === null || data === void 0 ? void 0 : data._attributes) === null || _m === void 0 ? void 0 : _m.YellowCardTimes) === null || _o === void 0 ? void 0 : _o.split(",")),
                                        subbedOffTimes: ((_p = data === null || data === void 0 ? void 0 : data._attributes) === null || _p === void 0 ? void 0 : _p.SubOffTimes) && ((_r = (_q = data === null || data === void 0 ? void 0 : data._attributes) === null || _q === void 0 ? void 0 : _q.SubOffTimes) === null || _r === void 0 ? void 0 : _r.split(",")),
                                        subbedOnTimes: ((_s = data === null || data === void 0 ? void 0 : data._attributes) === null || _s === void 0 ? void 0 : _s.SubOnTimes) && ((_u = (_t = data === null || data === void 0 ? void 0 : data._attributes) === null || _t === void 0 ? void 0 : _t.SubOnTimes) === null || _u === void 0 ? void 0 : _u.split(",")),
                                    });
                                });
                            }
                            else {
                                rowDetail.push({
                                    playerImage: (_c = (_b = detail === null || detail === void 0 ? void 0 : detail.Player) === null || _b === void 0 ? void 0 : _b.ImageUrl) === null || _c === void 0 ? void 0 : _c._text,
                                    goalTimes: ((_e = (_d = detail === null || detail === void 0 ? void 0 : detail.Player) === null || _d === void 0 ? void 0 : _d._attributes) === null || _e === void 0 ? void 0 : _e.GoalTimes) && ((_h = (_g = (_f = detail === null || detail === void 0 ? void 0 : detail.Player) === null || _f === void 0 ? void 0 : _f._attributes) === null || _g === void 0 ? void 0 : _g.GoalTimes) === null || _h === void 0 ? void 0 : _h.split(",")),
                                    assistTimes: ((_k = (_j = detail === null || detail === void 0 ? void 0 : detail.Player) === null || _j === void 0 ? void 0 : _j._attributes) === null || _k === void 0 ? void 0 : _k.AssistTimes) && ((_o = (_m = (_l = detail === null || detail === void 0 ? void 0 : detail.Player) === null || _l === void 0 ? void 0 : _l._attributes) === null || _m === void 0 ? void 0 : _m.AssistTimes) === null || _o === void 0 ? void 0 : _o.split(",")),
                                    redCardTimes: ((_q = (_p = detail === null || detail === void 0 ? void 0 : detail.Player) === null || _p === void 0 ? void 0 : _p._attributes) === null || _q === void 0 ? void 0 : _q.RedCardTimes) && ((_t = (_s = (_r = detail === null || detail === void 0 ? void 0 : detail.Player) === null || _r === void 0 ? void 0 : _r._attributes) === null || _s === void 0 ? void 0 : _s.RedCardTimes) === null || _t === void 0 ? void 0 : _t.split(",")),
                                    yellowCardTimes: ((_v = (_u = detail === null || detail === void 0 ? void 0 : detail.Player) === null || _u === void 0 ? void 0 : _u._attributes) === null || _v === void 0 ? void 0 : _v.YellowCardTimes) && ((_y = (_x = (_w = detail === null || detail === void 0 ? void 0 : detail.Player) === null || _w === void 0 ? void 0 : _w._attributes) === null || _x === void 0 ? void 0 : _x.YellowCardTimes) === null || _y === void 0 ? void 0 : _y.split(",")),
                                    subbedOffTimes: ((_0 = (_z = detail === null || detail === void 0 ? void 0 : detail.Player) === null || _z === void 0 ? void 0 : _z._attributes) === null || _0 === void 0 ? void 0 : _0.SubOffTimes) && ((_3 = (_2 = (_1 = detail === null || detail === void 0 ? void 0 : detail.Player) === null || _1 === void 0 ? void 0 : _1._attributes) === null || _2 === void 0 ? void 0 : _2.SubOffTimes) === null || _3 === void 0 ? void 0 : _3.split(",")),
                                    subbedOnTimes: ((_5 = (_4 = detail === null || detail === void 0 ? void 0 : detail.Player) === null || _4 === void 0 ? void 0 : _4._attributes) === null || _5 === void 0 ? void 0 : _5.SubOnTimes) && ((_8 = (_7 = (_6 = detail === null || detail === void 0 ? void 0 : detail.Player) === null || _6 === void 0 ? void 0 : _6._attributes) === null || _7 === void 0 ? void 0 : _7.SubOnTimes) === null || _8 === void 0 ? void 0 : _8.split(",")),
                                });
                            }
                            return { players: rowDetail };
                        });
                        let playerStarterStats = extractPlayerStats(formationTeamDetails === null || formationTeamDetails === void 0 ? void 0 : formationTeamDetails.Row);
                        let playerSubStats = extractPlayerStats(subTeamDetails === null || subTeamDetails === void 0 ? void 0 : subTeamDetails.Player);
                        let homePlayerStarterStats = extractPlayerStats((_280 = (_279 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Formations) === null || _279 === void 0 ? void 0 : _279.HomeTeamFormation) === null || _280 === void 0 ? void 0 : _280.Row);
                        let homePlayerSubStats = extractPlayerStats((_282 = (_281 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Substitutes) === null || _281 === void 0 ? void 0 : _281.HomeTeam) === null || _282 === void 0 ? void 0 : _282.Player);
                        let awayPlayerStarterStats = extractPlayerStats((_284 = (_283 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Formations) === null || _283 === void 0 ? void 0 : _283.AwayTeamFormation) === null || _284 === void 0 ? void 0 : _284.Row);
                        let awayPlayerSubStats = extractPlayerStats((_286 = (_285 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Substitutes) === null || _285 === void 0 ? void 0 : _285.AwayTeam) === null || _286 === void 0 ? void 0 : _286.Player);
                        let txtHeaderTabs = { headerTabs: [(_288 = (_287 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _287 === void 0 ? void 0 : _287._attributes) === null || _288 === void 0 ? void 0 : _288.FullName, (_290 = (_289 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _289 === void 0 ? void 0 : _289._attributes) === null || _290 === void 0 ? void 0 : _290.FullName] };
                        let defaultSelectTabTeam = ((_293 = (_292 = (_291 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _291 === void 0 ? void 0 : _291._attributes) === null || _292 === void 0 ? void 0 : _292.TriCode) === null || _293 === void 0 ? void 0 : _293.toLowerCase()) === CONFIG.tricode.toLowerCase() ? (_295 = (_294 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _294 === void 0 ? void 0 : _294._attributes) === null || _295 === void 0 ? void 0 : _295.FullName : (_297 = (_296 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _296 === void 0 ? void 0 : _296._attributes) === null || _297 === void 0 ? void 0 : _297.FullName;
                        let homeTeamLogo = (_299 = (_298 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _298 === void 0 ? void 0 : _298.ImageUrl) === null || _299 === void 0 ? void 0 : _299._text;
                        let awayTeamLogo = (_301 = (_300 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _300 === void 0 ? void 0 : _300.ImageUrl) === null || _301 === void 0 ? void 0 : _301._text;
                        let teamTricode = isHomeTeam ? (_303 = (_302 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _302 === void 0 ? void 0 : _302._attributes) === null || _303 === void 0 ? void 0 : _303.TriCode : (_305 = (_304 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _304 === void 0 ? void 0 : _304._attributes) === null || _305 === void 0 ? void 0 : _305.TriCode;
                        return {
                            teamDetails,
                            imgTeamLogo,
                            playerStarterStats,
                            playerSubStats,
                            homePlayerStarterStats,
                            homePlayerSubStats,
                            awayPlayerStarterStats,
                            awayPlayerSubStats,
                            isHomeTeam,
                            txtHeaderTabs,
                            homeTeamLogo,
                            awayTeamLogo,
                            txtTeamName,
                            defaultSelectTabTeam,
                            teamTricode,
                        };
                    }
                    else if (transformType === 'yinzcam-game-commentary') {
                        // console.log("commentary Data => ",sourceData);
                        let homeTeamLogo = (sourceData === null || sourceData === void 0 ? void 0 : sourceData.IsHome) !== null && (sourceData === null || sourceData === void 0 ? void 0 : sourceData.IsHome) ? (_306 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _306 === void 0 ? void 0 : _306.ImageUrl : "";
                        let awayTeamLogo = (sourceData === null || sourceData === void 0 ? void 0 : sourceData.IsHome) !== null && !(sourceData === null || sourceData === void 0 ? void 0 : sourceData.IsHome) ? (_307 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _307 === void 0 ? void 0 : _307.ImageUrl : "";
                        let backgroundImage = { source: sourceData === null || sourceData === void 0 ? void 0 : sourceData.BackgroundImageUrl };
                        return {
                            txtMinutes: { text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.Time },
                            txtTitle: { text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.Title },
                            txtCommentary: { text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.Text },
                            eventType: sourceData === null || sourceData === void 0 ? void 0 : sourceData.Type,
                            imgTitleIcon: { source: (sourceData === null || sourceData === void 0 ? void 0 : sourceData.Type) === "SUB" && "https://resources-uk.yinzcam.com/soccer/shared/icon_substitution.png" },
                            imgCommentTeamLogo: { source: (sourceData === null || sourceData === void 0 ? void 0 : sourceData.IsHome) ? homeTeamLogo : awayTeamLogo },
                            isEventIcon: (sourceData === null || sourceData === void 0 ? void 0 : sourceData.Type) === "SUB",
                            imgPlayerPhoto: { source: (sourceData === null || sourceData === void 0 ? void 0 : sourceData.Type) === "GOAL" ? (((_308 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Player1) === null || _308 === void 0 ? void 0 : _308.ImageUrl) || ((_309 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Player2) === null || _309 === void 0 ? void 0 : _309.ImageUrl))
                                    : (sourceData === null || sourceData === void 0 ? void 0 : sourceData.Type) === "IMAGE" ? sourceData === null || sourceData === void 0 ? void 0 : sourceData.ImageUrl : "" },
                            txtEventText: { text: (sourceData === null || sourceData === void 0 ? void 0 : sourceData.Type) === "GOAL" ? "GOAL" : "" },
                            variantType: ["GOAL", "IMAGE"].includes(sourceData === null || sourceData === void 0 ? void 0 : sourceData.Type) ? 'comment-and-photo' : (sourceData === null || sourceData === void 0 ? void 0 : sourceData.Type) === "TWITTER" ? 'comment-and-embed' : 'comment',
                            htmlContent: { html: sourceData === null || sourceData === void 0 ? void 0 : sourceData.EmbedCode },
                            commentTeam: (sourceData === null || sourceData === void 0 ? void 0 : sourceData.IsHome) ? (_310 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _310 === void 0 ? void 0 : _310.TriCode : (_311 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _311 === void 0 ? void 0 : _311.TriCode,
                            txtPlayerOff: { text: (_312 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Player2) === null || _312 === void 0 ? void 0 : _312.Name },
                            txtPlayerOn: { text: (_313 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Player1) === null || _313 === void 0 ? void 0 : _313.Name },
                            lastCard: sourceData === null || sourceData === void 0 ? void 0 : sourceData.LastCard,
                            firstCard: sourceData === null || sourceData === void 0 ? void 0 : sourceData.FirstCard,
                            backgroundImage
                        };
                    }
                    else if (transformType === 'yinzcam-game-events') {
                        // console.log("Game events => ", sourceData);
                        const eventMap = {
                            "GOAL": "goal",
                            "SUB": "substitution",
                            "YELLOW": "yellow-card",
                            "RED": "red-card",
                        };
                        let arrTimelineEvents = (_314 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.events) === null || _314 === void 0 ? void 0 : _314.map(record => {
                            var _a, _b;
                            return ({
                                badgeType: eventMap[record === null || record === void 0 ? void 0 : record.Type],
                                eventTime: record === null || record === void 0 ? void 0 : record.Time,
                                eventTeam: (record === null || record === void 0 ? void 0 : record.IsHome) ? "home-team" : "away-team",
                                badgeTextOne: { text: (_a = record === null || record === void 0 ? void 0 : record.Player1) === null || _a === void 0 ? void 0 : _a.Name },
                                badgeTextTwo: { text: (record === null || record === void 0 ? void 0 : record.Type) === "SUB" ? (_b = record === null || record === void 0 ? void 0 : record.Player2) === null || _b === void 0 ? void 0 : _b.Name : "" },
                            });
                        });
                        return {
                            arrTimelineEvents,
                            imgHomeLogo: { source: `https://resources-uk.yinzcam.com/soccer/shared/logos/${(_315 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.homeTeamLogoId) === null || _315 === void 0 ? void 0 : _315.toLowerCase()}_light.png` },
                            imgAwayLogo: { source: `https://resources-uk.yinzcam.com/soccer/shared/logos/${(_316 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.awayTeamLogoId) === null || _316 === void 0 ? void 0 : _316.toLowerCase()}_light.png` },
                            matchStatus: ('C' === ((_317 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.matchStatus) === null || _317 === void 0 ? void 0 : _317._text)) ? 'live' : ('F' === ((_318 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.matchStatus) === null || _318 === void 0 ? void 0 : _318._text)) ? 'post' : 'pre',
                        };
                    }
                    else if (transformType === 'yinzcam-stats-team') {
                        // console.log("Stats team => ", sourceData);
                    }
                    else if (transformType === 'contentful-page-header') {
                        // console.log("Contentful page header => ", sourceData);
                        return {
                            headerText: { text: (sourceData === null || sourceData === void 0 ? void 0 : sourceData.name) || '' },
                            bodyText: { text: (sourceData === null || sourceData === void 0 ? void 0 : sourceData.description) || '' },
                            thumbnail: { source: (_320 = (_319 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.leadImage) === null || _319 === void 0 ? void 0 : _319.file) === null || _320 === void 0 ? void 0 : _320.url },
                        };
                    }
                    else {
                        console.log('unrecognized transform', transform);
                    }
                }
                catch (e) {
                    console.log('transform threw exception', transform, e);
                }
                return ret;
            }
            function mapSourcesToProps(specData, transformData, sourceData, elementData) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                if (specData.schema.type !== "object") {
                    throw new Error('mapSourceToProps spec must be for an object');
                }
                if (_.isString(transformData.transform)) {
                    sourceData.data = runTransformTemporary(transformData.transform, sourceData.data);
                    transformData = {};
                }
                const ret = {};
                for (const [key, propSpec] of Object.entries(specData.schema.properties)) {
                    if (!propSpec) {
                        continue;
                    }
                    try {
                        let isArray = false;
                        let propType = propSpec.type;
                        if (propSpec.type === 'array') {
                            if (_.isArray(propSpec.items)) {
                                throw new Error('mixed-type arrays are not supported');
                            }
                            isArray = true;
                            propType = propSpec.items.type;
                        }
                        if (_.isArray(propType)) {
                            throw new Error('union-typed properties are not supported');
                        }
                        // NOTE: This version has handling for paths with arrays, but not using for now due to complexity.
                        /*
                        function resolvePathToValue(specData: SpecData, sourceData: SourceData, index: number, currentValue: unknown, path: string): unknown {
                          path = path.trim();
                          if (path === '') {
                            return currentValue;
                          }
                          if (specData?.schema?.type !== 'object') {
                            throw new Error('specData argument is not an object spec');
                          }
                          if (!_.isPlainObject(sourceData.data)) {
                            throw new Error('sourceData argument is not an object');
                          }
                          const pathParts = path.split('/');
                          let first: boolean = true;
                          let indexUsed: boolean = false;
                          let valueFound: boolean = false;
                          for (const part of pathParts) {
                            if (valueFound) {
                              throw new Error('cannot have path parts after value part');
                            }
                            switch (part) {
                              case '':
                                if (first) {
                                  // find the root
                                  while (sourceData.parent) {
                                    sourceData = sourceData.parent;
                                    if (!_.isPlainObject(sourceData)) {
                                      throw new Error('source parent is not an object');
                                    }
                                    specData = specData.parent;
                                    if (specData?.schema?.type !== 'object') {
                                      throw new Error('spec parent is not an object spec');
                                    }
                                  }
                                  if (specData.parent) {
                                    throw new Error('root of source data is not root of spec data');
                                  }
                                }
                                break;
                              case '.':
                                break;
                              case '..':
                                if (sourceData.parent) {
                                  sourceData = sourceData.parent;
                                  if (!_.isPlainObject(sourceData)) {
                                    throw new Error('source parent is not an object');
                                  }
                                  specData = specData.parent;
                                  if (specData?.schema?.type === 'object') {
                                    throw new Error('spec parent is not an object spec');
                                  }
                                } else if (specData.parent) {
                                  throw new Error('at root of source data but not root of spec data');
                                }
                                break;
                              default:
                                if (part.endsWith('[]')) {
                                  if (indexUsed) {
                                    throw new Error('cannot use array index more than once within transform path');
                                  }
                                  const prop = specData.schema.properties[part];
                                  if (!prop) {
                                    throw new Error('spec object prop does not exist');
                                  }
                                  if (prop.type !== 'array') {
                                    throw new Error('only array props can be accessed through [] suffix');
                                  }
                                  const arrayData = sourceData.data[part];
                                  if (_.isUndefined(arrayData)) {
                                    currentValue = undefined;
                                    valueFound = true;
                                    break;
                                  }
                                  if (!_.isArray(arrayData)) {
                                    throw new Error('source data is not an array');
                                  }
                                  if (!_.isInteger(index)) {
                                    throw new Error('array index not specified or not an integer');
                                  }
                                  if (_.isArray(prop.items)) {
                                    throw new Error('mixed-type arrays are not supported');
                                  }
                                  if (prop.items.type === 'object') {
                                    const objectData = (index < arrayData.length)? arrayData[index] : undefined;
                                    if (_.isUndefined(objectData)) {
                                      currentValue = undefined;
                                      valueFound = true;
                                      break;
                                    }
                                    if (!_.isPlainObject(objectData)) {
                                      throw new Error('source data is not an object');
                                    }
                                    specData = { parent: specData, key: part, schema: prop };
                                    sourceData = { parent: sourceData, key: part, data: objectData as { [k: string]: unknown } };
                                  } else {
                                    currentValue = (index < arrayData.length)? arrayData[index] : undefined;
                                    valueFound = true;
                                  }
                                } else {
                                  const prop = specData.schema.properties[part];
                                  if (!prop) {
                                    throw new Error('spec object prop does not exist');
                                  }
                                  if (prop.type === 'array') {
                                    throw new Error('array props can only be accessed through [] suffix');
                                  }
                                  if (prop.type === 'object') {
                                    const objectData = sourceData.data[part];
                                    if (_.isUndefined(objectData)) {
                                      currentValue = undefined;
                                      valueFound = true;
                                      break;
                                    }
                                    if (!_.isPlainObject(objectData)) {
                                      throw new Error('source data is not an object');
                                    }
                                    specData = { parent: specData, key: part, schema: prop };
                                    sourceData = { parent: sourceData, key: part, data: objectData as { [k: string]: unknown } };
                                  } else {
                                    currentValue = sourceData.data[part];
                                    valueFound = true;
                                  }
                                }
                                break;
                            }
                            first = false;
                          }
                          if (!valueFound) {
                            throw new Error('path did not resolve to a value');
                          }
                          return currentValue;
                        }
                        */
                        // Finds the data object at a given path in the source data.
                        function resolvePath(specData, sourceData, currentValue, path) {
                            var _a, _b, _c;
                            if (((_a = specData === null || specData === void 0 ? void 0 : specData.schema) === null || _a === void 0 ? void 0 : _a.type) !== 'object') {
                                throw new Error('specData argument is not an object spec');
                            }
                            if (!_.isPlainObject(sourceData.data)) {
                                throw new Error('sourceData argument is not an object');
                            }
                            path = path.trim();
                            if (path === '') {
                                return currentValue;
                            }
                            const pathParts = path.split('/');
                            let first = true;
                            let valueFound = false;
                            currentValue = sourceData.data;
                            for (const part of pathParts) {
                                if (valueFound) {
                                    throw new Error('cannot have path parts after value part');
                                }
                                switch (part) {
                                    case '':
                                        if (first) {
                                            // find the root
                                            while (sourceData.parent) {
                                                sourceData = sourceData.parent;
                                                if (!_.isPlainObject(sourceData)) {
                                                    throw new Error('source parent is not an object');
                                                }
                                                specData = specData.parent;
                                                if (((_b = specData === null || specData === void 0 ? void 0 : specData.schema) === null || _b === void 0 ? void 0 : _b.type) !== 'object') {
                                                    throw new Error('spec parent is not an object spec');
                                                }
                                            }
                                            if (specData.parent) {
                                                throw new Error('root of source data is not root of spec data');
                                            }
                                            currentValue = sourceData.data;
                                        }
                                        break;
                                    case '.':
                                        break;
                                    case '..':
                                        if (sourceData.parent) {
                                            sourceData = sourceData.parent;
                                            if (!_.isPlainObject(sourceData)) {
                                                throw new Error('source parent is not an object');
                                            }
                                            specData = specData.parent;
                                            if (((_c = specData === null || specData === void 0 ? void 0 : specData.schema) === null || _c === void 0 ? void 0 : _c.type) === 'object') {
                                                throw new Error('spec parent is not an object spec');
                                            }
                                            currentValue = sourceData.data;
                                        }
                                        else if (specData.parent) {
                                            throw new Error('at root of source data but not root of spec data');
                                        }
                                        break;
                                    default:
                                        const prop = specData.schema.properties[part];
                                        if (!prop) {
                                            throw new Error('spec object prop does not exist');
                                        }
                                        if (prop.type === 'object') {
                                            const objectData = sourceData.data[part];
                                            if (_.isUndefined(objectData)) {
                                                currentValue = undefined;
                                                valueFound = true;
                                                break;
                                            }
                                            if (!_.isPlainObject(objectData)) {
                                                throw new Error('source data is not an object');
                                            }
                                            specData = { parent: specData, key: part, schema: prop };
                                            sourceData = { parent: sourceData, key: part, data: objectData };
                                            currentValue = objectData;
                                        }
                                        else {
                                            // NOTE: arrays are considered value (leaf) objects at the moment
                                            currentValue = sourceData.data[part];
                                            valueFound = true;
                                        }
                                        break;
                                }
                                first = false;
                            }
                            return currentValue;
                        }
                        function mapPrimitiveValue(type, propTransform, parentSpecData, propSpecData, parentSourceData, sourceValue, elementValue) {
                            var _a;
                            if (((_a = propTransform === null || propTransform === void 0 ? void 0 : propTransform.operators) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                                for (const operator of propTransform.operators) {
                                    const operands = operator.operandPaths.map(_.partial(resolvePath, parentSpecData, parentSourceData, sourceValue));
                                    // TODO: check that all of the operands are primitives?
                                    sourceValue = operator.func(...operands);
                                }
                            }
                            //console.log('mapPrimitiveValue', { type, transform, parentSpecData, propSpecData, parentSourceData, sourceValue, elementValue });
                            if (!_.isUndefined(sourceValue)) {
                                return upcast.to(sourceValue, type);
                            }
                            else if (!_.isUndefined(elementValue)) {
                                return upcast.to(elementValue, type);
                            }
                            else if (!_.isUndefined(propSpecData.schema.default)) {
                                return upcast.to(propSpecData.schema.default, type);
                            }
                            else {
                                return undefined;
                            }
                        }
                        const propTransform = (_b = (_a = transformData.transform) === null || _a === void 0 ? void 0 : _a.properties) === null || _b === void 0 ? void 0 : _b[key];
                        if (propType === 'object') {
                            if (isArray) {
                                const propSpecData = {
                                    parent: specData,
                                    key,
                                    schema: propSpec.items
                                };
                                //console.log('PROP SPEC DATA FOR ARRAY', propSpecData);
                                if (propTransform && (propTransform === null || propTransform === void 0 ? void 0 : propTransform.type) !== 'ARRAY') {
                                    throw new Error('prop transform for array is not ARRAY type');
                                }
                                const propArrayTransform = propTransform;
                                if ((propArrayTransform === null || propArrayTransform === void 0 ? void 0 : propArrayTransform.itemTransform) && propArrayTransform.itemTransform.type !== 'OBJECT') {
                                    throw new Error('prop transform for items in array of objects is not OBJECT type');
                                }
                                const propTransformData = {
                                    parent: transformData,
                                    key,
                                    transform: propArrayTransform === null || propArrayTransform === void 0 ? void 0 : propArrayTransform.itemTransform
                                };
                                const sourceArr = (_c = sourceData.data) === null || _c === void 0 ? void 0 : _c[key];
                                if (!_.isUndefined(sourceArr) && !_.isArray(sourceArr)) {
                                    throw new Error('source array is defined but not an array');
                                }
                                const transformArr = (propArrayTransform) ? resolvePath(specData, sourceData, sourceArr, propArrayTransform.sourceArrayPath) : undefined;
                                if (!_.isUndefined(transformArr) && !_.isArray(transformArr)) {
                                    throw new Error('transform array is defined but not an array');
                                }
                                const sourceOrTransformArr = (!_.isUndefined(transformArr)) ? transformArr : sourceArr;
                                const elementArr = (_d = elementData.data) === null || _d === void 0 ? void 0 : _d[key];
                                if (!_.isUndefined(elementArr) && !_.isArray(elementArr)) {
                                    console.error(elementData, elementArr);
                                    throw new Error('element array is defined but not an array');
                                }
                                let arr = [];
                                switch ((propArrayTransform === null || propArrayTransform === void 0 ? void 0 : propArrayTransform.arrayMergeStrategy) || 'ITEMWISE_SOURCE_LENGTH_FIRST_ELEMENT_DEFAULT') {
                                    case 'ARRAYWISE':
                                        // if we have a transform or a matching source array exists, use that
                                        if (propArrayTransform) {
                                            for (let i = 0; i < sourceOrTransformArr.length; i++) {
                                                const sourceObject = sourceOrTransformArr[i];
                                                if (!_.isUndefined(sourceObject) && !_.isPlainObject(sourceObject)) {
                                                    throw new Error('source array items should be objects but this one is not');
                                                }
                                                const propSourceData = {
                                                    parent: sourceData,
                                                    key,
                                                    index: i,
                                                    data: sourceObject
                                                };
                                                const propElementData = {
                                                    parent: elementData,
                                                    key,
                                                    index: i,
                                                    data: undefined
                                                };
                                                const val = mapSourcesToProps(propSpecData, propTransformData, propSourceData, propElementData);
                                                arr.push(val);
                                            }
                                        }
                                        else if (_.isArray(sourceArr)) {
                                            for (let i = 0; i < sourceArr.length; i++) {
                                                const sourceObject = sourceArr[i];
                                                if (_.isUndefined(sourceObject)) {
                                                    continue;
                                                }
                                                if (!_.isPlainObject(sourceObject)) {
                                                    throw new Error('source array items should be objects but this one is not');
                                                }
                                                const propSourceData = {
                                                    parent: sourceData,
                                                    key,
                                                    index: i,
                                                    data: sourceObject
                                                };
                                                const propElementData = {
                                                    parent: elementData,
                                                    key,
                                                    index: i,
                                                    data: undefined
                                                };
                                                const val = mapSourcesToProps(propSpecData, propTransformData, propSourceData, propElementData);
                                                arr.push(val);
                                            }
                                        }
                                        else if (_.isArray(elementArr)) {
                                            for (let i = 0; i < elementArr.length; i++) {
                                                const elementObject = elementArr[i];
                                                if (_.isUndefined(elementObject)) {
                                                    continue;
                                                }
                                                if (!_.isPlainObject(elementObject)) {
                                                    throw new Error('element array items should be objects but this one is not');
                                                }
                                                const propSourceData = {
                                                    parent: sourceData,
                                                    key,
                                                    index: i,
                                                    data: undefined
                                                };
                                                const propElementData = {
                                                    parent: elementData,
                                                    key,
                                                    index: i,
                                                    data: elementObject
                                                };
                                                const val = mapSourcesToProps(propSpecData, propTransformData, propSourceData, propElementData);
                                                arr.push(val);
                                            }
                                        }
                                        else {
                                            arr = undefined;
                                        }
                                        break;
                                    case "ITEMWISE_SOURCE_LENGTH":
                                        if (!_.isArray(sourceOrTransformArr)) {
                                            arr = elementArr;
                                        }
                                        else {
                                            for (let i = 0; i < sourceOrTransformArr.length; i++) {
                                                const sourceObject = sourceOrTransformArr[i];
                                                if (!_.isUndefined(sourceObject) && !_.isPlainObject(sourceObject)) {
                                                    throw new Error('source array items should be objects but this one is not');
                                                }
                                                const elementObject = (!_.isUndefined(elementArr) && i < elementArr.length) ? elementArr[i] : undefined;
                                                if (!_.isUndefined(elementObject) && !_.isPlainObject(elementObject)) {
                                                    throw new Error('element array items should be objects but this one is not');
                                                }
                                                const propSourceData = {
                                                    parent: sourceData,
                                                    key,
                                                    index: i,
                                                    data: sourceObject
                                                };
                                                const propElementData = {
                                                    parent: elementData,
                                                    key,
                                                    index: i,
                                                    data: elementObject
                                                };
                                                const val = mapSourcesToProps(propSpecData, propTransformData, propSourceData, propElementData);
                                                arr.push(val);
                                                /*
                                                if (propArrayTransform) {
                                                  
                                                } else if (!_.isUndefined(sourceObject)) {
                                                  arr.push(sourceObject);
                                                } else {
                                                  arr.push(elementObject);
                                                }
                                                */
                                            }
                                        }
                                        break;
                                    case "ITEMWISE_SOURCE_LENGTH_FIRST_ELEMENT_DEFAULT":
                                        const elementObject = (!_.isUndefined(elementArr) && elementArr.length > 0) ? elementArr[0] : undefined;
                                        if (!_.isUndefined(elementObject) && !_.isPlainObject(elementObject)) {
                                            throw new Error('element array items should be objects but this one is not');
                                        }
                                        if (_.isArray(sourceOrTransformArr)) {
                                            for (let i = 0; i < sourceOrTransformArr.length; i++) {
                                                const sourceObject = sourceOrTransformArr[i];
                                                if (!_.isUndefined(sourceObject) && !_.isPlainObject(sourceObject)) {
                                                    throw new Error('source array items should be objects but this one is not');
                                                }
                                                const propSourceData = {
                                                    parent: sourceData,
                                                    key,
                                                    index: i,
                                                    data: sourceObject
                                                };
                                                const propElementData = {
                                                    parent: elementData,
                                                    key,
                                                    index: i,
                                                    data: elementObject
                                                };
                                                const val = mapSourcesToProps(propSpecData, propTransformData, propSourceData, propElementData);
                                                arr.push(val);
                                                /*
                                                if (propArrayTransform) {
                                                  
                                                } else if (!_.isUndefined(sourceObject)) {
                                                  arr.push(sourceObject);
                                                } else {
                                                  arr.push(elementObject);
                                                }
                                                */
                                            }
                                        }
                                        else if (_.isArray(elementArr)) {
                                            for (let i = 0; i < elementArr.length; i++) {
                                                const elementObject = elementArr[i];
                                                if (_.isUndefined(elementObject)) {
                                                    continue;
                                                }
                                                if (!_.isPlainObject(elementObject)) {
                                                    throw new Error('element array items should be objects but this one is not');
                                                }
                                                const propSourceData = {
                                                    parent: sourceData,
                                                    key,
                                                    index: i,
                                                    data: undefined
                                                };
                                                const propElementData = {
                                                    parent: elementData,
                                                    key,
                                                    index: i,
                                                    data: elementObject
                                                };
                                                const val = mapSourcesToProps(propSpecData, propTransformData, propSourceData, propElementData);
                                                arr.push(val);
                                            }
                                        }
                                        else {
                                            arr = undefined;
                                        }
                                        break;
                                    case "ITEMWISE_ELEMENT_LENGTH":
                                        if (!_.isArray(elementArr)) {
                                            arr = undefined;
                                        }
                                        else {
                                            for (let i = 0; i < elementArr.length; i++) {
                                                const sourceObject = (!_.isUndefined(sourceOrTransformArr) && i < sourceOrTransformArr.length) ? sourceOrTransformArr[i] : undefined;
                                                if (!_.isUndefined(sourceObject) && !_.isPlainObject(sourceObject)) {
                                                    throw new Error('source array items should be objects but this one is not');
                                                }
                                                const elementObject = elementArr[i];
                                                if (!_.isUndefined(elementObject) && !_.isPlainObject(elementObject)) {
                                                    throw new Error('element array items should be objects but this one is not');
                                                }
                                                const propSourceData = {
                                                    parent: sourceData,
                                                    key,
                                                    index: i,
                                                    data: sourceObject
                                                };
                                                const propElementData = {
                                                    parent: elementData,
                                                    key,
                                                    index: i,
                                                    data: elementObject
                                                };
                                                const val = mapSourcesToProps(propSpecData, propTransformData, propSourceData, propElementData);
                                                arr.push(val);
                                                /*
                                                if (propArrayTransform) {
                      
                                                } else if (!_.isUndefined(sourceObject)) {
                                                  arr.push(sourceObject);
                                                } else {
                                                  arr.push(elementObject);
                                                }
                                                */
                                            }
                                        }
                                        break;
                                }
                                if (!_.isUndefined(arr)) {
                                    ret[key] = arr;
                                }
                            }
                            else {
                                const propSpecData = {
                                    parent: specData,
                                    key,
                                    schema: propSpec
                                };
                                if (propTransform && propTransform.type !== 'OBJECT') {
                                    throw new Error('prop transform for object is not OBJECT type');
                                }
                                const propTransformData = {
                                    parent: transformData,
                                    key,
                                    transform: propTransform
                                };
                                const sourceObject = (_e = sourceData.data) === null || _e === void 0 ? void 0 : _e[key];
                                if (!_.isUndefined(sourceObject) && !_.isPlainObject(sourceObject)) {
                                    console.log('ERROR???', propSpec, sourceObject);
                                    throw new Error('source data item should be object but this one is not');
                                }
                                const elementObject = (_f = elementData.data) === null || _f === void 0 ? void 0 : _f[key];
                                if (!_.isUndefined(elementObject) && !_.isPlainObject(elementObject)) {
                                    //console.error('oh no', key, elementObject);
                                    throw new Error('element data item should be object but this one is not');
                                }
                                const propSourceData = {
                                    parent: sourceData,
                                    key,
                                    data: sourceObject
                                };
                                const propElementData = {
                                    parent: elementData,
                                    key,
                                    data: elementObject
                                };
                                ret[key] = mapSourcesToProps(propSpecData, propTransformData, propSourceData, propElementData);
                            }
                        }
                        else {
                            // not object
                            if (isArray) {
                                const propSpecData = {
                                    parent: specData,
                                    key,
                                    schema: propSpec.items
                                };
                                if (propTransform && (propTransform === null || propTransform === void 0 ? void 0 : propTransform.type) !== 'ARRAY') {
                                    throw new Error('prop transform for array is not ARRAY type');
                                }
                                const propArrayTransform = propTransform;
                                if ((propArrayTransform === null || propArrayTransform === void 0 ? void 0 : propArrayTransform.itemTransform) && propArrayTransform.itemTransform.type !== 'PRIMITIVE') {
                                    throw new Error('prop transform for items in array of primitives is not PRIMITIVE type');
                                }
                                const propArrayItemTransform = propArrayTransform === null || propArrayTransform === void 0 ? void 0 : propArrayTransform.itemTransform;
                                const sourceArr = (_g = sourceData.data) === null || _g === void 0 ? void 0 : _g[key];
                                if (!_.isUndefined(sourceArr) && !_.isArray(sourceArr)) {
                                    throw new Error('source array is defined but not an array');
                                }
                                const transformArr = (propArrayTransform) ? resolvePath(specData, sourceData, sourceArr, propArrayTransform.sourceArrayPath) : undefined;
                                if (!_.isUndefined(transformArr) && !_.isArray(transformArr)) {
                                    throw new Error('transform array is defined but not an array');
                                }
                                const sourceOrTransformArr = (!_.isUndefined(transformArr)) ? transformArr : sourceArr;
                                const elementArr = (_h = elementData.data) === null || _h === void 0 ? void 0 : _h[key];
                                if (!_.isUndefined(elementArr) && !_.isArray(elementArr)) {
                                    throw new Error('element array is defined but not an array');
                                }
                                let arr = [];
                                switch ((propArrayTransform === null || propArrayTransform === void 0 ? void 0 : propArrayTransform.arrayMergeStrategy) || 'ITEMWISE_SOURCE_LENGTH_FIRST_ELEMENT_DEFAULT') {
                                    case 'ARRAYWISE':
                                        // if we have a transform or a matching source array exists, use that
                                        if (propArrayTransform) {
                                            for (let i = 0; i < sourceOrTransformArr.length; i++) {
                                                const sourceValue = sourceOrTransformArr[i];
                                                if (!_.isUndefined(sourceValue) && _.isObject(sourceValue)) {
                                                    throw new Error('array items should be primitives but this one is not');
                                                }
                                                const val = mapPrimitiveValue(propType, propArrayItemTransform, specData, propSpecData, sourceData, sourceValue, undefined);
                                                arr.push(val);
                                            }
                                        }
                                        else if (_.isArray(sourceArr)) {
                                            for (let i = 0; i < sourceArr.length; i++) {
                                                const sourceValue = sourceArr[i];
                                                if (_.isUndefined(sourceValue)) {
                                                    continue;
                                                }
                                                if (_.isObject(sourceValue)) {
                                                    throw new Error('array items should be primitives but this one is not');
                                                }
                                                const val = mapPrimitiveValue(propType, propArrayItemTransform, specData, propSpecData, sourceData, sourceValue, undefined);
                                                arr.push(val);
                                            }
                                        }
                                        else if (_.isArray(elementArr)) {
                                            for (let i = 0; i < elementArr.length; i++) {
                                                const elementValue = elementArr[i];
                                                if (_.isUndefined(elementValue)) {
                                                    continue;
                                                }
                                                if (_.isObject(elementValue)) {
                                                    throw new Error('array items should be primitives but this one is not');
                                                }
                                                const val = mapPrimitiveValue(propType, propArrayItemTransform, specData, propSpecData, sourceData, undefined, elementValue);
                                                arr.push(val);
                                            }
                                        }
                                        else {
                                            arr = undefined;
                                        }
                                        break;
                                    case "ITEMWISE_SOURCE_LENGTH":
                                        if (!_.isArray(sourceOrTransformArr)) {
                                            arr = elementArr;
                                        }
                                        else {
                                            for (let i = 0; i < sourceOrTransformArr.length; i++) {
                                                const sourceValue = sourceOrTransformArr[i];
                                                if (!_.isUndefined(sourceValue) && _.isObject(sourceValue)) {
                                                    throw new Error('source array items should be primitives but this one is not');
                                                }
                                                const elementValue = (!_.isUndefined(elementArr) && i < elementArr.length) ? elementArr[i] : undefined;
                                                if (!_.isUndefined(elementValue) && _.isObject(elementValue)) {
                                                    throw new Error('element array items should be primitives but this one is not');
                                                }
                                                if (propArrayTransform) {
                                                    const val = mapPrimitiveValue(propType, propArrayItemTransform, specData, propSpecData, sourceData, sourceValue, elementValue);
                                                    arr.push(val);
                                                }
                                                else if (!_.isUndefined(sourceValue)) {
                                                    arr.push(sourceValue);
                                                }
                                                else {
                                                    arr.push(elementValue);
                                                }
                                            }
                                        }
                                        break;
                                    case "ITEMWISE_SOURCE_LENGTH_FIRST_ELEMENT_DEFAULT":
                                        const elementValue = (!_.isUndefined(elementArr) && elementArr.length > 0) ? elementArr[0] : undefined;
                                        if (!_.isUndefined(elementValue) && _.isObject(elementValue)) {
                                            throw new Error('element array items should be primitives but this one is not');
                                        }
                                        if (_.isArray(sourceOrTransformArr)) {
                                            for (let i = 0; i < sourceOrTransformArr.length; i++) {
                                                const sourceValue = sourceOrTransformArr[i];
                                                if (!_.isUndefined(sourceValue) && _.isObject(sourceValue)) {
                                                    throw new Error('source array items should be primitives but this one is not');
                                                }
                                                const val = mapPrimitiveValue(propType, propArrayItemTransform, specData, propSpecData, sourceData, sourceValue, elementValue);
                                                arr.push(val);
                                                /*
                                                if (propArrayTransform) {
                      
                                                } else if (!_.isUndefined(sourceValue)) {
                                                  arr.push(sourceValue);
                                                } else {
                                                  arr.push(elementValue);
                                                }
                                                */
                                            }
                                        }
                                        else if (_.isArray(elementArr)) {
                                            for (let i = 0; i < elementArr.length; i++) {
                                                const elementValue = elementArr[i];
                                                if (_.isUndefined(elementValue)) {
                                                    continue;
                                                }
                                                if (_.isObject(elementValue)) {
                                                    throw new Error('array items should be primitives but this one is not');
                                                }
                                                const val = mapPrimitiveValue(propType, propArrayItemTransform, specData, propSpecData, sourceData, undefined, elementValue);
                                                arr.push(val);
                                            }
                                        }
                                        else {
                                            arr = undefined;
                                        }
                                        break;
                                    case "ITEMWISE_ELEMENT_LENGTH":
                                        if (_.isUndefined(elementArr)) {
                                            arr = undefined;
                                        }
                                        else {
                                            for (let i = 0; i < elementArr.length; i++) {
                                                const sourceValue = (!_.isUndefined(sourceOrTransformArr) && i < sourceOrTransformArr.length) ? sourceOrTransformArr[i] : undefined;
                                                if (!_.isUndefined(sourceValue) && _.isObject(sourceValue)) {
                                                    throw new Error('source array items should be primitives but this one is not');
                                                }
                                                const elementValue = elementArr[i];
                                                if (!_.isUndefined(elementValue) && _.isObject(elementValue)) {
                                                    throw new Error('element array items should be primitives but this one is not');
                                                }
                                                const val = mapPrimitiveValue(propType, propArrayItemTransform, specData, propSpecData, sourceData, sourceValue, elementValue);
                                                arr.push(val);
                                                /*
                                                if (propArrayTransform) {
                                                  
                                                } else if (!_.isUndefined(sourceValue)) {
                                                  arr.push(sourceValue);
                                                } else {
                                                  arr.push(elementValue);
                                                }
                                                */
                                            }
                                        }
                                        break;
                                }
                                if (!_.isUndefined(arr)) {
                                    ret[key] = arr;
                                }
                            }
                            else {
                                const propSpecData = {
                                    parent: specData,
                                    key,
                                    schema: propSpec
                                };
                                if (!_.isUndefined(propTransform) && (propTransform === null || propTransform === void 0 ? void 0 : propTransform.type) !== 'PRIMITIVE') {
                                    throw new Error('prop transform for primitive is not PRIMITIVE type');
                                }
                                const val = mapPrimitiveValue(propType, propTransform, specData, propSpecData, sourceData, (_j = sourceData.data) === null || _j === void 0 ? void 0 : _j[key], (_k = elementData.data) === null || _k === void 0 ? void 0 : _k[key]);
                                if (!_.isUndefined(val)) {
                                    ret[key] = val;
                                }
                            }
                        }
                    }
                    catch (e) {
                        console.error(`unable to map data for prop ${key}`, e);
                    }
                }
                if (specData.schema.additionalProperties) {
                    if (sourceData === null || sourceData === void 0 ? void 0 : sourceData.data) {
                        for (const [key, val] of Object.entries(sourceData.data)) {
                            if (!(key in ret)) {
                                ret[key] = val;
                            }
                        }
                    }
                    if (elementData === null || elementData === void 0 ? void 0 : elementData.data) {
                        for (const [key, val] of Object.entries(elementData.data)) {
                            if (!(key in ret)) {
                                ret[key] = val;
                            }
                        }
                    }
                }
                return ret;
            }
            spreadProps = mapSourcesToProps({ schema: configSpec }, { transform }, { data: $firstSourceData }, { data: expandedElement.data });
            //console.log('SPREAD PROPS', sequenceId, element.class, spreadProps);
            /*
            for (const [key, propSpec] of Object.entries(configSpec.properties)) {
              if (propSpec?.type !== "string") {
                console.error(`element ${element.class} dynamic property ${key} is not of type string and only string props are currently supported`);
                continue;
              }
              newSpreadProps[key] = getTemplatedElementDataFromSource(expandedElement, key, $firstSourceData)?.toString();
            }
            spreadProps = newSpreadProps;
            */
            //inlineStyle = buildInlineStyle(...Object.entries(newStyleProps).flatMap((v) => { v[0] = _.kebabCase(v[0]); return v; }));
            //console.log('NEW SPREAD PROPS', spreadProps);
            //console.log('NEW INLINE STYLE', inlineStyle);
        }
        else {
            console.error(`element type ${element.class} defines getElementConfigSpec but its value is not the spec for a single object with a simple properties object`);
        }
    }
    else {
        //console.warn(`element type ${element.class} does not define getElementConfigSpec as a function, spread props and styles are not available`);
    }
}
$: updateElementData(element, cardSpec, sequenceId, $mergedParams, $firstSourceData, $conditionsCheckStore, actionOverrides);
//$: console.log('UPDATE ELEMENT', element);
/*
$: if (sequenceId) {
  // register and unregister the card dynamically from data sources depending on whether conditions are met
  if ($conditionsCheckStore) {
    sources = tabContext.dataSourceManager.register(element.id, sequenceId, element.sourceIds);
  } else {
    tabContext.dataSourceManager.unregister(element.id);
    sources = writable([]);
  }
  element = element;
}s
*/
$: cardLibraryRecord = getNamed(ROOT, CardsLibraryToken, element.class) || getNamed(ROOT, CardsLibraryToken, 'YinzCam.Core.UnknownCard');
$: cardComponent = cardLibraryRecord["default"];
$: cardSpec = cardLibraryRecord["specification"];
//$: console.log('CARD LIBRARY RECORD', cardLibraryRecord);
$: cardComponentName = (cardSpec) ? cardSpec.getDisplayName() : (_.isFunction(cardLibraryRecord.getDisplayName) ? cardLibraryRecord.getDisplayName() : element.class);
$: showPlaceholder = $emmEnabled && !$emmPreview;
$: if ($conditionsCheckStore) {
    if (showPlaceholder) {
        // If we're showing the placeholder card, content is always available
        contentAvailable = true;
    }
    else if (!_.isNil(childContentAvailable)) {
        // If contentAvailable is defined by the card component, that means it wants to explicitly control whether it has content available.
        contentAvailable = childContentAvailable;
    }
    else if (cardSpec && $sources.length > 0) {
        // If the card is a new-style Atom and the card has data sources defined, show the card if source data is provided.
        //console.log('ATOM CONTENT AVAILALBE', cardSpec.getDisplayName(), !!$firstSourceData);
        contentAvailable = !!$firstSourceData;
    }
    else if ($sources.length > 0 && !_.isUndefined(childSourceStore)) {
        // If card provides access to its sourceStore, use the value of the store to determine whether it has content available.
        // Note that null here means that the sourceStore is defined by the card, it's just not available yet.
        contentAvailable = (!!$childSourceStore || ((_d = element === null || element === void 0 ? void 0 : element.data) === null || _d === void 0 ? void 0 : _d.forceShow)) || false;
    }
    else {
        // Otherwise, assume the card doesn't load dynamic content and just display as soon as we're mounted.
        contentAvailable = (mounted || ((_e = element === null || element === void 0 ? void 0 : element.data) === null || _e === void 0 ? void 0 : _e.forceShow)) || false;
    }
}
else {
    contentAvailable = false;
}
let updateDisplay = _.debounce((ca) => {
    cssDisplay = (ca) ? '' : 'display: none;';
}, 100);
$: updateDisplay(contentAvailable);
/*
$: cssVisibility = (contentAvailable)? '' : 'visibility: hidden;';
*/
$: contentActive = childContentActive;
function generateSchema(component) {
    const schema = _.cloneDeep(CARDS_ELEMENT_SCHEMA);
    const rec = getNamed(ROOT, CardsLibraryToken, component.class);
    if (rec) {
        const spec = cardLibraryRecord["specification"];
        if (spec) {
            // new way
            schema.title = spec.getDisplayName();
            function removeUneditableProperties(schema) {
                if (schema.uneditable === true) {
                    return undefined;
                }
                if (schema.type === 'object') {
                    const newProperties = {};
                    for (const [k, v] of Object.entries(schema.properties || {})) {
                        const newProp = removeUneditableProperties(v);
                        if (!_.isUndefined(newProp)) {
                            newProperties[k] = newProp;
                        }
                    }
                    if (Object.keys(newProperties).length > 0) {
                        return Object.assign(Object.assign({}, schema), { properties: newProperties });
                    }
                    else {
                        return undefined;
                    }
                }
                else {
                    return schema;
                }
            }
            schema.properties.data = removeUneditableProperties(spec.getSchema());
            if (schema.properties.data) {
                schema.properties.data.title = schema.title;
            }
            const curSources = tabContext.dataSourceManager.getDataSources() || [];
            schema.properties['sourceIds'] = generateElementDataSourcesSchema(spec.getDataSourceSpec(), curSources);
        }
        else {
            // old way
            if (_.isFunction(rec.getDisplayName)) {
                schema.title = rec.getDisplayName();
            }
            else {
                schema.title = rec.clazz;
            }
            if (_.isFunction(rec.getElementConfigSpec)) {
                schema.properties.data = rec.getElementConfigSpec();
                schema.properties.data.title = schema.title;
            }
            else {
                delete schema.properties.data;
            }
            if (_.isFunction(rec.getElementDataSourceSpec)) {
                const dsSpec = rec.getElementDataSourceSpec();
                if (dsSpec) {
                    const curSources = tabContext.dataSourceManager.getDataSources() || [];
                    schema.properties['sourceIds'] = generateElementDataSourcesSchema(dsSpec, curSources);
                }
            }
        }
    }
    return schema;
}
let childEditable = false;
let childEnableEditorMode = () => { };
let childDisableEditorMode = () => { };
$: childEditorData = element.editorData = element.editorData || {};
$: childTransformData = element.transformData = element.transformData || {};
function childSaveEditorData(ed) {
    //console.log('SED', JSON.stringify(ed));
    pageContext.editorModeManager.getComponent(element.id).onUpdate(Object.assign(Object.assign({}, element), { editorData: ed }));
}
function childSaveTransformData(td) {
    //console.log('STD', JSON.stringify(td));
    pageContext.editorModeManager.getComponent(element.id).onUpdate(Object.assign(Object.assign({}, element), { transformData: td }));
}
function onEdit() {
    if (childEditable && childEnableEditorMode) {
        childEnableEditorMode();
    }
}
let editorOptions;
$: editorOptions = {
    pageContext,
    parentSequenceId,
    sequenceId,
    componentId: element.id,
    component: element,
    componentTypeName: 'Card',
    childTypeNames: [],
    primaryColor: '#0DDE4F',
    reverseTextColor: 'black',
    unselectedBackgroundColor: '#e6e6e6',
    unselectedTextColor: '#000000',
    selectedBackgroundColor: 'rgb(0, 122, 255)',
    selectedTextColor: '#FFFFFF',
    childrenKey: null,
    configSpecGenerator: generateSchema,
    allowDeletion: true,
    allowDuplication: true,
    allowMovement: true,
    allowRepeats: true,
    allowCopyPaste: true,
    allowCustomEdit: childEditable,
    updater,
    allowPreviewHoverEdit: true,
    cloned: !!((_f = element === null || element === void 0 ? void 0 : element.__expandRepeats) === null || _f === void 0 ? void 0 : _f.cloned),
    sourceElementId: (_g = element === null || element === void 0 ? void 0 : element.__expandRepeats) === null || _g === void 0 ? void 0 : _g.sourceId,
    extraButtons: [
        ...((childEditable && [{ id: 'EDIT', icon: 'square_pencil_fill', tooltip: $pbt('Edit this component.'), showOnlyWhenActive: true, onClick: onEdit }]) || [])
    ]
};
// TODO: Fold this mechanism into the cssDisplay variable
/*
let display = null;
$: visibleKey = element.visibleKey;
  $: visible = (visibleKey)? ($mergedParams || {})[visibleKey] : null;
$: if (visibleKey) {
  display = (visible === 'true') ? 'block' : 'none';
} else {
  display = null;
}
*/
onMount(() => {
    mounted = true;
    return () => {
        tabContext.dataSourceManager.unregister(element.id);
    };
});
</script>