<script context="module" lang="ts">export { specification } from "./spec";
</script>
  
<script lang="ts">import { onMount } from 'svelte';
import BaseAtom from 'components/cards/atoms/BaseAtom.svelte';
import { getContentManager, getSignonManager } from 'components/cards/atoms';
import { VideoParticle } from 'components/cards/particles/YinzCam';
import TextParticle from 'components/cards/particles/YinzCam/Core/Text/TextParticle.svelte';
import { formatDateTimeLocal } from "components/cards/utilities";
import { DateTime } from "luxon";
import { VideoPlaybackErrorType } from "components/cards/particles/YinzCam/Core/Video/spec";
import { resolveUrl } from "js/url";
const sso = getSignonManager();
export let container = undefined;
export let slug = undefined;
export let link = undefined;
export let publishDateTime = undefined;
export let titleText = undefined;
//export let descriptionText: TextParticleProps = undefined;
export let dateTimeText = undefined;
export let video = undefined;
export let showInfoBox = undefined;
$: mtc = getContentManager().getMediaTypeConfigurationStore('gallery', slug, link);
//$: shareLink = $mtc?.shareLinkUrl?.href;
$: publishDateTimeDisplay = formatDateTimeLocal(publishDateTime, DateTime.DATE_HUGE);
let videoEl;
onMount(() => {
    return () => {
        if (videoEl) {
            videoEl.pause();
            videoEl.currentTime = 0;
        }
    };
});
function handleReady() {
    if (CONFIG.loginRequiredForVideoPlayback) {
        if (!sso.getStatus().loggedIn) {
            sso.getSdk().requireAuth();
        }
    }
}
async function _getPlaybackToken(sourceSystemId, sourceSystemVideoId) {
    var _a, _b;
    if (sourceSystemId === 'STREAMAMG') {
        if (sso.getStatus().loggedIn) {
            if (CONFIG.streamAmgVideoTokenSource === 'koreAccessToken') {
                const result = await sso.getProfileSegmentScoped('SPORTSALLIANCE');
                console.log('koreAccessToken result', result);
                return (_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a['access_token'];
            }
            else if (CONFIG.streamAmgVideoTokenSource === 'videoTokenEndpoint') {
                const result = await sso.getVideoToken(sourceSystemVideoId);
                console.log('videoTokenEndpoint result', result);
                return (_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b.token;
            }
        }
    }
    return undefined;
}
function handleError(e) {
    const type = e.detail.type;
    switch (type) {
        case VideoPlaybackErrorType.AUTHENTICATION_REQUIRED:
            sso.getSdk().requireAuth();
            break;
        case VideoPlaybackErrorType.ENTITLEMENT_REQUIRED:
            // TODO: make this dynamic
            resolveUrl(`video-packages-popup/${slug}`, undefined, { modal: true }).func();
            break;
        case VideoPlaybackErrorType.UNKNOWN_ERROR:
        default:
            break;
    }
}
</script>

<style>
  div.outer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  div.player {
    max-width: 100%;
  }

  div.info-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1.75rem 2.5rem;
    gap: 0.5rem;
  }

  /*
  div.info-box-title {
  }

  div.info-box-date {
  }
  */</style>
  
<BaseAtom _isotope="VideoPlayerAtom" {...container}>
  <div class="outer">
    <div class="player">
      <VideoParticle on:ready={handleReady} on:error={handleError} {_getPlaybackToken} {...video}  />
    </div>
    {#if showInfoBox}
    <div class="info-box">
      <div class="info-box-title">
        <TextParticle {...titleText} />
      </div>
      <div class="info-box-date">
        <TextParticle {...dateTimeText} text={publishDateTimeDisplay} />
      </div>
    </div>
    {/if}
  </div>
</BaseAtom>
