<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import { update, cloneDeep } from "lodash";
import { ButtonParticle, ButtonParticleSpec, SearchParticle, SearchParticleSpec } from "components/cards/particles/YinzCam";
import { BaseAtom, getSignonManager } from "components/cards/atoms";
import HeaderNavSectionMenu from "./HeaderNavSectionMenu.svelte";
import HeaderNavFlyoutMenu from "./HeaderNavFlyoutMenu.svelte";
import { onMount, tick } from "svelte";
import { resizeObserverAction } from "components/cards/common/actions";
import { resolveUrl } from "js/url";
export let container;
export let items = [];
export let menuTitleShowChevron = false;
export let menuTitleShowActiveBackground = false;
export let menuTitleActiveBackgroundColor = undefined;
export let menuTitleShowActiveUnderline = true;
export let menuTitleActiveUnderlineColor = undefined;
export let topButtons = undefined;
export let logoButton = undefined;
export let sponsorButton = undefined;
export let hideTopRow = false;
export let fontFamily = undefined;
export let searchBarInline;
export let searchBarProps;
export let flyoutMenuPage = undefined;
let atomWidthPx;
let mainContainer;
let menusRightGap;
let layoutClass = 'full';
let searchBarOpen = false;
async function updateLayout(_atomWidthPx) {
    if (!mainContainer || !menusRightGap) {
        return;
    }
    // This works because tick() allows reflows to happen but does not allow a repaint.
    layoutClass = 'full';
    await tick();
    //console.log("test full right gap: " + menusRightGap.clientWidth);
    if (menusRightGap.clientWidth < 40) {
        layoutClass = 'collapsed';
        await tick();
        //console.log("test collapsed right gap: " + menusRightGap.clientWidth);
        if (menusRightGap.clientWidth < 40) {
            layoutClass = 'burger-full';
            await tick();
            //console.log("test burger-full right gap: " + menusRightGap.clientWidth);
            if (mainContainer.clientWidth < 600) {
                layoutClass = 'burger-collapsed';
            }
        }
    }
    //console.log('LAYOUT CLASS: ' + layoutClass);
}
$: updateLayout(atomWidthPx);
function handleMenusRightGapResize() {
    updateLayout(0);
}
$: isBurgerLayout = layoutClass.startsWith('burger-');
$: logoHeightMultiplier = (layoutClass === 'burger-collapsed') ? '0.50' : (layoutClass === 'burger-full') ? '0.85' : '1.00';
$: topCenterButtonsShowImage = isBurgerLayout;
onMount(() => {
    setTimeout(updateLayout, 500);
});
const sso = getSignonManager();
const profile = sso.getDefaultProfileSegmentStore();
$: loginStatus = sso.getStatusComponent().store;
$: isLoggedIn = $loginStatus.loggedIn;
$: signInButtonText = (isLoggedIn) ? ($profile === null || $profile === void 0 ? void 0 : $profile['first_name']) || "My Profile" : "Sign In or Register";
async function handleSignInButtonClick() {
    if (isLoggedIn) {
        sso.getSdk().showCompleteProfilePage();
    }
    else {
        sso.getSdk().requireAuth();
    }
}
function handleSearchButtonClick() {
    searchBarOpen = !searchBarOpen;
}
let burgerOpened = false;
function handleBurgerButtonClick() {
    if (flyoutMenuPage === null || flyoutMenuPage === void 0 ? void 0 : flyoutMenuPage.slug) {
        //burgerNavigating = true;
        resolveUrl(flyoutMenuPage === null || flyoutMenuPage === void 0 ? void 0 : flyoutMenuPage.slug).func();
    }
    else {
        burgerOpened = !burgerOpened;
    }
}
</script>

<style>
  .main-container {
    /*aspect-ratio: var(--aspect-ratio, 21/4);*/
    height: 100%;
    width: 100%;
    padding: 0 9.38rem;

    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    font-family: var(--navigation-menu-atom-b-font-family, inherit);
  }

  .main-container.layout-collapsed,.main-container.layout-burger-full,.main-container.layout-burger-collapsed {
    padding: 0 3.12rem;
  }

  .main-container > .logo-container {
    z-index: 99;
  }

  .main-container.layout-burger-full > .logo-container {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main-container.layout-burger-collapsed {
    padding: 0 1.25rem;
  }

  .main-container > * {
    flex: 0 1 max-content;
  }

  .main-container > .logo-menus-gap {
    flex: 0 0 0.1rem;
  }

  .main-container.layout-collapsed > .logo-menus-gap {
    flex: 0 0 0.1rem;
  }

  .main-container.layout-burger-full > .logo-menus-gap,
  .main-container.layout-burger-collapsed > .logo-menus-gap {
    display: none;
  }

  .main-container > .two-row-container {
    flex-grow: 1;
    height: 100%;
    /*
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    */
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1px 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .main-container.layout-burger-collapsed > .two-row-container {
    grid-template-rows: 1fr;
  }

  .main-container.hide-top-row > .two-row-container {
    grid-template-rows: 1fr;
  }

  .main-container > .two-row-container > * {
    width: 100%;
  }

  .main-container > .two-row-container > .top-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
  }

  .main-container.layout-burger-collapsed > .two-row-container > .top-row {
    display: none;
  }

  .main-container > .two-row-container > .top-row > * {
    flex: 0 1 max-content;
  }

  .main-container > .two-row-container > .top-row > .logo-top-center-buttons-gap {
    flex-grow: 1;
  }

  .main-container.layout-burger-full > .two-row-container > .top-row > .logo-top-center-buttons-gap {
    display: none;
  }

  .main-container > .two-row-container > .top-row > .top-center-buttons {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  .main-container.layout-burger-full > .two-row-container > .top-row > .top-center-buttons {
    gap: 0.75rem;
  }

  .main-container > .two-row-container > .top-row > .top-center-buttons > :global(*) {
    flex: 0 1 max-content;
  }

  .main-container > .two-row-container > .top-row > .top-center-right-buttons-gap {
    flex-grow: 1;
  }

  .main-container > .two-row-container > .thin-white-line {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .main-container > .two-row-container > .thin-white-line > * {
    flex: 1 1 100%;
    background-color: white;
    height: 100%;
  }

  .main-container.layout-burger-full > .two-row-container > .thin-white-line > .center-line {
    flex: 0 0 10rem;
    background-color: transparent;
  }

  .main-container.layout-burger-collapsed > .two-row-container > .thin-white-line {
    display: none;
  }

  .main-container > .two-row-container > .bottom-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }

  .main-container > .two-row-container > .bottom-row > * {
    flex: 0 1 max-content;
  }

  .main-container > .two-row-container > .bottom-row > .menus-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
  }

  .main-container.layout-collapsed > .two-row-container > .bottom-row > .menus-container {
    gap: 1rem;
  }

  .main-container.layout-burger-full > .two-row-container > .bottom-row > .menus-container,
  .main-container.layout-burger-collapsed > .two-row-container > .bottom-row > .menus-container {
    display: none;
  }

  .main-container > .two-row-container > .bottom-row > .menus-container > :global(*) {
    flex: 0 1 max-content;
  }

  .main-container > .two-row-container > .bottom-row > .bottom-center-buttons {
    flex: 1 0;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .main-container > .two-row-container > .bottom-row > .bottom-center-buttons > :global(*) {
    flex: 0 1 max-content;
    zoom: 1.5;
  }

  .main-container:not(.layout-burger-collapsed) > .two-row-container > .bottom-row > .bottom-center-buttons {
    display: none;
  }

  .main-container > .two-row-container > .bottom-row > .menus-right-gap {
    flex: 1 0 auto;
  }

  .main-container.layout-burger-full > .two-row-container > .bottom-row > .menus-right-gap,
  .main-container.layout-burger-collapsed > .two-row-container > .bottom-row > .menus-right-gap {
    display: none;
  }

  .main-container > .two-row-container > .bottom-row > .bottom-right-buttons {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: 2rem;
  }

  .main-container.layout-burger-full > .two-row-container > .bottom-row > .bottom-right-buttons {
    flex: 1 0 100%;
    justify-content: space-between;
  }

  .main-container:not(.layout-burger-collapsed) > .two-row-container > .bottom-row > .bottom-right-buttons > :global(*) {
    flex: 0 1 max-content;
    transform: translateY(-0.375rem);
  }

  .main-container > .two-row-container > .bottom-row > .bottom-right-buttons > .non-burger-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  .main-container.layout-burger-full > .two-row-container > .bottom-row > .bottom-right-buttons > .non-burger-buttons {
    flex-direction: row-reverse;
  }

  .main-container.layout-burger-full > .two-row-container > .bottom-row > .bottom-right-buttons > .non-burger-buttons.inline-search {
    flex-direction: row;
  }

  .main-container.layout-burger-full.inline-search{
    justify-content: flex-end;
  }

  .main-container.layout-burger-full.inline-search .two-row-container{
      flex-grow: 0;
    }

  .main-container:not(.layout-burger-full):not(.layout-burger-collapsed) > .two-row-container > .bottom-row > .bottom-right-buttons > .burger-button {
    display: none;
  }

  .main-container.layout-burger-collapsed > .two-row-container > .bottom-row > .bottom-right-buttons >.non-burger-buttons> .search-bar-inline {
    display: none;
  }

  /*
  .main-container > .right-buttons-container {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .main-container > .right-buttons-container > * {
    flex: 1;
    width: 100%;
  }

  .main-container > .right-buttons-container > .top-right-buttons {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: 2rem;
  }

  .main-container:is(.layout-burger-full,.layout-burger-collapsed) > .right-buttons-container > .top-right-buttons {
    display: none;
  }

  .main-container > .right-buttons-container > .top-right-buttons > :global(*) {
    flex: 0 1 max-content;
  }
  */

  .search-bar {
    position: absolute;
    top: 100%;
    left: 50%;
    background-color: white;
    width: 100%;
    box-shadow: 0 0 8px rgba(0,0,0,.1);
    z-index: 10;
    margin: 0px 0px 0px 0px; /* top margin should be negative of translateY value below */
    padding: 0.75rem 10%;
    transform: translateX(-50%) translateY(0px);
    transition: all 0.2s ease;
    visibility: hidden;
    opacity: 0;
  }

  .search-bar-inline{
    transition: all 0.2s ease;
    visibility: visible;
    opacity: 1;
    overflow: hidden;
    min-width: 10rem;
  }

  .search-bar-inline.search-bar-inline-open{
    visibility: visible;
    opacity: 1;
  }

  .search-bar.search-bar-open {
    visibility: visible;
    opacity: 1;
    transform: translateX(-50%) translateY(0px);
  }</style>

<BaseAtom bind:_clientWidthPx={atomWidthPx} _isotope="NavigationMenuBAtom" _position="relative" _cssVariablePrefix="navigation-menu-atom-b" {...container} {fontFamily}>
  <div 
    bind:this={mainContainer}
    class="main-container"
    class:layout-full={layoutClass === 'full'}
    class:layout-collapsed={layoutClass === 'collapsed'}
    class:layout-burger-full={layoutClass === 'burger-full'}
    class:layout-burger-collapsed={layoutClass === 'burger-collapsed'}
    class:hide-top-row={hideTopRow}
    class:inline-search={searchBarInline}
  >
    <div class="logo-container">
      <ButtonParticle {...update(cloneDeep(logoButton), 'image.container.height', (v) => `calc(${v} * ${logoHeightMultiplier})`)} />
    </div>
    <div class="logo-menus-gap" />
    <div class="two-row-container">
      {#if !hideTopRow}
      <div class="top-row">
        <div class="logo-top-center-buttons-gap" />
        <div class="top-center-buttons">
          {#each (topButtons || []) as button, i}
            <ButtonParticle {...button} showImage={topCenterButtonsShowImage} showText={!topCenterButtonsShowImage} />
          {/each}
        </div>
        <div class="top-center-right-buttons-gap" />
        <div class="top-right-buttons">
          <ButtonParticle {...sponsorButton} />
        </div>
      </div>
      <div class="thin-white-line">
        <div class="left-line"></div>
        <div class="center-line"></div>
        <div class="right-line"></div>
      </div>
      {/if}
      <div class="bottom-row">
        <div class="menus-container">
          {#each items as item}
          <HeaderNavSectionMenu element={{ data: {
            title: item.title,
            titleFontSize: "1rem",
            titleFontColor: "white",
            titleFontWeight: "600",
            titleShowChevron: menuTitleShowChevron,
            titleShowBackgroundOnHover: menuTitleShowActiveBackground,
            titleHoverBackgroundColor: menuTitleActiveBackgroundColor,
            titleShowUnderlineOnHover: menuTitleShowActiveUnderline,
            titleHoverUnderlineColor: menuTitleActiveUnderlineColor,
            titleYShift: "-0.375rem",
            dropdownBackgroundColor: "white",
            dropdownPageEmbed: item.menuPage?.slug,
            sectionSlug: item.slug,
            linkUrl: item.linkUrl,
            subsections: item.subsections
          }}} />
          {/each}
        </div>
        <div class="bottom-center-buttons">
          {#each (topButtons || []) as button, i}
            {#if i !== 0}
            <ButtonParticle {...button} showImage={topCenterButtonsShowImage} showText={!topCenterButtonsShowImage} />
            {/if}
          {/each}
        </div>
        <div class="menus-right-gap" bind:this={menusRightGap} use:resizeObserverAction={handleMenusRightGapResize} />
        <div class="bottom-right-buttons">
          <div class="non-burger-buttons" class:inline-search={searchBarInline}>
            {#if !searchBarInline}
                <ButtonParticle on:click={handleSearchButtonClick} {...ButtonParticleSpec.propsWithDefaults({
                  image: { container: { height: '2.25rem', width: '2.25rem' }, icon: "yc_icon_search" },
                  text: { text: "Search", size: '1rem', weight: '600', wrap: 'nowrap' },
                  imagePosition: 'after',
                  showImage: layoutClass === 'full' || layoutClass === 'collapsed' || layoutClass === 'burger-full',
                  showText: false
                })} />
            {:else}
                <div class="search-bar-inline" class:search-bar-inline-open={searchBarOpen && searchBarInline}>
                    <SearchParticle {...searchBarProps} />
                </div>
            {/if}
            <ButtonParticle on:click={handleSignInButtonClick} {...ButtonParticleSpec.propsWithDefaults({
              image: { container: { height: '2.25rem', width: '2.25rem' }, icon: "yc_icon_profile" },
              text: { text: signInButtonText, size: '1rem', weight: '600', wrap: 'nowrap' },
              imagePosition: 'after',
              gap: '0.5rem',
              showImage: layoutClass === 'full' || layoutClass === 'collapsed' || layoutClass === 'burger-full',
              showText: layoutClass === 'full' || layoutClass === 'collapsed'
            })} />  
          </div>
          <div class="burger-button">
            <ButtonParticle on:click={handleBurgerButtonClick} {...ButtonParticleSpec.propsWithDefaults({
              image: { container: { height: '2.25rem', width: '2.25rem' }, icon: "yc_icon_menu_stack" },
              text: { text: "Menu", size: '1rem', weight: '600' },
              imagePosition: 'after',
              showImage: isBurgerLayout,
              showText: false
            })}/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <HeaderNavFlyoutMenu element={{ data: {
    menuPadding: '0.75rem 3.12rem',
    menuBackgroundColor: 'var(--theme-primary-background-color)',
    menuFontSize: '1.25rem',
    menuFontWeight: '600',
    menuFontColor: 'var(--theme-primary-foreground-color)',
    menuItemPadding: '2.12rem 0',
    submenuPadding: '0.75rem 1.5rem',
    submenuBackgroundColor: 'var(--theme-primary-background-color)',
    submenuTitleFontSize: '1rem',
    submenuTitleFontWeight: '400',
    submenuTitleFontColor: 'var(--theme-primary-accent-color)',
    submenuFontSize: '0.75rem',
    submenuFontWeight: '600',
    submenuFontColor: 'var(--theme-primary-foreground-color)',
    items: items
  }}} opened={(layoutClass === 'burger-full' || layoutClass === 'burger-collapsed') && burgerOpened} />
  <div class="search-bar" class:search-bar-open={searchBarOpen && !searchBarInline}>
    <SearchParticle {...SearchParticleSpec.propsWithDefaults({
      container: { themeMode: 'primary' },
      clearButton: { image: { iconColor: 'var(--theme-mode-accent-color)' }, text: { useAccentColor: true } }
    })} />
  </div>
</BaseAtom>
