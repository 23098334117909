<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import { uniqueId } from "lodash";
import { onMount, onDestroy } from "svelte";
import { getViewContext } from "components/cards/atoms";
import { BaseParticle, } from "components/cards/particles";
import { TextParticle, ButtonParticle, } from "components/cards/particles/YinzCam";
export let container;
export let overlayPadding = undefined;
export let txtListTitle;
export let btnChevron;
export let txtOptions;
export let colorBorder;
export let colorDropdownBackground;
export let listOptionText;
export let paramsName;
export let btnClearIcon;
const _id = uniqueId();
let showContent = false;
let selectedOption = null;
const viewContext = getViewContext();
function selectOption(selectedItem) {
    selectedOption = selectedItem;
    showContent = false;
}
function clearOption() {
    showContent = false;
    selectedOption = null;
}
function handleClick(event) {
    if (event.target.closest(`.dropdown-button`) !== null &&
        event.target.closest(`.dropdown-button`).id === _id) {
        showContent = !showContent;
    }
    else {
        showContent = false;
    }
}
function handleClickOutside(event) {
    if (event.target.closest(`.dropdown-button`) === null) {
        showContent = false;
    }
    if (event.target.closest(`.dropdown-button`) !== null &&
        event.target.closest(`.dropdown-button`).id !== _id) {
        showContent = false;
    }
}
onMount(() => document.addEventListener("click", handleClickOutside));
onDestroy(() => document.removeEventListener("click", handleClickOutside));
$: viewContext.setParam(paramsName, (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.value) || "");
</script>

<BaseParticle
    _name="DropDown"
    {...container}
    _cssVariablePrefix="drop-down"
    {overlayPadding}
    {colorBorder}
    {colorDropdownBackground}
>
    <div class="dropdown-box">
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div class="dropdown-button" class:content-open={showContent} id={_id}>
            <div class="ddl-title" on:click={handleClick}>
                <TextParticle
                    {...txtListTitle}
                    text={selectedOption
                        ? selectedOption?.option?.slice(0, 15)
                        : txtListTitle?.text?.slice(0, 15) || "Select Option"}
                />
            </div>
            <div class="ddl-icons">
                <div class="click-icon">
                    <ButtonParticle {...btnChevron} on:click={handleClick} />
                </div>
                <div class="clear-icon">
                    <ButtonParticle
                        {...btnClearIcon}
                        on:click={() => clearOption()}
                    />
                </div>
            </div>
        </div>
        <div class="dropdown-content" class:active-content={showContent}>
            {#each txtOptions || [] as txtOption}
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <div class="ddl-item" on:click={() => selectOption(txtOption)}>
                    <TextParticle
                        text={txtOption?.option}
                        {...listOptionText}
                    />
                </div>
            {/each}
        </div>
    </div>
</BaseParticle>

<style>
    .dropdown-box {
        width: 100%;
        padding: 0;
        position: relative;
        /* display: flex; */
    }
    .dropdown-button {
        padding: 0.75rem 1rem 0.75rem 2rem;
        border: none;
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 0.75rem;
        align-items: center;
        border: 1px solid
            var(
                --drop-down-color-border,
                var(--theme-mode-foreground-color, #fff)
            );
        border-radius: 5rem;
        background-color: var(--drop-down-color-dropdown-background, #fff);
    }
    .dropdown-button.content-open {
        border-radius: 2.5rem;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .dropdown-content {
        display: none;
        padding: 1rem 1rem 1rem 2rem;
        overflow: hidden;
    }
    .dropdown-content.active-content {
        position: absolute;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        /* justify-content: space-evenly; */
        gap: 1rem;
        border: 1px solid
            var(
                --drop-down-color-border,
                var(--theme-mode-foreground-color, #fff)
            );
        overflow-y: auto;
        height: 200px;
        background-color: var(--drop-down-color-dropdown-background, #fff);
        /* margin-top: -1rem; */
        border-bottom-left-radius: 2.5rem;
        border-bottom-right-radius: 2.5rem;
        z-index: 999;
    }
    .ddl-title {
        cursor: pointer;
    }
    .ddl-item {
        width: 100%;
        cursor: pointer;
    }
    .ddl-item:hover {
        background-color: #f0f0f0;
    }
    .ddl-icons {
        /* width: 30%; */
        display: flex;
        gap: 0.1rem;
        z-index: 10;
    }</style>
