import { generateRootObjectSchema, generateColorSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ImageParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Next Match-B", generateRootObjectSchema({
    container: generateAtomContainerSchema({}),
    imgLeaguelogo: ImageParticleSpec.generateSchema("Competition logo", "Upload a competition logo", {
        defaultValues: { container: { width: "4rem", height: "4rem" }, sizing: "contain" }
    }),
    imgOpponentLogo: ImageParticleSpec.generateSchema("Opponent Logo", "Upload an opponent logo", {
        defaultValues: { container: { width: "2rem", height: "2rem" }, sizing: "contain" }
    }),
    txtNextMatch: TextParticleSpec.generateSchema("Next Match Text", "Paragraph text | defaultValue: Next Match", {
        defaultValues: { size: "1rem", weight: "800", text: "Next Match |", wrap: "wrap" }
    }),
    txtMatchDate: TextParticleSpec.generateSchema("Match Date", "Specify date of next match", {
        defaultValues: { size: "1rem", weight: "500", wrap: "wrap" }, uneditableFields: { text: true }
    }),
    txtOpponentTricode: TextParticleSpec.generateSchema("Opponent Tricode", "Specify tricode of opponent team", {
        defaultValues: {
            size: "1rem", weight: "800"
        }
    }),
    txtHomeOrAwayCode: TextParticleSpec.generateSchema("Home or Away Code", "Home or away match", {
        defaultValues: { size: "1rem", weight: "700" }, uneditableFields: { text: true }
    }),
    optionHomeOrAway: generateStringSchema("Home Or Away", "Select if the match is home or away match", {
        choices: ["home", "away"],
        choiceTitles: ["Home", "Away"]
    }),
    matchDateTime: generateStringSchema("Next Match Date", "Specify date of next match"),
    txtCountDownTime: TextParticleSpec.generateSchema("Timer Handles", "Timer Handles", {
        defaultValues: { size: "1rem", weight: "800" }
    }),
    txtCountDownText: TextParticleSpec.generateSchema("Timer Text", "Timer Text", {
        defaultValues: { size: "0.85rem", weight: "500" }
    }),
    countdownBoxColor: generateColorSchema("Countdown Box Color", "Countdown Box Color"),
    symbolColor: generateColorSchema("Symbol Color", "Symbol Color"),
    symbolFontFamily: generateStringSchema("Symbol Font Family", "Symbol Font Family"),
    matchCentreUrl: generateStringSchema("Match Centre URL", "Match Centre URL")
}));