import { generateArraySchema, generateBooleanSchema, generateDateTimeSchema, generateObjectSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { AuthorParticleSpec, ImageParticleSpec, ShareStripParticleSpec, TextParticleSpec, VideoParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Video Player Card", generateRootObjectSchema({
  container: generateAtomContainerSchema({
    defaultValues: { themeMode: 'inverted' }
  }),
  slug: generateStringSchema("Slug", "The slug for this media item. This will be used to generate the media item link if an explicit link is not provided."),
  link: generateUrlSchema("Link", "The link for this media item. The default link will be generated from the slug if this is not provided (and the slug is)."),
  publishDateTime: generateDateTimeSchema("Publish Date and Time", "The date and time that this content was published."),
  titleText: TextParticleSpec.generateSchema("Title Text", "The text and styling for the video title.", {
    defaultValues: { size: '1.25rem', weight: '400', color: '#E0E0E0' },
  }),
  /*
  descriptionText: TextParticleSpec.generateSchema("Description Text", "The text and styling for the video description.", {
    defaultValues: { size: '1rem', weight: '700' },
  }),
  */
  dateTimeText: TextParticleSpec.generateSchema("Date Time Text", "The styling for the date/time text.", {
    defaultValues: { size: '1rem', weight: '700', color: '#FAFAFA' },
    uneditableFields: { text: true }
  }),
  /*
  author: AuthorParticleSpec.generateSchema("Author", "The author of the video.", {
    defaultValues: { },
  }),
  */
  video: VideoParticleSpec.generateSchema("Video", "The video to play."),
  showInfoBox: generateBooleanSchema("Show Info Box", "Show the video box below the player.", {
    defaultValue: true
  }),
}));
