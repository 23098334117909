<script context="module" lang="ts">var _a;
export { specification } from "./spec";
</script>

<script lang="ts">var _a;
import "lodash";
import { BaseAtom, getContentManager } from "components/cards/atoms";
import { ShareStripParticle, TextParticle } from "components/cards/particles/YinzCam";
import AuthorParticle from "components/cards/particles/YinzCam/Media/Author/AuthorParticle.svelte";
export let container = undefined;
export let slug = undefined;
export let link = undefined;
export let titleText = undefined;
export let descriptionText = undefined;
export let author = undefined;
export let socialLinks = undefined;
$: mtc = getContentManager().getMediaTypeConfigurationStore('gallery', slug, link);
$: shareLink = (_a = $mtc === null || $mtc === void 0 ? void 0 : $mtc.shareLinkUrl) === null || _a === void 0 ? void 0 : _a.href;
</script>

<style>
  .gallery-header-atom {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 100%;
    height: 100%;
    gap: 1rem;
  }

  .gallery-header-atom > .gallery-header-atom-title {
    flex: 1 0 auto;
    width: 100%;
  }

  .gallery-header-atom > .gallery-header-atom-description {
    flex: 1 0 auto;
    width: 100%;
  }

  .gallery-header-atom > .gallery-header-atom-bottom-row {
    flex: 1 0 auto;
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    

    display: none;
  }

  .gallery-header-atom > .gallery-header-atom-bottom-row > .gallery-header-atom-author {
    flex: 0 0 auto;
  }

  .gallery-header-atom > .gallery-header-atom-bottom-row > .gallery-header-atom-bottom-row-spacer {
    flex: 1 1 auto;
  }

  .gallery-header-atom > .gallery-header-atom-bottom-row > .gallery-header-atom-social {
    flex: 0 0 auto;
  }</style>

<BaseAtom _isotope="GalleryHeaderAtom" {...container}>
  <div class="gallery-header-atom">
    <div class="gallery-header-atom-title">
      <TextParticle {...titleText} />
    </div>
    <div class="gallery-header-atom-description">
      <TextParticle {...descriptionText} />
    </div>
    <div class="gallery-header-atom-bottom-row">
      <div class="gallery-header-atom-author">
        <AuthorParticle {...author} />
      </div>
      <div class="gallery-header-atom-bottom-row-spacer">
      </div>
      <div class="gallery-header-atom-social">
        <ShareStripParticle {...socialLinks} shareText={(titleText && titleText.text) || ""} shareLink={shareLink || '/'} />
      </div>
    </div>
  </div>
</BaseAtom>
