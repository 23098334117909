<script context="module" lang="ts">var _a, _b;
export { specification } from "./spec.ts";
</script>

<script lang="ts">var _a, _b;
import { get, merge } from "lodash";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ImageParticle, } from "components/cards/particles/YinzCam";
export let container;
export let overlayPadding;
export let txtHeading;
export let imgHomeLogo;
export let imgAwayLogo;
export let statsList;
export let txtStatName;
export let txtHomeValue;
export let txtAwayValue;
export let matchStats = undefined;
export let myTeamColor;
export let opponentTeamColor;
export let homeTricode;
export let awayTricode;
export let isCircleGraphRequired;
export let objCircleGraphStats = undefined;
let atomWidthRem;
let barGraphDisplayStats = [];
let colorHomeGraph = "", colorAwayGraph = "";
const populateDisplayStats = (matchStats, statsList) => {
    let statsArray = [];
    matchStats === null || matchStats === void 0 ? void 0 : matchStats.forEach((stat) => {
        if ((statsList === null || statsList === void 0 ? void 0 : statsList.includes(stat === null || stat === void 0 ? void 0 : stat.statName)) &&
            !statsArray.find((ele) => !(ele === null || ele === void 0 ? void 0 : ele.statName.localeCompare(stat === null || stat === void 0 ? void 0 : stat.statName)))) {
            statsArray === null || statsArray === void 0 ? void 0 : statsArray.push(stat);
        }
    });
    return statsArray;
};
$: isMobile = atomWidthRem <= 36;
$: isTablet = atomWidthRem <= 59 && atomWidthRem > 36;
$: imgMultiplier = isTablet || isMobile ? "0.45" : "1.00";
$: circleStat = (_a = populateDisplayStats(matchStats, [
    objCircleGraphStats === null || objCircleGraphStats === void 0 ? void 0 : objCircleGraphStats.statName,
])) === null || _a === void 0 ? void 0 : _a[0];
$: valMax = parseInt(circleStat === null || circleStat === void 0 ? void 0 : circleStat.maxValue);
$: valProgress = parseInt(circleStat === null || circleStat === void 0 ? void 0 : circleStat.homeValue);
$: progressPercent = ((valProgress / valMax) * 100).toFixed(2);
$: circleGraphProgressColor = objCircleGraphStats === null || objCircleGraphStats === void 0 ? void 0 : objCircleGraphStats.progressColor;
$: circleGraphFillColor = objCircleGraphStats === null || objCircleGraphStats === void 0 ? void 0 : objCircleGraphStats.fillColor;
$: barGraphDisplayStats = populateDisplayStats(matchStats, statsList);
$: if ((homeTricode === null || homeTricode === void 0 ? void 0 : homeTricode.toLowerCase()) === ((_b = CONFIG === null || CONFIG === void 0 ? void 0 : CONFIG.tricode) === null || _b === void 0 ? void 0 : _b.toLowerCase())) {
    colorHomeGraph = myTeamColor;
    colorAwayGraph = opponentTeamColor;
}
else {
    colorHomeGraph = opponentTeamColor;
    colorAwayGraph = myTeamColor;
}
const computeProgress = (homeValue, awayValue) => {
    if (parseInt(homeValue) === 0 || parseInt(awayValue) === 0)
        return 50;
    const homeValueNumber = parseInt(homeValue);
    const awayValueNumber = parseInt(awayValue);
    return (homeValueNumber / (homeValueNumber + awayValueNumber)) * 100 || 0;
};
</script>

<BaseAtom
    bind:_clientWidthRem={atomWidthRem}
    _isotope="MCShootingDetailsAtom"
    _cssVariablePrefix="mc-shooting-details"
    {...container}
    {overlayPadding}
    {colorHomeGraph}
    {colorAwayGraph}
    {circleGraphProgressColor}
    {circleGraphFillColor}
>
    <div class="main-container">
        <div class="heading">
            <TextParticle {...txtHeading} />
        </div>
        <div class="team-logo-box">
            <ImageParticle
                _block={true}
                {...merge({}, imgHomeLogo, {
                    container: {
                        width: `calc(${get(imgHomeLogo, "container.width")}*${imgMultiplier})`,
                        height: `calc(${get(imgHomeLogo, "container.height")}*${imgMultiplier})`,
                    },
                })}
            />
            {#if isCircleGraphRequired}
                <div class="graph-container">
                    <div class="circle-card">
                        <div
                            class="circle-progress"
                            class:mobile={isMobile}
                            style={`--progress: ${progressPercent}deg`}
                        >
                            <div class="percent-text-box">
                                <div class="stat-name">
                                    <TextParticle
                                        {...txtStatName}
                                        text={circleStat?.statName}
                                    />
                                </div>
                                <div class="home-away-values">
                                    <TextParticle
                                        {...txtHomeValue}
                                        text={circleStat?.homeValue || 0}
                                    />
                                    <span>|</span>
                                    <TextParticle
                                        {...txtAwayValue}
                                        text={circleStat?.awayValue || 0}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/if}
            <ImageParticle
                _block={true}
                {...merge({}, imgAwayLogo, {
                    container: {
                        width: `calc(${get(imgAwayLogo, "container.width")}*${imgMultiplier})`,
                        height: `calc(${get(imgAwayLogo, "container.height")}*${imgMultiplier})`,
                    },
                })}
            />
        </div>
        <div class="stat-graph-box">
            {#each barGraphDisplayStats || [] as stat}
                <div class="stat-line-box">
                    <div class="stat-line">
                        <TextParticle
                            {...txtHomeValue}
                            text={stat?.homeValue || 0}
                        />
                        <TextParticle {...txtStatName} text={stat?.statName} />
                        <TextParticle
                            {...txtAwayValue}
                            text={stat?.awayValue || 0}
                        />
                    </div>
                    <div class="graph-line">
                        <div class="outer-bar">
                            <div
                                class="inner-bar"
                                style={`--progress: ${computeProgress(stat?.homeValue, stat?.awayValue)}%`}
                            ></div>
                        </div>
                    </div>
                </div>
            {/each}
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        display: flex;
        flex-direction: column;
        padding: var(--mc-shooting-details-overlay-padding, unset);
        width: 100%;
        gap: 1rem;
    }
    .team-logo-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .stat-graph-box {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
    .stat-line-box {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
    .stat-line {
        display: flex;
        justify-content: space-between;
    }
    .outer-bar {
        background-color: var(--mc-shooting-details-color-away-graph, #d9d9d9);
        width: 100%;
        height: 1rem;
        border-radius: 1.685rem;
    }
    .inner-bar {
        background-color: var(--mc-shooting-details-color-home-graph, #000);
        height: 1rem;
        width: var(--progress, 0);
        border-radius: 1.685rem;
    }

    .graph-container {
        width: 100%;
        height: 100%;
    }
    .circle-card {
        display: flex;
        flex-direction: column;
        border-radius: 0.75rem;
        align-items: center;
        position: relative;
        background-color: var(--theme-mode-background-color, unset);
    }
    .circle-progress {
        position: relative;
        width: 16rem;
        height: 16rem;
        border-radius: 50%;
        background: conic-gradient(
            var(
                    --mc-shooting-details-color-away-graph,
                    --theme-mode-foreground-color
                )
                calc(360deg - (var(--progress) * 3.6)),
            var(--mc-shooting-details-color-home-graph, #d9d9d9) 0deg
        );
        background: conic-gradient(
            var(
                    --mc-shooting-details-color-away-graph,
                    --theme-mode-foreground-color
                )
                calc(360deg - calc(var(--progress) * 3.6)),
            var(--mc-shooting-details-color-home-graph, #d9d9d9) 0deg
        );
        font-size: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    .circle-progress::before {
        content: "";
        position: absolute;
        top: 1rem;
        right: 1rem;
        bottom: 1rem;
        left: 1rem;
        background-color: #fff;
        border-radius: 50%;
    }
    .circle-progress.mobile {
        width: 13rem;
        height: 13rem;
    }
    .percent-text-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.5rem 0.75rem;
        border: 1px solid var(--circle-graph-border-color, #d9d9d9);
        border-radius: 0.75rem;
        z-index: 1;
    }
    .percent-text-box .stat-name {
            text-align: center;
        }
    .percent-text-box .home-away-values {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            line-height: 1;
        }
    .percent-text-box .home-away-values span {
                font-size: 1.5rem;
                color: #dedede;
            }</style>
